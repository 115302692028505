import React from 'react';
import { Modal, Button, Select, Checkbox, Tooltip, Input, Tag, Alert } from 'antd';
import { QuestionCircleOutlined, ExclamationCircleOutlined, CheckCircleOutlined, DownOutlined, WarningOutlined } from '@ant-design/icons';

interface TranslationModalProps {
  isVisible: boolean;
  onCancel: () => void;
  onTranslate: () => void;
  selectedChapter: any;
  translateVersion: string;
  setTranslateVersion: (version: string) => void;
  bookLanguage: string;
  targetLanguage: string;
  setTargetLanguage: React.Dispatch<React.SetStateAction<string>>;
  handleLanguageChange: (value: string, type: 'source' | 'target') => void;
  handleAIModelChange: (value: string) => void;
  languages: string[];
  betaLanguages: string[];
  betaLanguageWarning: boolean;
  setBetaLanguageWarning: (warning: boolean) => void;
  quotationStyle: string;
  setQuotationStyle: (style: string) => void;
  selectedAIModel: string;
  setSelectedAIModel: (model: string) => void;
  isBetaUser: boolean;
  creditBalance: any;
  getCreditCost: () => number;
  isChapterTranslated: boolean;
  isPreviousChapterTranslated: boolean;
  hasUntranslatedChapters: boolean;
  userHasEnoughCredits: boolean;
  hasUnproofreadChapters: boolean;
  proofreadOptionProps: {
    value: string;
    disabled: boolean;
    text: string;
  };
}

const TranslationModal: React.FC<TranslationModalProps> = ({
  isVisible,
  onCancel,
  onTranslate,
  selectedChapter,
  translateVersion,
  setTranslateVersion,
  bookLanguage,
  targetLanguage,
  setTargetLanguage,
  handleLanguageChange,
  handleAIModelChange,
  languages,
  betaLanguages,
  betaLanguageWarning,
  setBetaLanguageWarning,
  quotationStyle,
  setQuotationStyle,
  selectedAIModel,
  setSelectedAIModel,
  isBetaUser,
  creditBalance,
  getCreditCost,
  isChapterTranslated,
  isPreviousChapterTranslated,
  hasUntranslatedChapters,
  userHasEnoughCredits,
  hasUnproofreadChapters,
  proofreadOptionProps,
}) => {
  
  // Get status messages to display in alerts
  const getStatusMessages = () => {
    const messages = [];
    
    // Warning messages (orange)
    if (isChapterTranslated) {
      messages.push({
        type: 'warning',
        message: 'The selected chapter is already translated and will be overwritten.',
        icon: <WarningOutlined />
      });
    }
    
    if (selectedChapter && !isPreviousChapterTranslated) {
      messages.push({
        type: 'warning',
        message: 'The previous chapter has not been translated.',
        icon: <WarningOutlined />
      });
    }
    
    // Error messages (red)
    if (!hasUntranslatedChapters && !selectedChapter) {
      messages.push({
        type: 'error',
        message: 'All chapters have been translated for the selected target language.',
        icon: <ExclamationCircleOutlined />
      });
    }
    
    if (!userHasEnoughCredits) {
      messages.push({
        type: 'error',
        message: 'You do not have enough credits for this translation.',
        icon: <ExclamationCircleOutlined />
      });
    }
    
    if (hasUnproofreadChapters && !selectedChapter && translateVersion === 'Proofread') {
      messages.push({
        type: 'error',
        message: 'Not all chapters are proofread.',
        icon: <ExclamationCircleOutlined />
      });
    }
    
    // Success messages (green)
    if (hasUntranslatedChapters && !selectedChapter) {
      messages.push({
        type: 'success',
        message: 'ScribeShadow will only translate chapters that have not yet been translated.',
        icon: <CheckCircleOutlined />
      });
    }
    
    return messages;
  };
		
  return (
    <Modal
      title={selectedChapter ? "Translate Chapter" : "Translate Book"}
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>Cancel</Button>,
        <Button 
          key="translate" 
          type="primary" 
          onClick={onTranslate} 
          disabled={
            !targetLanguage || !translateVersion || bookLanguage === targetLanguage ||
            (!hasUntranslatedChapters && !selectedChapter) ||
            !userHasEnoughCredits ||
            (translateVersion === 'Proofread' && hasUnproofreadChapters && !selectedChapter)
          }
        >
          Translate
        </Button>
      ]}
      width={700}
    >
      <div>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
            <strong>Version to Translate:</strong>
          </label>
          <Select
            value={translateVersion}
            onChange={value => setTranslateVersion(value)}
            style={{ width: '100%' }}
            suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
          >
            <Select.Option value="Original">Original</Select.Option>
            <Select.Option value={proofreadOptionProps.value} disabled={proofreadOptionProps.disabled}>
              {proofreadOptionProps.text}
            </Select.Option>
          </Select>
        </div>
        
        <div style={{ marginBottom: '15px' }}>
          <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
            <strong>Origin Language:</strong>
          </label>
          <div style={{ padding: '4px 0' }}>{bookLanguage}</div>
        </div>
        
        <div style={{ marginBottom: '15px' }}>
          <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
            <strong>Target Language:</strong>
          </label>
          <Select
            value={targetLanguage}
            onChange={(value) => {
              setTargetLanguage(value);
              handleLanguageChange(value, 'target');
              setBetaLanguageWarning(betaLanguages.includes(value));
              if (value === 'German') {
                setQuotationStyle('german guillemets');
              }  
            }}
            style={{ width: '100%' }}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{maxHeight: '5000px' }}
            suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
          >
            {languages.filter(lang => lang !== bookLanguage).map(lang => (
              <Select.Option key={lang} value={lang}>
                {lang} {betaLanguages.includes(lang) ? "(BETA)" : ""}
              </Select.Option>
            ))}
          </Select>      
        </div>
        
        {betaLanguageWarning && (
          <Alert
            message={`${targetLanguage} Translation Beta`}
            description={`The translation for ${targetLanguage} is currently in beta. While we strive for accuracy, there may be variations in quality. We welcome your feedback to improve this service.`}
            type="warning"
            showIcon
            style={{ marginBottom: '15px' }}
          />
        )}
        
        {/* Combined AI Model and Quotation Style in a row */}
        <div style={{ 
          display: 'flex', 
          gap: '15px', 
          marginBottom: '15px' 
        }}>
          {/* Left column - AI Model */}
          <div style={{ flex: 1 }}>
            <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
              <strong>AI Model:</strong>
            </label>
            <Select
              value={selectedAIModel}
              onChange={(value) => {
                setSelectedAIModel(value);
                handleAIModelChange(value);
              }}
              style={{ width: '100%' }}
              suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
            >
              <Select.Option value="Claude3.7">
                Claude 3.7 <Tag color="geekblue" style={{ marginLeft: '8px', position: 'relative', top: '-0.5px' }}>Recommended </Tag>
              </Select.Option>
              <Select.Option value="Claude">Claude 3.5</Select.Option>
              <Select.Option value="Chat-GPT">Chat-GPT</Select.Option>	
              <Select.Option value="Claude3">Claude 3</Select.Option>
              <Select.Option value="DeepSeek">
                DeepSeek <Tag color="green" style={{ marginLeft: '8px', position: 'relative', top: '-0.5px' }}>50% Off</Tag>
                <Tag color="red" style={{ marginLeft: '8px', position: 'relative', top: '-0.5px' }}>Allows NSFW</Tag>
              </Select.Option>
              {/* <Select.Option value="Llama">Llama</Select.Option> */}
            </Select>
          </div>
          
          {/* Right column - Quotation Style */}
          <div style={{ flex: 1 }}>
            <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
              <strong>Quotation Style:</strong>
            </label>
            <Select
              value={quotationStyle}
              onChange={value => setQuotationStyle(value)}
              style={{ width: '100%' }}
              suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
              disabled={targetLanguage !== 'German'}
            >
              <Select.Option value="german guillemets">Guillemets (»Dialog Text«)</Select.Option>
              <Select.Option value="german quotation marks">German Quotation Marks („Dialog Text")</Select.Option>            
            </Select>
            {targetLanguage !== 'German' && (
              <div style={{ color: '#999', fontSize: '12px', marginTop: '5px' }}>
                Only available for German language
              </div>
            )}
          </div>
        </div>
        
        {/* Credit information */}
        <div style={{ 
          background: '#f0f7ff', 
          padding: '15px', 
          borderRadius: '6px', 
          marginBottom: '15px' 
        }}>
          <div style={{ marginBottom: '8px' }}>
            <strong>Credit Balance: </strong>
            <span>{creditBalance ? creditBalance.credit_balance : 'Loading...'}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <strong>Translation Credit Cost: </strong>
            <span style={{ marginLeft: '5px', marginRight: '5px' }}>{getCreditCost()}</span>
            <Tooltip title="This value may differ from the word count of your document due to the inclusion of hidden tags and metadata necessary to maintain formatting integrity.">
              <QuestionCircleOutlined style={{ color: '#1677ff' }} />
            </Tooltip>
          </div>
        </div>
        
        {/* Status Messages */}
        <div style={{ marginTop: '12px' }}>
          {getStatusMessages().map((msg, index) => (
            <Alert
              key={index}
              message={msg.message}
              type={msg.type as any}
              icon={msg.icon}
              style={{ marginBottom: '8px' }}
              showIcon
              closable={false}
              banner={false}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default TranslationModal;