import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Tag, Tooltip, message, Spin } from 'antd';
import { EditOutlined, DeleteOutlined, SaveOutlined, QuestionCircleOutlined, PlusOutlined } from '@ant-design/icons';

interface AlwaysTranslateModalProps {
  isVisible: boolean;
  onClose: () => void;
  currentLanguagePair: string;
  alwaysTranslateWords: { [key: string]: string[] };
  setAlwaysTranslateWords: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>;
  bookGuid: string;
}

const AlwaysTranslateModal: React.FC<AlwaysTranslateModalProps> = ({
  isVisible,
  onClose,
  currentLanguagePair,
  alwaysTranslateWords,
  setAlwaysTranslateWords,
  bookGuid,
}) => {
  const [newSourceWord, setNewSourceWord] = useState('');
  const [newTargetWord, setNewTargetWord] = useState('');
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editedSourceWord, setEditedSourceWord] = useState('');
  const [editedTargetWord, setEditedTargetWord] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Reset states when modal is opened/closed
  useEffect(() => {
    if (isVisible) {
      setNewSourceWord('');
      setNewTargetWord('');
      setEditingIndex(null);
      setEditedSourceWord('');
      setEditedTargetWord('');
    }
  }, [isVisible]);

  const updateAPI = async (words: string[]): Promise<boolean> => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://askpoeai-nswdzfky7q-uc.a.run.app/update-always-translate/${bookGuid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          language_pair: currentLanguagePair,
          words: words,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to update API');
      }
      return true;
    } catch (error) {
      console.error('Error updating API:', error);
      message.error('Failed to update. Please try again.');
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const isValidInput = (input: string): boolean => {
    // Allow Unicode letters, numbers, spaces, hyphens, and apostrophes
    return /^[\p{L}\p{N}\s\-']+$/u.test(input);
  };

  const handleAddWord = async () => {
    if (newSourceWord.trim() && newTargetWord.trim()) {
      if (isValidInput(newSourceWord.trim()) && isValidInput(newTargetWord.trim())) {
        const newPair = `${newSourceWord.trim()} | ${newTargetWord.trim()}`;
        const updatedWords = [...(alwaysTranslateWords[currentLanguagePair] || []), newPair];
        const success = await updateAPI(updatedWords);
        if (success) {
          setAlwaysTranslateWords(prevState => ({
            ...prevState,
            [currentLanguagePair]: updatedWords
          }));
          setNewSourceWord('');
          setNewTargetWord('');
        }
      } else {
        message.error('Please enter valid words or phrases (only letters, numbers, spaces, hyphens, and apostrophes are allowed).');
      }
    } else {
      message.error('Please enter both source and target words or phrases.');
    }
  };

  const handleSaveEdit = async () => {
    if (editingIndex !== null && editedSourceWord.trim() && editedTargetWord.trim()) {
      if (isValidInput(editedSourceWord.trim()) && isValidInput(editedTargetWord.trim())) {
        const updatedWords = [...(alwaysTranslateWords[currentLanguagePair] || [])];
        updatedWords[editingIndex] = `${editedSourceWord.trim()} | ${editedTargetWord.trim()}`;
        const success = await updateAPI(updatedWords);
        if (success) {
          setAlwaysTranslateWords(prevState => ({
            ...prevState,
            [currentLanguagePair]: updatedWords
          }));
          setEditingIndex(null);
        }
      } else {
        message.error('Please enter valid words or phrases (only letters, numbers, spaces, and hyphens are allowed).');
      }
    } else {
      message.error('Please enter both source and target words or phrases.');
    }
  };

  const handleRemoveWord = async (index: number) => {
    const updatedWords = (alwaysTranslateWords[currentLanguagePair] || []).filter((_, i) => i !== index);
    const success = await updateAPI(updatedWords);
    if (success) {
      setAlwaysTranslateWords(prevState => ({
        ...prevState,
        [currentLanguagePair]: updatedWords
      }));
    }
  };

  const handleEditWord = (index: number) => {
    const pair = alwaysTranslateWords[currentLanguagePair][index];
    const [sourceWord, targetWord] = pair.split(' | ');
    setEditingIndex(index);
    setEditedSourceWord(sourceWord);
    setEditedTargetWord(targetWord);
  };

  const handleModalClose = () => {
    setEditingIndex(null);
    onClose();
  };

  return (
    <Modal
      title={
        <span>
          Always Translate for {currentLanguagePair}
          <Tooltip title="The AI will always translate the source text to the specified target text">
            <QuestionCircleOutlined style={{ marginLeft: '8px', cursor: 'pointer', color: '#1677ff' }} />
          </Tooltip>
        </span>
      }
      visible={isVisible}
      onCancel={handleModalClose}
      footer={[
        <Button key="close" onClick={handleModalClose}>
          Close
        </Button>
      ]}
      width={700}
    >
      <Spin spinning={isLoading}>
        <div style={{ 
          background: '#f7f7f7', 
          padding: '15px', 
          borderRadius: '6px', 
          marginBottom: '15px' 
        }}>
          <div style={{ marginBottom: '15px' }}>
            <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
              <strong>Source Word/Phrase:</strong>
            </label>
            <Input
              value={newSourceWord}
              onChange={(e) => setNewSourceWord(e.target.value)}
              placeholder="Enter source word or phrase"
              style={{ width: '100%' }}
            />
          </div>
          
          <div style={{ marginBottom: '15px' }}>
            <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
              <strong>Target Word/Phrase:</strong>
            </label>
            <Input
              value={newTargetWord}
              onChange={(e) => setNewTargetWord(e.target.value)}
              placeholder="Enter target word or phrase"
              style={{ width: '100%' }}
            />
          </div>
          
          <Button 
            type="primary" 
            onClick={handleAddWord} 
            icon={<PlusOutlined />}
          >
            Add Word/Phrase Pair
          </Button>
        </div>
        
        <div style={{ 
          background: '#f0f7ff', 
          padding: '15px', 
          borderRadius: '6px'
        }}>
          <div style={{ marginBottom: '12px' }}>
            <strong>Current Always Translate Pairs:</strong>
            <Tooltip title="The AI will always translate these source words/phrases to their specified target equivalents">
              <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#1677ff' }} />
            </Tooltip>
          </div>
          
          <div style={{ maxHeight: '300px', overflowY: 'auto', padding: '5px 0' }}>
            {currentLanguagePair && (alwaysTranslateWords[currentLanguagePair] ?? []).length > 0 ? (
              <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -5px' }}>
                {(alwaysTranslateWords[currentLanguagePair] ?? []).map((pair, index) => {
                  const [sourceWord, targetWord] = pair.split(' | ');
                  return (
                    <div key={index} style={{ 
                      width: editingIndex === index ? '100%' : '50%', 
                      padding: '0 5px', 
                      boxSizing: 'border-box', 
                      marginBottom: '10px' 
                    }}>
                      <Tag 
                        style={{ 
                          padding: '6px 8px', 
                          fontSize: '14px',
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%'
                        }}
                      >
                        {editingIndex === index ? (
                          <>
                            <Input
                              value={editedSourceWord}
                              onChange={(e) => setEditedSourceWord(e.target.value)}
                              style={{ width: '150px', marginRight: '8px' }}
                            />
                            <span style={{ margin: '0 5px' }}>→</span>
                            <Input
                              value={editedTargetWord}
                              onChange={(e) => setEditedTargetWord(e.target.value)}
                              style={{ width: '150px', marginRight: '8px' }}
                            />
                            <Tooltip title="Save">
                              <SaveOutlined
                                onClick={handleSaveEdit}
                                style={{ marginLeft: '5px', cursor: 'pointer', color: '#1677ff' }}
                              />
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{sourceWord}</span>
                            <span style={{ margin: '0 8px', flexShrink: 0 }}>→</span>
                            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{targetWord}</span>
                            <div style={{ marginLeft: 'auto', display: 'flex', gap: '8px', flexShrink: 0 }}>
                              <Tooltip title="Edit">
                                <EditOutlined
                                  onClick={() => handleEditWord(index)}
                                  style={{ cursor: 'pointer', color: '#1677ff' }}
                                />
                              </Tooltip>
                              <Tooltip title="Delete">
                                <DeleteOutlined
                                  onClick={() => handleRemoveWord(index)}
                                  style={{ cursor: 'pointer', color: '#ff4d4f' }}
                                />
                              </Tooltip>
                            </div>
                          </>
                        )}
                      </Tag>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div style={{ color: '#999', padding: '10px 0' }}>
                No translation pairs added yet. Add your first pair above.
              </div>
            )}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default AlwaysTranslateModal;