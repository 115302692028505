//BookDetailPage.tsx

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Input, Button, Divider, Tabs, Spin, Modal, Select, message, Checkbox, Tooltip, Tag, Radio, Space, Form, Alert, Dropdown, Menu, Typography  } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, ExclamationCircleOutlined, QuestionCircleOutlined, WarningOutlined, CopyOutlined, FileImageOutlined, UploadOutlined, TranslationOutlined, ExportOutlined, SaveOutlined, BulbOutlined, DownOutlined, BookOutlined, FormOutlined, PlusOutlined, MenuFoldOutlined, MenuUnfoldOutlined, FileTextOutlined, DownloadOutlined, LoadingOutlined  } from '@ant-design/icons';

import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

import axios from 'axios';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import './BookDetailPage.css';
import { getAuth } from "firebase/auth"; // Import getAuth
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import { CSSProperties } from 'react';
import { diffWords  } from 'diff';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import TranslationOptionsModal from './TranslationOptionsModal';
import GenerateBlurbModal from './GenerateBlurbModal';
import KeywordSuggestionModal from './KeywordSuggestionModal';
import TranslationModal from './TranslationModal';
import { Book, Chapter } from './types';
import SummarizeOfferModal from './SummarizeOfferModal';
import ProofreadModal from './ProofreadModal';
import AddLanguageModal from './AddLanguageModal';
import NeverTranslateModal from './NeverTranslateModal';
import AlwaysTranslateModal from './AlwaysTranslateModal';
import TranslationStatusPanel from './TranslationStatusPanel';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

interface TranslatedChapter {
  chapter_guid: string;
  translated_text: string;
  proofread_text: string;
  proofread_comments: string;
  translated_word_count: number;
  proofread_word_count: number;
  source_language: string;
  target_language: string;
  api_model: string;
}

interface EditBook {
  title: string;
  author: string;
  series: string;
  booknumber: number;
  genres: string[]; 
  tropes: string[];
  keywords: string[];
}

interface BlurbTranslations {
  [key: string]: string;
}

interface TranslationOption {
  translatedText: string;
  sourceTrans: string;
  desc: string;
}

interface TranslationOptions {
  titleOptions?: TranslationOption[];
  seriesOptions?: TranslationOption[];
}

interface CreditBalance {
  credit_balance: number;
  planID: string;
  temp_Credits: number;
  forever_credits: number;
}

type ChapterOrderMap = {
  [key: string]: number;
};

type TextContent = string;

interface DiffPart {
  added?: boolean;
  removed?: boolean;
  value: string;
}
// Make sure this interface matches exactly with the one in BookDetailPage.tsx
interface TranslationStatus {
  chapterGuid: string;
  chapterName: string;
  sourceLang: string;
  targetLang: string;
  timestamp: string;
  status: 'pending' | 'completed' | 'failed';
  bookId?: string;
  userId?: string;
  word_count?: number;
  estimatedTimeMinutes?: number;
  completionTime?: string;  
  cleared?: boolean;
  lockGuid: string;
  error?: string;
  type: 'Translation' | 'Proofread' | 'EditingNotes';
}

const BookDetailPage = () => {
  const { bookId } = useParams<{ bookId?: string }>();
  const [book, setBook] = useState<Book>({ title: '', author: '', series: '', booknumber: 0, coverUrl: '', chapters: [], genres: [] });
  const [editBook, setEditBook] = useState<EditBook>({
  title: '',
  author: '',
  series: '',
  booknumber: 0,
  genres: [],
  tropes: [],
  keywords: []
  }); 
  const [selectedChapter, setSelectedChapter] = useState<Chapter | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [firstEnter, setFirstEnter] = useState(true);
  const [originalOrder, setOriginalOrder] = useState<Record<string, number>>({});
  const [isLoading, setIsLoading] = useState(false);
  const auth = getAuth();
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isTranslateModalVisible, setIsTranslateModalVisible] = useState(false);
  const [isProofreadModalVisible, setIsProofreadModalVisible] = useState(false);  
  const [isMetadataTranslateModalVisible, setIsMetadataIsTranslateModalVisible] = useState(false);
  const [isKeywordSuggestionModalVisible, setIsKeywordSuggestionModalVisible] = useState(false);    
  const [sourceLanguage, setSourceLanguage] = useState('English');
  const [targetLanguage, setTargetLanguage] = useState(''); 
  const [targetMetadataLanguage, setTargetMetadataLanguage] = useState(''); 
  const [translateVersion, setTranslateVersion] = useState('Original'); 
  const [isBetaUser, setIsBetaUser] = useState(false);
  const baseLanguages = ["Dutch", "English", "British English", "French", "German", "Italian", "European Portuguese", "Brazilian Portuguese", "Spanish"];
  const betaLanguages = ["Japanese", "Traditional Chinese", "Simplified Chinese", "Russian", "Thai", "Hindi","Catalan"];
  const languages = isBetaUser ? [...baseLanguages, ...betaLanguages] : baseLanguages;
  const [creditCost, setCreditCost] = useState<number>(0);
  const [editingChapterGuid, setEditingChapterGuid] = useState<string | null>(null);
  const [editedChapterName, setEditedChapterName] = useState<string>('');
  const [blurbActiveTabKey, setBlurbActiveTabKey] = useState<string>('blurb');
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [exportVersion, setExportVersion] = useState('Original');
  const [proofreadVersion, setProofreadVersion] = useState('Original');
  const [exportFormat, setExportFormat] = useState('ePub');
  const [untranslatedChapters, setUntranslatedChapters] = useState<string[]>([]);
  const [unproofreadChapters, setUnproofreadChapters] = useState<string[]>([]);  
  const [unproofreadNotesChapters, setUnproofreadNotesChapters] = useState<string[]>([]);
  const [showWarning, setShowWarning] = useState(false);
  const [bookLanguage, setBookLanguage] = useState<string>('');
  const isSelectedChapter = (chapterGuid: string) => {
      return selectedChapter && chapterGuid === selectedChapter.chapter_guid;
  };  
  const [activeTabKey, setActiveTabKey] = useState<string>('1');
  
  const [failedTranslations, setFailedTranslations] = useState<string[]>([]);
  const [successfulTranslations, setSuccessfulTranslations] = useState<string[]>([]);

  const [failedProofreads, setFailedProofreads] = useState<string[]>([]);
  const [successfulProofreads, setSuccessfulProofreads] = useState<string[]>([]);

  const [failedProofreadNotes, setFailedProofreadNotes] = useState<string[]>([]);
  const [successfulProofreadNotes, setSuccessfulProofreadNotes] = useState<string[]>([]);
  
  const [isFailedTranslationModalVisible, setIsFailedTranslationModalVisible] = useState(false);
  const [isFailedProofreadModalVisible, setIsFailedProofreadModalVisible] = useState(false);  
  const [showDeleteTranslatedChaptersModal, setShowDeleteTranslatedChaptersModal] = useState(false);  
  const [showDeleteProofreadChaptersModal, setShowDeleteProofreadChaptersModal] = useState(false);  
  const [showDeleteSingleProofreadChapterModal, setShowDeleteSingleProofreadChapterModal] = useState(false);  
  const [showDeleteSingleTranslatedChapterModal, setShowDeleteSingleTranslatedChapterModal] = useState(false);  
  
  const [deleteChaptersSourceLang, setDeleteChaptersSourceLang] = useState<string>('');
  const [deleteChaptersTargetLang,setDeleteChaptersTargetLang] = useState<string>('');

  const [deleteSingleChapterSourceLang, setDeleteSingleChapterSourceLang] = useState<string>('');
  const [deleteSingleChapterTargetLang,setDeleteSingleChapterTargetLang] = useState<string>('');  
  
  
  const [isTranslationTimedout, setIsTranslationTimedout] = useState(false);
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  const [blurb, setBlurb] = useState<string>('');
  const [blurbTranslations, setBlurbTranslations] = useState<{ [language: string]: string }>({});
  const [tropes, setTropes] = useState<string[]>([]);
  const [keywords, setKeywords] = useState<string[]>([]);
const [editedBlurb, setEditedBlurb] = useState<string>('');
const [blurbLanguage, setBlurbLanguage] = useState<string>('English');  
const [uploadType, setUploadType] = useState<string>('');
const [translationOptions, setTranslationOptions] = useState<TranslationOptions>({});
const [isTranslationOptionsModalVisible, setIsTranslationOptionsModalVisible] = useState(false);
const [canTranslateTitle, setCanTranslateTitle] = useState(false);
const [canTranslateSeries, setCanTranslateSeries] = useState(false);
const [canTranslateBlurb, setCanTranslateBlurb] = useState(false);
  const [titleTranslations, setTitleTranslations] = useState<{ [key: string]: string }>({});
  const [seriesTranslations, setSeriesTranslations] = useState<{ [key: string]: string }>({});
const [keywordTranslations, setKeywordTranslations] = useState<{ [key: string]: string[] }>({});

  
const [isTranslatedTabActive, setIsTranslatedTabActive] = useState(false);
  const [creditBalance, setCreditBalance] = useState<CreditBalance | null>(null);
  const [userHasEnoughCredits, setUserHasEnoughCredits] = useState(true);
  const [userHasEnoughCreditsMetadata, setUserHasEnoughCreditsMetadata] = useState(true);

const hasUntranslatedChapters = untranslatedChapters.length > 0;

const [hasUntranslatedChaptersForProofread, setHasUntranslatedChaptersForProofread] = useState(false);

const [hasProofreadChapters, setHasProofreadChapters] = useState(false);
const [hasProofreadNotesChapters, setHasProofreadNotesChapters] = useState(false);
const [hasUnproofreadChapters, setHasUnproofreadChapters] = useState(true);
const [hasUnproofreadNotesChapters, setHasUnproofreadNotesChapters] = useState(true);
const [hasOriginalProofreadComplete, setHasOriginalProofreadComplete] = useState(false);
const [viewMode, setViewMode] = useState('sideBySide');
const navigate = useNavigate();
const [selectedAIModel, setSelectedAIModel] = useState<string>("Claude3.7");
const [rejectedChapters, setRejectedChapters] = useState<string[]>([]);
const [betaLanguageWarning, setBetaLanguageWarning] = useState(false);

  const [isProofreadChecked, setIsProofreadChecked] = useState(false);
  const [isEditingNotesChecked, setIsEditingNotesChecked] = useState(false);
const [genres, setGenres] = useState<string[]>([]);
const commonGenres = ['Fantasy', 'Science Fiction', 'Mystery', 'Thriller', 'Romance', 'Young Adult', 'Children', 'Historical', 'Non-Fiction', 'Horror'];
  const [chapterTense, setChapterTense] = useState<string>("Past");
  
  const [quotationStyle, setQuotationStyle] = useState<string>("");
  const [proofreadAdditionalInstructions, setProofreadAdditionalInstructions] = useState<string>("");

  const [isToggling, setIsToggling] = useState(false); 
  
  const [canChapterBeProofread, setCanChapterBeProofread] = useState<boolean>(false);
  const [hasChapterBeenProofread, setHasChapterBeenProofread] = useState<boolean>(false);
  const [hasChapterBeenEditNotes, setHasChapterBeenEditNotes] = useState<boolean>(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [isProofreadConfirmationModalVisible, setIsProofreadConfirmationModalVisible] = useState(false);

  const [isGenerateBlurbModalVisible, setIsGenerateBlurbModalVisible] = useState(false);
  const [blurbTense, setBlurbTense] = useState('Present');
  const [blurbPoV, setBlurbPoV] = useState('3rd Person');
  const [blurbCharacterNames, setBlurbCharacterNames] = useState('');
  
    const [isKeywordSuggestionLoading, setIsKeywordSuggestionLoading] = useState(false);

  const [showSummarizeOffer, setShowSummarizeOffer] = useState(false);
 
  const [editingTranMetadataTitle, setEditingTranMetadataTitle] = useState(false);
  const [editingTranMetadataSeries, setEditingTranMetadataSeries] = useState(false);
  const [editingTranMetadataBlurb, setEditingTranMetadataBlurb] = useState(false);
  const [tempTranMetadataTitle, setTempTranMetadataTitle] = useState('');
  const [tempTranMetadataSeries, setTempTranMetadataSeries] = useState('');
  const [tempTranMetadataBlurb, setTempTranMetadataBlurb] = useState('');
const [humanReviewModalVisible, setHumanReviewModalVisible] = useState(false);
const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
const [isAddLanguageModalVisible, setIsAddLanguageModalVisible] = useState(false);
const [alwaysTranslateWords, setAlwaysTranslateWords] = useState<{ [key: string]: string[] }>({});
const [neverTranslateWords, setNeverTranslateWords] = useState<{ [key: string]: string[] }>({});
const [isNeverTranslateModalVisible, setIsNeverTranslateModalVisible] = useState(false);
const [currentLanguagePair, setCurrentLanguagePair] = useState<string>('blurb');
const [newNeverTranslateWord, setNewNeverTranslateWord] = useState('');
const [isAlwaysTranslateModalVisible, setIsAlwaysTranslateModalVisible] = useState(false);
const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

const [activeTranslations, setActiveTranslations] = useState<TranslationStatus[]>([]);

const fetchActiveTranslations = useCallback(async () => {
  if (!auth.currentUser) return;
  
  try {
    const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/active-translations/${auth.currentUser.uid}`);
    console.log("Active translations:", response.data.translations); // Debug line
    setActiveTranslations(response.data.translations || []);
  } catch (error) {
    console.error('Error fetching active translations:', error);
  }
}, [auth.currentUser]);

// Add this effect to fetch active translations on load and periodically
useEffect(() => {
  fetchActiveTranslations();
  const interval = setInterval(
    fetchActiveTranslations, 
    30000 // 30 seconds
  );
  return () => clearInterval(interval);
}, [fetchActiveTranslations]);

const toggleSidebar = () => {
  setIsSidebarCollapsed(!isSidebarCollapsed);
};
 
 const [translateMetadata, setTranslateMetadata] = useState({
    title: false,
    series: false,
    blurb: false,
  });

  const [keywordSuggestionMetadata, setKeywordSuggestionMetadata] = useState({
    genre: false,
    tropes: false,
    keywords: false,
  });

  const [selectedTab, setSelectedTab] = useState<string>('Original');

  const handleTabChange = (value: string) => {
    setSelectedTab(value);
  };

//  useEffect(() => {
//    if (showSummarizeOffer) {
//      setShowSummarizeOffer(true);
//    }
//  }, []);

const toggleAlwaysTranslateModal = () => {
  setIsAlwaysTranslateModalVisible(!isAlwaysTranslateModalVisible);
};

useEffect(() => {
  const allChaptersProofread = book.chapters.every(chapter => chapter.proofread_chapter);
  setHasOriginalProofreadComplete(allChaptersProofread);
  //console.log("hasOriginalProofreadComplete: "+hasOriginalProofreadComplete)
}, [book.chapters]);


  const handleSummarizeAccept = async (dontShowAgain: boolean) => {
 
    try {
	  setIsLoading(true);	
	  setShowSummarizeOffer(false); 
	  if (dontShowAgain) {
		  await axios.post(`https://askpoeai-nswdzfky7q-uc.a.run.app/set-summary-offer/${bookId}`, {
        userId: auth.currentUser?.uid 
      });
	  }
		
      const response = await axios.post(`https://askpoeai-nswdzfky7q-uc.a.run.app/summarize-book/${bookId}`, {
        userId: auth.currentUser?.uid,
		aiModel: 'Claude'
      });
	  if (response.data.status=='Success') {
		message.success('Book summarized successfully');
	  }
	  else {
		  message.error('Failed to summarize book');
	  }
    } catch (error) {
      console.error('Error summarizing book:', error);
      message.error('Failed to summarize book');
    } finally{
		setShowSummarizeOffer(false);
		setIsLoading(false);		
	}
    setShowSummarizeOffer(false);
	setIsLoading(false);
  };

  const handleSummarizeDecline = async (dontShowAgain: boolean) => {
    try {
	  setIsLoading(true);	
	  setShowSummarizeOffer(false);		
	  if (dontShowAgain) {
		  await axios.post(`https://askpoeai-nswdzfky7q-uc.a.run.app/set-summary-offer/${bookId}`, {
			userId: auth.currentUser?.uid
      });
	  }
    } catch (error) {
      console.error('Error saving preference:', error);
      message.error('Failed to save preference.');
    } finally{
		setShowSummarizeOffer(false);
		setIsLoading(false);
	}
	setShowSummarizeOffer(false);
	setIsLoading(false);
	
  };


const fetchCreditBalance = useCallback(async (userId: string) => {
  try {
    const response = await axios.get<CreditBalance>(`https://askpoeai-nswdzfky7q-uc.a.run.app/check_credit_balance/?userID=${userId}`);
    setCreditBalance(response.data);
  } catch (error) {
    console.error('Error fetching credit balance', error);
    message.error('Failed to fetch credit balance', 10);
  }
}, []);

useEffect(() => {
  const initializeSummaryOffer = async () => {
    if (showSummarizeOffer && auth.currentUser) {
      await fetchCreditBalance(auth.currentUser.uid);
      setShowSummarizeOffer(true);
    }
  }; 

  initializeSummaryOffer();
}, [showSummarizeOffer, auth.currentUser, fetchCreditBalance]);

useEffect(() => {
  //console.log("useEffect 1");
  //console.time('useEffect 1');
  const wordCount = calculateWordCount(targetLanguage, selectedAIModel);
  setUserHasEnoughCredits(creditBalance ? creditBalance.credit_balance >= wordCount : false);
  //console.timeEnd('useEffect 1');
}, [creditBalance, selectedChapter, targetLanguage, book.chapters]);

const updateUntranslatedChaptersForProofread = (newTargetLanguage: string) => {
  //console.time('updateUntranslatedChaptersForProofread');
  if (newTargetLanguage === 'Original') {
    setHasUntranslatedChaptersForProofread(false);
	//console.timeEnd('updateUntranslatedChaptersForProofread');
    return; 
  }  
  const translatedChaptersKey = `translated_chapters_${bookLanguage}_${newTargetLanguage}`;
  const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];
  const untranslated = book.chapters.filter(chapter =>
    !translatedChapters.some(tChapter => tChapter.chapter_guid === chapter.chapter_guid)
  ).map(chapter => chapter.chapter_name);

  setHasUntranslatedChaptersForProofread(untranslated.length > 0);
  //console.timeEnd('updateUntranslatedChaptersForProofread');
};

useEffect(() => {
  //console.log("useEffect 2");
  //console.time('useEffect 2');
  if (!selectedChapter) {
    if (proofreadVersion === "Original") {
      updateUntranslatedChaptersForProofread("Original");
    } else {
      const [, targetLang] = proofreadVersion.split('-');
      updateUntranslatedChaptersForProofread(targetLang);
    }
	//console.timeEnd('useEffect 2');
    return;
  }

  if (proofreadVersion === "Original") {
    updateUntranslatedChaptersForProofread("Original");
    setCanChapterBeProofread(true);
    setHasChapterBeenProofread(selectedChapter.proofread_word_count > 0);
    setHasChapterBeenEditNotes(selectedChapter.proofread_comments.length > 0);
  } else {
    const [sourceLang, targetLang] = proofreadVersion.split('-');
    const translatedChaptersKey = `translated_chapters_${sourceLang}_${targetLang}`;
    const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];
    const translatedChapter = translatedChapters.find(chap => chap.chapter_guid === selectedChapter.chapter_guid);

    if (translatedChapter) {
      setCanChapterBeProofread(!!translatedChapter.translated_text && translatedChapter.translated_word_count > 0);
      setHasChapterBeenProofread(!!translatedChapter.proofread_text && translatedChapter.proofread_word_count > 0);
      setHasChapterBeenEditNotes(!!translatedChapter.proofread_comments && translatedChapter.proofread_comments.length > 0);
    } else {
      setCanChapterBeProofread(false);
      setHasChapterBeenProofread(false);
      setHasChapterBeenEditNotes(false);
    }
  }
  //console.timeEnd('useEffect 2');
}, [selectedChapter, proofreadVersion]);

const [isChapterTranslated, setIsChapterTranslated] = useState(false);
const [isPreviousChapterTranslated, setIsPreviousChapterTranslated] = useState(false);

  useEffect(() => {
    if (selectedChapter) {
      const translatedChaptersKey = `translated_chapters_${bookLanguage}_${targetLanguage}`;
      const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];
      
      // Check if the current chapter is translated
      const isCurrentChapterTranslated = translatedChapters.some(
        tChapter => tChapter.chapter_guid === selectedChapter.chapter_guid
      );
      setIsChapterTranslated(isCurrentChapterTranslated);

      // Check if the previous chapter is translated
      const currentChapterOrder = originalOrder[selectedChapter.chapter_guid] || 0;

      if (currentChapterOrder === 1) {
        // If it's the first chapter
        setIsPreviousChapterTranslated(true);
      } else {
        const isPrevChapterTranslated = translatedChapters.some(
          tChapter => originalOrder[tChapter.chapter_guid] === currentChapterOrder - 1
        );
        setIsPreviousChapterTranslated(isPrevChapterTranslated);
      }
    } else {
      setIsChapterTranslated(false);
      setIsPreviousChapterTranslated(false);
    }
  }, [selectedChapter, book, bookLanguage, targetLanguage, originalOrder]);


//  // Check if the selected chapter is already translated for the target language
//  const isChapterTranslated = () => {
//    if (selectedChapter) {
//      const translatedChaptersKey = `translated_chapters_${bookLanguage}_${targetLanguage}`;
//      const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];
//      return translatedChapters.some(tChapter => tChapter.chapter_guid === selectedChapter.chapter_guid);
//    }
//    return false;
//  };
//
//  const isPreviousChapterTranslated = () => {
//    if (selectedChapter) {
//      const translatedChaptersKey = `translated_chapters_${bookLanguage}_${targetLanguage}`;
//      const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];
//      const previousChapterOrder = (originalOrder[selectedChapter.chapter_guid] || 0) - 1;
//      return translatedChapters.some(tChapter => originalOrder[tChapter.chapter_guid] === previousChapterOrder);
//    }
//    return false;
//  };

  const isChapterProofread = () => {
    return false;
  };  

  useEffect(() => {
	  //console.log("useEffect 3");
	  //console.time('useEffect 3');
  const handleLogout = () => {
    // Reset all states
    setBook({ title: '', author: '', series: '', booknumber: 0, coverUrl: '', chapters: [], genres: [] });
    setEditBook({ title: '', author: '', series: '', booknumber: 0, genres: [], tropes: [], keywords: [] });
    setSelectedChapter(null);
	setIsProofreadChecked(false);
	setBetaLanguageWarning(false);
	setIsBetaUser(false);
	setIsEditingNotesChecked(false);
    setIsEditing(false);
    setOriginalOrder({});
    setIsLoading(false);
    setIsTitleValid(true);
    setIsTranslateModalVisible(false);
    setIsMetadataIsTranslateModalVisible(false);
	setIsKeywordSuggestionModalVisible(false);
    setSourceLanguage('English');
    setTargetLanguage('');
	setTranslateVersion('Original');
	setBlurbCharacterNames('');
    setEditingChapterGuid(null);
    setEditedChapterName('');
    setBlurbActiveTabKey('blurb');
    setIsExportModalVisible(false);
    setExportVersion('Original');
	setProofreadVersion('Original');
    setExportFormat('ePub');
    setUntranslatedChapters([]);
    setShowWarning(false);
    setBookLanguage('');
    setActiveTabKey('1');
    setFailedTranslations([]);
	setGenres([]);
	setFailedProofreads([]);
	setFailedProofreadNotes([]);
	setSuccessfulProofreads([]);	
	setSuccessfulTranslations([]);
	setSuccessfulProofreadNotes([]);
	setTropes([]);
	setKeywords([]);
	setQuotationStyle("");
	setProofreadAdditionalInstructions("");
    setIsFailedTranslationModalVisible(false);
	setIsFailedProofreadModalVisible(false);
    setBlurb('');
    setBlurbTranslations({});
    setEditedBlurb('');
    setBlurbLanguage('English');
    setUploadType('');
    setTranslationOptions({});
	setUnproofreadChapters([]);
	setUnproofreadNotesChapters([]);
	setIsProofreadModalVisible(false);
	setHasUnproofreadChapters(true);
	setHasUnproofreadNotesChapters(true);
	setHasProofreadChapters(false);
	setHasProofreadNotesChapters(false);
    setIsTranslationOptionsModalVisible(false);
    setCanTranslateTitle(false);
    setCanTranslateSeries(false);
    setCanTranslateBlurb(false);
    setTitleTranslations({});
    setSeriesTranslations({});
    setIsTranslatedTabActive(false);
    setCreditBalance(null);
    setUserHasEnoughCredits(true);
	setUserHasEnoughCreditsMetadata(true);
	setCanChapterBeProofread(false);
    setTranslateMetadata({
      title: false,
      series: false,
      blurb: false,
    });
	setKeywordSuggestionMetadata({
      genre: false,
      tropes: false,
      keywords: false,
    });	
	
      // Redirect to /manage-books
      navigate('/manage-books');
    };

    if (!auth.currentUser?.uid) {
      handleLogout();
    }
	//console.timeEnd('useEffect 3');
	}, [auth.currentUser?.uid, navigate]);

  //Functions to Delete ALL translated chapters for selected language pair
  const handleDeleteTranslatedChapters = async (sourceLang: string, targetLang: string) => {
		setDeleteChaptersSourceLang(sourceLang);
		setDeleteChaptersTargetLang(targetLang);
		setShowDeleteTranslatedChaptersModal(true);		
  };  

  const handleDeleteTranslatedChaptersOK = () => {
		setShowDeleteTranslatedChaptersModal(false);	
		deleteTranslatedChapters(deleteChaptersSourceLang, deleteChaptersTargetLang);
  };  
  
  const handleDeleteTranslatedChaptersCancel = () => {
		setShowDeleteTranslatedChaptersModal(false);		
  };  

  //Functions to Delete ALL proofread chapters for selected language pair
  const handleDeleteProofreadChapters = async (sourceLang: string, targetLang: string) => {
		setDeleteChaptersSourceLang(sourceLang);
		setDeleteChaptersTargetLang(targetLang);
		setShowDeleteProofreadChaptersModal(true);		
  };  

  const handleDeleteProofreadChaptersOK = () => {
		setShowDeleteProofreadChaptersModal(false);	
		deleteProofreadChapters(deleteChaptersSourceLang, deleteChaptersTargetLang);
  };

  const handleDeleteProofreadChaptersCancel = () => {
		setShowDeleteProofreadChaptersModal(false);		
  };   
  
  //Functions to Delete the selected chapter's Proofread for selected language pair
  const handleDeleteSingleProofreadChapter = async (sourceLang: string, targetLang: string) => {
		setDeleteSingleChapterSourceLang(sourceLang);
		setDeleteSingleChapterTargetLang(targetLang);		
		setShowDeleteSingleProofreadChapterModal(true);		
  };  

  const handleDeleteSingleProofreadChapterOK = () => {
		setShowDeleteSingleProofreadChapterModal(false);	
		if (selectedChapter) {
			deleteProofread(selectedChapter.chapter_guid, deleteSingleChapterSourceLang, deleteSingleChapterTargetLang);
		}
  };

  const handleDeleteSingleProofreadChapterCancel = () => {
		setShowDeleteSingleProofreadChapterModal(false);		
  };     

  //Functions to Delete the selected chapter's Translation for selected language pair
  const handleDeleteSingleTranslatedChapter = async (sourceLang: string, targetLang: string) => {
		setDeleteSingleChapterSourceLang(sourceLang);
		setDeleteSingleChapterTargetLang(targetLang);		
		setShowDeleteSingleTranslatedChapterModal(true);		
  };  

  const handleDeleteSingleTranslatedChapterOK = () => {
		setShowDeleteSingleTranslatedChapterModal(false);	
		if (selectedChapter) {
			deleteTranslation(selectedChapter.chapter_guid, deleteSingleChapterSourceLang, deleteSingleChapterTargetLang);
		}
  };

  const handleDeleteSingleTranslatedChapterCancel = () => {
		setShowDeleteSingleTranslatedChapterModal(false);		
  };     
  
  
  //API call to delete translated chapters
  const deleteTranslatedChapters = async (sourceLang: string, targetLang: string) => {
    if (!auth.currentUser) {
      console.error("No user logged in");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        `https://askpoeai-nswdzfky7q-uc.a.run.app/delete-translated-chapters/${bookId}`, 
        {
          user_id: auth.currentUser.uid,
          source_language: sourceLang,
          target_language: targetLang
        }
      );

    if (response.data.status === "error") {
      throw new Error(response.data.message);
    }
	
      // Refresh the book data to reflect the deletion
      const updatedBookResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/book/${bookId}`);
      let updatedBook: Book = updatedBookResponse.data;
      updatedBook.chapters = updatedBook.chapters.sort((a: Chapter, b: Chapter) => a.order - b.order);
      setBook(updatedBook);
	  let successMsg = `All Chapter Translations for ${targetLang} deleted successfully.`;
      message.success(successMsg);
    } catch (error) {
      console.error("Error deleting translation:", error);
      message.error("Error deleting translation",10);
    } finally {
      setIsLoading(false);
    }
  };  

  const deleteProofreadChapters = async (sourceLang: string, targetLang: string) => {
    if (!auth.currentUser) {
      console.error("No user logged in");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        `https://askpoeai-nswdzfky7q-uc.a.run.app/delete-proofread-chapters/${bookId}`, 
        {
          user_id: auth.currentUser.uid,
          source_language: sourceLang,
          target_language: targetLang
        }
      );

    if (response.data.status === "error") {
      throw new Error(response.data.message);
    }
	
      // Refresh the book data to reflect the deletion
      const updatedBookResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/book/${bookId}`);
      let updatedBook: Book = updatedBookResponse.data;
      updatedBook.chapters = updatedBook.chapters.sort((a: Chapter, b: Chapter) => a.order - b.order);
      setBook(updatedBook);
	  let successMsg = `All Chapter Proofreads for ${targetLang} deleted successfully.`;
      message.success(successMsg);
    } catch (error) {
      console.error("Error deleting proofreading:", error);
      message.error("Error deleting proofreading",10);
    } finally {
      setIsLoading(false);
    }
  };  
  
const calculateWordCount = (targetLang: string, aiModel: string): number => {
  if (selectedChapter && !isEditing) {
    // If a specific chapter is selected, count the words in that chapter
	let wordCount=selectedChapter.word_count * (targetLang === 'Hindi' ? 2 : 1);
	
    if (aiModel === 'DeepSeek') {
        wordCount = Math.ceil(wordCount * 0.5);
    } else {
        wordCount = wordCount;
    }	
	
    return wordCount;
  } else {
    // Count the words in all untranslated chapters
    const translatedChaptersKey = `translated_chapters_${bookLanguage}_${targetLang}`;
    const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];

    let wordCount = book.chapters
      .filter(chapter => 
        !translatedChapters.some(tChapter => tChapter.chapter_guid === chapter.chapter_guid))
      .reduce((sum, chapter) => sum + chapter.word_count, 0);

	wordCount = wordCount * (targetLang === 'Hindi' ? 2 : 1);

    if (aiModel === 'DeepSeek') {
        wordCount = Math.ceil(wordCount * 0.5);
    } else {
        wordCount = wordCount;
    }
	
    return wordCount;
  }
};

const handleGenerateBlurb = async () => {
  if (!auth.currentUser) {
    console.error("No user logged in");
    return;
  }
  setSelectedAIModel("Claude3.7");
  if(!isGenerateBlurbModalVisible) {
	  setIsGenerateBlurbModalVisible(true);
  }
  else {
	  setIsGenerateBlurbModalVisible(false);
  }
};


  useEffect(() => {
	  //console.log("useEffect 4");
	  //console.time('useEffect 4');
    setCanTranslateTitle(!!book.title);
    setCanTranslateSeries(!!book.series);
    setCanTranslateBlurb(!!blurb);
	//console.timeEnd('useEffect 4');
  }, [book.title, book.series, blurb]);

const isAnyMetadataSelectedForTranslation = () => {
  return translateMetadata.title || translateMetadata.series || translateMetadata.blurb;
};

useEffect(() => {
	//console.log("useEffect 5");
	//console.time('useEffect 5');
  //console.log("exportVersion: "+exportVersion);
  if (exportVersion !== 'Original'&& !exportVersion.includes('Proofread')) {
    const [sourceLang, targetLang] = exportVersion.split('-');
    const translatedChaptersKey = `translated_chapters_${sourceLang}_${targetLang}`;
    const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];

    const untranslated = book.chapters.filter(chapter =>
      !translatedChapters.some(tChapter => tChapter.chapter_guid === chapter.chapter_guid)
    ).map(chapter => chapter.chapter_name);

    setUntranslatedChapters(untranslated);
    setShowWarning(untranslated.length > 0);
  } 
  else if (exportVersion == 'Proofread') {
    const unproofreadChapters = book.chapters
      .filter(chapter => !chapter.proofread_chapter)
      .map(chapter => chapter.chapter_name);

    setUnproofreadChapters(unproofreadChapters);
    setShowWarning(unproofreadChapters.length > 0);	  
  }
  else if (exportVersion !== 'Original'&& exportVersion.includes('Proofread')) {
	  //console.log("Translated (Proofread)");
	const cleanExportVersion = exportVersion.replace('(Proofread)', '').trim();
	const [sourceLang, targetLang] = cleanExportVersion.split('-');
    const translatedChaptersKey = `translated_chapters_${sourceLang}_${targetLang}`;
	//console.log("translatedChaptersKey: "+translatedChaptersKey);
    const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];

    const unproofreadChapters = book.chapters
      .filter(chapter => {
        const translatedChapter = translatedChapters.find(tChapter => tChapter.chapter_guid === chapter.chapter_guid);
		return !translatedChapter?.proofread_text;
      })
      .map(chapter => chapter.chapter_name);
	//console.log("unproofreadChapters: "+unproofreadChapters);
    setUnproofreadChapters(unproofreadChapters);
    setShowWarning(unproofreadChapters.length > 0); 
  }
  else {
    setUntranslatedChapters([]);
	setShowWarning(false);
  }
  //console.timeEnd('useEffect 5');
}, [exportVersion, book.chapters, book.translation_pairs]);

useEffect(() => {
	//console.log("useEffect 6");
	//console.time('useEffect 6');
setProofreadVersion(selectedTab);
//console.timeEnd('useEffect 6');
}, [selectedTab]);

useEffect(() => {
  //console.log("useEffect 7");
  //console.time('useEffect 7');
  checkProofreadChapters();
  //console.timeEnd('useEffect 7');
}, [book.chapters, proofreadVersion]);


const checkProofreadChapters = () => {
  const [sourceLang, targetLang] = proofreadVersion.split('-');
  const translatedChaptersKey = `translated_chapters_${sourceLang}_${targetLang}`;
  const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];

  let proofreadChapters: string[] = [];
  let unproofreadChapters: string[] = [];
  let proofreadNotesChapters: string[] = [];
  let unproofreadNotesChapters: string[] = [];

  book.chapters.forEach(chapter => {
    let translatedChapter = proofreadVersion !== "Original"
      ? translatedChapters.find(tc => tc.chapter_guid === chapter.chapter_guid)
      : null;

    if (proofreadVersion === "Original" ? chapter.proofread_chapter : translatedChapter?.proofread_text) {
      //console.log("chapter.chapter_name: ",chapter.chapter_name);
	  //console.log("translatedChapter.proofread_text: ",translatedChapter?.proofread_text);
	  proofreadChapters.push(chapter.chapter_name);
    } else {
      unproofreadChapters.push(chapter.chapter_name);
    }

    if (proofreadVersion === "Original" ? chapter.proofread_comments : translatedChapter?.proofread_comments) {
      proofreadNotesChapters.push(chapter.chapter_name);
    } else {
      unproofreadNotesChapters.push(chapter.chapter_name);
    }
  });

  setUnproofreadChapters(unproofreadChapters);
  setHasUnproofreadChapters(unproofreadChapters.length > 0);
  setHasProofreadChapters(proofreadChapters.length > 0);
  setUnproofreadNotesChapters(unproofreadNotesChapters);
  setHasUnproofreadNotesChapters(unproofreadNotesChapters.length > 0);
  setHasProofreadNotesChapters(proofreadNotesChapters.length > 0);	
  
  //console.log("hasProofreadChapters:",hasProofreadChapters);
};

const toggleTranslateModal = () => {
  if (isToggling) return; // Prevent toggling if already in process
  setIsToggling(true); // Set toggling state to true
  
  if (isTranslateModalVisible) {
    // If the modal is visible, just close it
    setIsTranslateModalVisible(false);
    setIsToggling(false);
  } else {
    // If the modal is not visible, prepare to open it
    // setTargetLanguage('');
    if (auth.currentUser) {
      fetchCreditBalance(auth.currentUser.uid);
      setCreditCost(getCreditCost());
    }
    
    if (selectedChapter) {
      setTranslateVersion(selectedChapter.proofread_chapter ? 'Proofread' : 'Original');
    } else {
      setTranslateVersion(hasUnproofreadChapters ? 'Original' : 'Proofread');
    }
    
    // Open the modal after state updates
    setIsTranslateModalVisible(true);
    
    // Reset toggling state after a short delay
    setTimeout(() => {
      setIsToggling(false);
    }, 300);
  }
};

const toggleProofreadModal = () => {
    
  if (isToggling) return;
  
  setIsToggling(true);		
  setProofreadVersion("");
  setIsProofreadChecked(false);
  setIsEditingNotesChecked(false);
  setProofreadAdditionalInstructions("");
    if (auth.currentUser && !isProofreadModalVisible) {
      fetchCreditBalance(auth.currentUser.uid);
    } 
  if (unproofreadChapters.length > 0) {
	setHasUnproofreadChapters(true);
  }
  else
  {
	  setHasUnproofreadChapters(false);
  }
  setIsProofreadModalVisible(!isProofreadModalVisible);
  
  setTimeout(() => {
    setIsToggling(false); // Reset toggling state after a short delay
  }, 300);  
};

const handleMetadataTranslation = async () => {
  if (!auth.currentUser) {
    console.error("No user logged in");
    return;
  }
  setTargetMetadataLanguage(targetLanguage);
  setIsLoading(true);
  toggleMetadataTranslateModal();
  
  try {
    const translationPromises: Promise<any>[] = [];
    const translationTypes: string[] = [];

    // Translate Blurb
    if (translateMetadata.blurb) {
      translationPromises.push(axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/translate-blurb/', {
        userId: auth.currentUser.uid,
        bookId: bookId,
        sourceLang: bookLanguage,
        targetLang: targetLanguage,
		model: selectedAIModel,
		genre: genres.join(', '),
		quotationStyle: quotationStyle
      }));
      translationTypes.push('blurb');
    }

    // Translate Title
    if (translateMetadata.title) {
      translationPromises.push(axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/translate-title/', {
        userId: auth.currentUser.uid,
        bookId: bookId,
        title: book.title,
        series: book.series || '',
        blurb: blurb || '',
        sourceLang: bookLanguage,
        targetLang: targetLanguage,
		model: selectedAIModel
      }));
      translationTypes.push('title');
    }

    // Translate Series
    if (translateMetadata.series) {
      translationPromises.push(axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/translate-series/', {
        userId: auth.currentUser.uid,
        bookId: bookId,
        title: book.title || '',
        series: book.series,
        blurb: blurb || '',
        sourceLang: bookLanguage,
        targetLang: targetLanguage,
		model: selectedAIModel
      }));
      translationTypes.push('series');
    }

    const results = await Promise.all(translationPromises);
    const newTranslationOptions: TranslationOptions = {};
	
    results.forEach((response, index) => {
      const translationType = translationTypes[index];
      switch (translationType) {
        case 'blurb':
		  if (response.data.status == "success") {
			setBlurbTranslations(prevTranslations => ({
				...prevTranslations,
				[`${bookLanguage}_${targetLanguage}`]: response.data.translated_blurb
			}));
			message.success('Blurb translated successfully');
		  }
		  else {
			message.error('Blurb translation failed: '+ response.data.message,10);  
		  }
          break;
        case 'title':
          newTranslationOptions.titleOptions = parseTranslationOptions(response.data);
          break;
        case 'series':
          //console.log("Series options");
		  newTranslationOptions.seriesOptions = parseTranslationOptions(response.data);
		  //console.log("newTranslationOptions.seriesOptions: "+newTranslationOptions.seriesOptions);
          break;
        default:
          // Handle unknown type if necessary
          break;
      }
    });

    setTranslationOptions(newTranslationOptions);    
	
	//message.success('Metadata translated successfully');	

    if (translateMetadata.title || translateMetadata.series) {
      setIsLoading(false);
	  setIsTranslationOptionsModalVisible(true);
    }
	else {
	  fetchBookDetails();
	  setIsLoading(false);
	}

  } catch (error) {
    console.error("Error translating metadata:", error);
    message.error("Error translating metadata",10);
	setIsLoading(false);
  } 
};


const parseTranslationOptions = (xmlString: string): TranslationOption[] => {
  const validXmlString = xmlString
    .replace(/&/g, '&amp;')  // Replace & with &amp;
    .replace(/'/g, '&apos;') // Replace ' with &apos;
    .trim();
  //console.log("validXmlString: "+validXmlString);
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(validXmlString, "text/xml");

  if (xmlDoc.getElementsByTagName("parsererror").length) {
    console.error("Error parsing XML string");
    return [];
  }

  const options: TranslationOption[] = [];
  const xmlOptions = xmlDoc.getElementsByTagName("option");

  for (let i = 0; i < xmlOptions.length; i++) {
    const translatedTitleNode = xmlOptions[i].getElementsByTagName("translatedtitle")[0];
    const sourceTransNode = xmlOptions[i].getElementsByTagName("sourceTrans")[0];
    const descNode = xmlOptions[i].getElementsByTagName("desc")[0];

    options.push({
      translatedText: translatedTitleNode?.textContent || '', // Fallback to empty string if null
      sourceTrans: sourceTransNode?.textContent || '', // Fallback to empty string if null
      desc: descNode?.textContent || '' // Fallback to empty string if null
    });
  }

  return options;
};

  // Function to toggle metadata translation modal
  const toggleMetadataTranslateModal = () => {

  if (isToggling) return; // Prevent toggling if already in process

  setIsToggling(true);
  
  setSelectedAIModel("Claude3.7");
    
    if (auth.currentUser && !isTranslateModalVisible) {
      fetchCreditBalance(auth.currentUser.uid);
    } 
	
	setIsMetadataIsTranslateModalVisible(!isMetadataTranslateModalVisible);
    // Reset the translateMetadata state when opening the modal
    if (!isMetadataTranslateModalVisible) {
      setTranslateMetadata({ title: false, series: false, blurb: false });
    }
	else {
		//setTargetLanguage('')
	}
    
	setTimeout(() => {
      setIsToggling(false); // Reset toggling state after a short delay
    }, 300);	
  };

  // Function to toggle metadata translation modal
  const toggleKeywordSuggestionModal = () => {
    
	//setTargetLanguage("")
	
    if (auth.currentUser && !isKeywordSuggestionModalVisible) {
      fetchCreditBalance(auth.currentUser.uid);
    } 
	
	setIsKeywordSuggestionModalVisible(!isKeywordSuggestionModalVisible);
    // Reset the translateMetadata state when opening the modal
    if (!isKeywordSuggestionModalVisible) {
      setKeywordSuggestionMetadata({ genre: false, tropes: false, keywords: false });
    }
  };



const renderMetadataTranslationTabContent = (pair: string) => {
    const [sourceLang, targetLang] = pair.split('-').map(lang => lang.replace(/0/g, ' '));
    const titleTranslation = titleTranslations[pair] !== "<Not Translated>" ? titleTranslations[pair] : "";
    const seriesTranslation = seriesTranslations[pair] !== "<Not Translated>" ? seriesTranslations[pair] : "";
    const blurbTranslation = blurbTranslations[pair] !== "<Not Translated>" ? blurbTranslations[pair] : "";
    const keywordsTranslation = keywordTranslations[pair] || [];
	

  const handleTranMetadataEdit = (field: string) => {
    switch(field) {
      case 'title':
        setEditingTranMetadataTitle(true);
        setTempTranMetadataTitle(titleTranslation);
        break;
      case 'series':
        setEditingTranMetadataSeries(true);
        setTempTranMetadataSeries(seriesTranslation);
        break;
      case 'blurb':
        setEditingTranMetadataBlurb(true);
        setTempTranMetadataBlurb(blurbTranslation);
        break;
    }
	
	
	
  };

const sanitizeInput = (input: string): string => {
  // Define allowed tags
  const allowedTags = ['p', 'strong', 'em', 'u'];
  
  // Create a regex pattern to match allowed tags
  const allowedTagsPattern = allowedTags.join('|');
  const allowedTagsRegex = new RegExp(`</?(?:${allowedTagsPattern})(?:\\s[^>]*)?>`,'gi');
  
  // Replace disallowed tags with their content
  let sanitizedText = input.replace(/<(?!\/?(?:p|strong|em|u)(?:\s[^>]*)?>)[^>]+>/gi, '');
 
  return sanitizedText;
};


  const handleTranMetadataSave = async (field: string, pair: string) => {
  let value = '';
  switch (field) {
    case 'title':
      value = tempTranMetadataTitle || ''; // Use empty string if undefined
      break;
    case 'series':
      value = tempTranMetadataSeries || ''; // Use empty string if undefined
      break;
    case 'blurb':
      value = tempTranMetadataBlurb || ''; // Use empty string if undefined
      break;
    default:
      console.error('Unknown field:', field);
      return;
  }

    if (value === '') {
      handleTranMetadataCancel(field);
      return;
    }

    setIsLoading(true);
    try {
      const [sourceLang, targetLang] = pair.split('-');
      const sanitizedValue = sanitizeInput(value);

      const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/update-translation/', {
        userId: auth.currentUser?.uid,
        bookId: bookId,
        field,
        value: sanitizedValue,
        sourceLang,
        targetLang
      });

      if (response.data.status === 'success') {
        switch(field) {
          case 'title':
            setTitleTranslations({...titleTranslations, [pair]: sanitizedValue} as { [key: string]: string });
            setEditingTranMetadataTitle(false);
            break;
          case 'series':
            setSeriesTranslations({...seriesTranslations, [pair]: sanitizedValue} as { [key: string]: string });
            setEditingTranMetadataSeries(false);
            break;
          case 'blurb':
            setBlurbTranslations({...blurbTranslations, [pair]: sanitizedValue} as { [key: string]: string });
            setEditingTranMetadataBlurb(false);
            break;
        }
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error(`Error saving ${field} translation:`, error);
      message.error(`Error Saving ${field} translation`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTranMetadataCancel = (field: string) => {
    switch(field) {
      case 'title':
        setEditingTranMetadataTitle(false);
        break;
      case 'series':
        setEditingTranMetadataSeries(false);
        break;
      case 'blurb':
        setEditingTranMetadataBlurb(false);
        break;
    }
  };

const renderEditableField = (field: string, value: string, editing: boolean, tempValue: string, setTempValue: (value: string) => void) => {
  const handleCopy = async () => {
    try {
      // Create a temporary element to hold the HTML content
      const tempElement = document.createElement('div');
      tempElement.innerHTML = value;
  
      // Get the plain text version
      const plainText = tempElement.textContent || tempElement.innerText;
  
      // Use the Clipboard API to set both HTML and plain text
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/plain': new Blob([plainText], { type: 'text/plain' }),
          'text/html': new Blob([value], { type: 'text/html' })
        })
      ]);
  
      message.success('Copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
      message.error('Failed to copy');
    }
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['clean'] // remove formatting button
    ]
  };

  const getLabel = (field: string) => {
    switch (field) {
      case 'title':
        return 'Translated Title:';
      case 'series':
        return 'Translated Series Name:';
      case 'blurb':
        return 'Translated Blurb:';
      default:
        return field;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <strong>{getLabel(field)}</strong>
        {!editing && (
          <div style={{ marginLeft: '10px' }}>
            <EditOutlined
              onClick={() => handleTranMetadataEdit(field)}
              style={{ cursor: 'pointer', marginRight: '5px' }}
            />
            <CopyOutlined
              onClick={handleCopy}
              style={{ cursor: 'pointer' }}
            />
          </div>
        )}
        {editing && (
          <div style={{ marginLeft: '10px' }}>
            <SaveOutlined 
              onClick={() => handleTranMetadataSave(field, pair)}
              style={{ cursor: 'pointer', marginRight: '5px' }}
            />
            <CloseOutlined 
              onClick={() => handleTranMetadataCancel(field)}
              style={{ cursor: 'pointer' }}
            />
          </div>
        )}
      </div>
      {editing ? (
        <>
          {field === 'blurb' ? (
            <ReactQuill
              value={tempValue}
              onChange={setTempValue}
              modules={modules}
              style={{ marginBottom: '10px', minHeight: '300px' }}
            />
          ) : (
            <Input
              value={tempValue}
              onChange={(e) => setTempValue(e.target.value)}
              style={{ marginBottom: '10px' }}
            />
          )}
        </>
      ) : (
        <div>
          {field === 'blurb' ? (
            <div dangerouslySetInnerHTML={{ __html: value || '<Not translated>' }} />
          ) : (
            <Typography.Text>{value || '<Not translated>'}</Typography.Text>
          )}
        </div>
      )}
    </div>
  );
};

  // Creating a new JSX element array for the blurb with line breaks
const blurbElements = blurbTranslation ? blurbTranslation.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  )) : [];

  const keywordElements = keywordsTranslation.map((keyword, index) => {
    const [keywordText, meaning] = keyword.split('|');
    return (
      <tr key={index}>
        <td><strong>{keywordText}</strong></td>
        <td>{meaning}</td>
      </tr>
    );
  });

  return (
    <div>
      <div>
	  {/* <strong>Translated Title:</strong><br /> */}
        {renderEditableField('title', titleTranslation, editingTranMetadataTitle, tempTranMetadataTitle, setTempTranMetadataTitle)}
      </div><br />
      <div>
      {/* <strong>Translated Series Name:</strong><br /> */}
        {renderEditableField('series', seriesTranslation, editingTranMetadataSeries, tempTranMetadataSeries, setTempTranMetadataSeries)}
      </div><br />
      <div>
      {/* <strong>Translated Blurb:</strong><br /> */}
        {renderEditableField('blurb', blurbTranslation, editingTranMetadataBlurb, tempTranMetadataBlurb, setTempTranMetadataBlurb)}
      </div><br />
	  <div style={{ marginBottom: '10px' }}>
	  	<strong>Never Translate<Tooltip title="Edit Never Translate Text">
  <EditOutlined
    onClick={() => {
      //setCurrentLanguagePair(pair);
	  //console.log("currentLanguagePair: ",currentLanguagePair);
      setIsNeverTranslateModalVisible(true);
    }}
    style={{ cursor: 'pointer', marginLeft: '10px' }}
  />
</Tooltip></strong></div>
        <div>
          {neverTranslateWords[pair]?.map((word, index) => (
            <Tag key={index}>
              {word}
            </Tag>
          ))}			  
        </div>
		<br />
		<div style={{ marginBottom: '10px' }}>
<strong>Always Translate
                <Tooltip title="Edit Always Translate Text">
                    <EditOutlined
                        onClick={() => {
                            setIsAlwaysTranslateModalVisible(true);
                        }}
                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                    />
                </Tooltip>
            </strong>
        </div>
        <div>
            {alwaysTranslateWords[pair]?.map((word, index) => (
                <Tag key={index}>
                    {word.includes('|') ? word.replace('|', ' -> ') : word}
                </Tag>
            ))}
        </div>
        <Divider />
      <table style={{ width: '50%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ borderBottom: '1px solid #ddd', textAlign: 'left' }}>Keyword in {targetLang}</th>
            <th style={{ borderBottom: '1px solid #ddd', textAlign: 'left' }}>Meaning in {bookLanguage}</th>
          </tr>
        </thead>
        <tbody>
          {keywordElements}
        </tbody>
      </table>
      <Divider />

	<Button 
  onClick={() => handleDeleteTranslatedChapters(sourceLang, targetLang)}
  style={{marginTop: '10px', marginRight: '10px' }}
  icon={<DeleteOutlined />}
>
  Delete Translated Chapters
</Button>

	<Button 
  onClick={() => handleDeleteProofreadChapters(sourceLang, targetLang)}
  style={{marginTop: '10px' }}
  icon={<DeleteOutlined />}
>
  Delete Proofread Chapters
</Button>
	  
    </div>
  );
};

 
  const renderTranslatedTabContent = (sourceLang: string, targetLang: string) => {
    const translatedChaptersKey = `translated_chapters_${sourceLang}_${targetLang}`;
    const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey];

    if (translatedChapters && selectedChapter) {
      const translatedChapter = translatedChapters.find(chap => chap.chapter_guid === selectedChapter.chapter_guid);
      if (translatedChapter) {
const copyToClipboard = () => {
  const dummyElement = document.createElement('div');

  dummyElement.innerHTML = removeHyperlinks(translatedChapter.translated_text).replace(/\p{Cf}/gu, '');
    
  // Make sure the dummy element is not displayed
  dummyElement.style.position = 'absolute';
  dummyElement.style.left = '-9999px';
  document.body.appendChild(dummyElement);

  const range = document.createRange();
  range.selectNode(dummyElement);

  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
      message.success('Translated text copied to clipboard');
    } catch (err) {
      message.error('Failed to copy text to clipboard');
    }

    selection.removeAllRanges();
  } else {
    message.error('Failed to access clipboard');
  }

  // Remove the dummy element from the DOM
  document.body.removeChild(dummyElement);
};
		  
        return {
          content: (
		    <div style={{flex: 1, overflowY: 'auto', height: 'calc(65vh - 10px)' }} >
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0px', marginRight: '15px' }}>
<Tooltip title={translatedChapter.api_model 
  ? `Translated with ${translatedChapter.api_model}` 
  : "Translated before AI model tracking was implemented"}>
  <InfoCircleOutlined style={{ color: '#1890ff', marginRight: '10px' }} />
</Tooltip>
			<Tooltip title="Copy Translated Chapter Text to Clipboard">
			<Button
              onClick={copyToClipboard}
              type="primary"
              icon={<CopyOutlined />}
			  style={{marginRight: '10px'}}
            >
            </Button>
			</Tooltip>			
			<Tooltip title="Delete this Chapter's Translation">
              <Button
                onClick={() => handleDeleteSingleTranslatedChapter(sourceLang, targetLang)}
                type="primary"
				icon={<DeleteOutlined />}
                danger
              >
              </Button>	
			  </Tooltip>
			</div>
              <div style={{marginRight: '15px'}} dangerouslySetInnerHTML={{ __html: removeHyperlinks(translatedChapter.translated_text) }} />

            </div>
          ),
          isTranslated: true
        };
      }
    }
    return { content: <div>            <span style={{ color: '#CC5500' }}>
              <ExclamationCircleOutlined /> This chapter has not yet been translated.
            </span></div>, isTranslated: false };
  };



const syncScroll = (e: React.UIEvent<HTMLDivElement>, otherPanelId: string) => {
  const otherPanel = document.getElementById(otherPanelId) as HTMLDivElement;
  if (otherPanel) {
    otherPanel.scrollTop = e.currentTarget.scrollTop;
  }
};

const renderProofreadTabContent = (sourceLang: string, targetLang: string) => {
  const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',  // Change to row for side-by-side layout
    alignItems: 'flex-start'
  };

  const panelStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  };

  const contentStyle: CSSProperties = {
    maxHeight: '43vh',
    overflowY: 'auto',
    border: '1px solid #ccc',
    padding: '10px',
	marginRight: '15px'
  };

const copyProofreadToClipboard = (proofreadText: string) => {
  const dummyElement = document.createElement('div');
  dummyElement.innerHTML = removeHyperlinks(proofreadText);

  // Make sure the dummy element is not displayed
  dummyElement.style.position = 'absolute';
  dummyElement.style.left = '-9999px';
  document.body.appendChild(dummyElement);

  const range = document.createRange();
  range.selectNode(dummyElement);

  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
      message.success('Proofread content copied to clipboard');
    } catch (err) {
      message.error('Failed to copy proofread content to clipboard');
    }

    selection.removeAllRanges();
  } else {
    message.error('Failed to access clipboard');
  }

  // Remove the dummy element from the DOM
  document.body.removeChild(dummyElement);
};

const cleanHtml = (html: string): string => {
    const bodyMatch = html.match(/<body[^>]*>/i);
    if (bodyMatch) {
        const bodyIndex = html.indexOf(bodyMatch[0]);
        html = html.substring(bodyIndex + bodyMatch[0].length);
    }

    html = html.replace(/\n/gi, '');

    // Remove <title> tags and their content
    html = html.replace(/<title[^>]*>.*?<\/title>/gi, '');

    // Allow only bold, italics, strikethrough, and heading tags
    html = html.replace(/<(?!\/?(b|i|em|strong|s|del|u|p|h[1-6])\b)[^>]+>/gi, '');

    // Replace spans
    html = html.replace(/<\/?span[^>]*>/gi, '');

    // Standardize paragraph tags
    html = html.replace(/<p>/gi, '\n\n').replace(/<\/p>/gi, '');

    // Remove any remaining tags except allowed ones
    html = html.replace(/<\/?(?!b|i|em|strong|s|del|u|p|h[1-6])[a-z][^>]*>/gi, '');

    const doc = new DOMParser().parseFromString(html, 'text/html');
    let textContent = doc.body.innerHTML || '';

    return textContent;
};

const normalizeText = (text: string) => {
  return text
    .replace(/[‘’]/g, "'") // Replace curly single quotes with straight single quotes
    .replace(/[′]/g, "'")
    .replace(/[“”]/g, '"') // Replace curly double quotes with straight double quotes
    .replace(/\.{3}/g, '…'); // Normalize three dots to ellipses
};

  const renderDiff = (originalText: string, proofreadText: string): string => {
    let cleanedOriginalText = normalizeText(cleanHtml(originalText));
    let cleanedProofreadText = normalizeText(cleanHtml(proofreadText));
	
    cleanedOriginalText = cleanedOriginalText.replace(/\n\n/g, '<p></p>')
                                             .replace(/\s*<p>/g, '<p>')
                                             .replace(/<\/p>\s*/g, '</p>')
                                             .replace(/<p><\/p>\s*<p><\/p>/g, '<p></p>');
    cleanedProofreadText = cleanedProofreadText.replace(/\n\n/g, '<p></p>')
                                             .replace(/\s*<p>/g, '<p>')
                                             .replace(/<\/p>\s*/g, '</p>')
                                             .replace(/<p><\/p>\s*<p><\/p>/g, '<p></p>');


	//console.log("proofreadText:\n"+proofreadText);
	//console.log("cleanedOriginalText:\n"+cleanedOriginalText);
	//console.log("cleanedProofreadText:\n"+cleanedProofreadText);

    const diffResult = diffWords(cleanedOriginalText, cleanedProofreadText);

    let result = diffResult.map((part: DiffPart) => {
      const color = part.added ? '#90EE90' : part.removed ? '#FFB6C1' : 'white'; // Light green for added, light red for removed
      return `<span style="background:${color};">${part.value}</span>`;
    }).join('');
	
	//console.log("result 1:\n"+result);
    //result = result.replace(/\n\n/gi, '<p></p>');
	//console.log("result 2:\n"+result);
    return result;
  };

  let proofreadContent = null;
  let originalText = "";
  let ProofreadText = "";
  let editingNotesContent = null;
  let formattedDiff = null;
  let isProofread = true;

  if (selectedChapter) {
	if (sourceLang == "Original") {
		originalText = selectedChapter.content;
		ProofreadText = selectedChapter.proofread_chapter;
	}
	
	else {				
		const translatedChaptersKey = `translated_chapters_${sourceLang}_${targetLang}`;
		const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey];
        const translatedChapter = translatedChapters.find(chap => chap.chapter_guid === selectedChapter.chapter_guid);
		if (translatedChapter) {
			originalText = translatedChapter.translated_text;
			ProofreadText = translatedChapter.proofread_text; 
		}
	}
   
	if (ProofreadText) {		
	proofreadContent = (
      <div>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            marginBottom: '10px' 
          }}>
            {/* Left spacer for balance */}
            <div style={{ width: '80px' }}></div>
            
            {/* Centered radio group */}
            <Radio.Group 
              value={viewMode} 
              onChange={(e) => setViewMode(e.target.value)}
            >
              <Radio.Button value="sideBySide">Side-by-Side View</Radio.Button>
              <Radio.Button value="inlineDiff">In-Line View</Radio.Button>
              <Radio.Button value="proofreadOnly">Proofread Only</Radio.Button> 
            </Radio.Group>
            
            {/* Right-justified action buttons */}
            <div style={{ width: '80px', display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
              {viewMode === 'proofreadOnly' && (
                <>
                  <Tooltip title="Copy Proofread Content to the Clipboard">
				  <Button
                    type="primary"
                    icon={<CopyOutlined />}
                    onClick={() => copyProofreadToClipboard(ProofreadText)}
                    style={{ marginRight: '10px' }}
                  />
				  </Tooltip>
				  <Tooltip title="Delete this Chapter's Proofreading">
                  <Button
                    type="primary"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteSingleProofreadChapter(sourceLang, targetLang)}
                  />
				  </Tooltip>
                </>
              )}
            </div>
          </div>

            {viewMode === 'sideBySide' && (
              <div style={containerStyle}>
                {/* Side-by-Side Comparison */}
                <div style={panelStyle}>
                  <h3 style={{ textAlign: 'center' }}>Original Content</h3>
                  <div 
                    style={contentStyle} 
                    id="leftPanel" 
                    onScroll={(e) => syncScroll(e, 'rightPanel')}
                    dangerouslySetInnerHTML={{ __html: removeHyperlinks(originalText) }}
                  />
                </div>
                <div style={panelStyle}>
                  <h3 style={{ textAlign: 'center' }}>Proofread Content</h3>
                  <div 
                    style={contentStyle} 
                    id="rightPanel" 
                    onScroll={(e) => syncScroll(e, 'leftPanel')}
                    dangerouslySetInnerHTML={{ __html: removeHyperlinks(ProofreadText) }}
                  />
                </div>
              </div>
            )}

            {viewMode === 'inlineDiff' && (
              <div style={panelStyle}>
                <h3 style={{ textAlign: 'center' }}>
                In-Line View
				<Tooltip 
				title={[
					"Added text is highlighted in green, removed text in pink.\n",
					"Some normally hidden formatting elements may be lost or exposed in this view.\n",
					"Additionally, formatting such as bold, italics, alignment, etc., will not be displayed."
				].join('\n')}
				>
				<QuestionCircleOutlined style={{ marginLeft: '5px', color: 'red' }} />
				</Tooltip>
              </h3>
                <div 
                  style={contentStyle} 
                  dangerouslySetInnerHTML={{ __html: renderDiff(removeHyperlinks(originalText), removeHyperlinks(ProofreadText)) }}
                />
              </div>
            )}  
        {viewMode === 'proofreadOnly' && (
          <div style={panelStyle}>
    <div style={{ position: 'relative', textAlign: 'center' }}>	  
      <h3>Proofread Content</h3>
    </div>
            <div 
              style={contentStyle} 
              dangerouslySetInnerHTML={{ __html: removeHyperlinks(ProofreadText) }}
            />
          </div>
        )}			

      </div>
    );
  }
  }
		  
          {/* Proofread Comments Section */}
  if (selectedChapter ) { //&& selectedChapter.proofread_comments

	let proofreadComments = "";

	if (sourceLang == "Original") {
		proofreadComments = selectedChapter.proofread_comments;
	}
	
	else {
				
		const translatedChaptersKey = `translated_chapters_${sourceLang}_${targetLang}`;
		const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey];
        const translatedChapter = translatedChapters.find(chap => chap.chapter_guid === selectedChapter.chapter_guid);
		if (translatedChapter && translatedChapter.proofread_comments) {
			proofreadComments = translatedChapter.proofread_comments; 
		}
	}

	if (proofreadComments) {
    editingNotesContent = (
            <div>
              <h3 style={{ textAlign: 'center' }}>Editing Notes</h3>
              <div 
                style={{ ...contentStyle, maxHeight: '300px' }} 
                dangerouslySetInnerHTML={{ __html: proofreadComments }}
              />
              {/* Delete Editing Notes Button */}
              <Button
                type="primary"
                danger
                onClick={() => deleteEditingNotes(selectedChapter.chapter_guid, sourceLang, targetLang)}
                style={{ marginTop: '10px' }}
              >
                Delete Editing Notes
              </Button>
            </div>
          );
  }}

if (!proofreadContent) {
	isProofread=false;
	proofreadContent = (<Tooltip title="This chapter has not been proofread yet.">
            <span style={{ color: '#CC5500' }}>
              <ExclamationCircleOutlined /> This chapter is not proofread yet.
            </span>
          </Tooltip>);
}

if (!editingNotesContent) {
	editingNotesContent = (<Tooltip title="This chapter has no editing notes yet.">
            <span style={{ color: '#CC5500' }}>
              <ExclamationCircleOutlined /> This chapter has no editing notes yet.
            </span>
          </Tooltip>);
}

  return { 
    content: (
      <div style={{ 
			overflowY: 'auto',
			height: '65vh'
          }}>
        {proofreadContent}
		<br />
        {editingNotesContent}
        {!proofreadContent && !editingNotesContent && (
          <Tooltip title="This chapter has not been proofread yet.">
            <span style={{ color: '#CC5500' }}>
              <ExclamationCircleOutlined /> This chapter is not proofread yet.
            </span>
          </Tooltip>
        )}
      </div>
    ),
    isProofread: isProofread
  };
};

const fetchBookDetails = () => {
  setIsLoading(true);
  let newRejectedChapters: string[] = [];

  axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/book/${bookId}`)
    .then(response => {
      const fetchedBook = response.data;
      setBookLanguage(fetchedBook.language || 'English');
	  setBlurb(fetchedBook.blurb);
	  setGenres(fetchedBook.genre || []);
	  setTropes(fetchedBook.tropes || []);
	  setKeywords(fetchedBook.keywords || []);
	  setSourceLanguage(fetchedBook.language);
	  setUploadType(fetchedBook.uploadType || '');
	  setExportFormat(fetchedBook.uploadType || '');
	  //setShowSummarizeOffer(fetchedBook.showSummaryOffer ?? true);
        
	  const chapters = (fetchedBook.chapters || []).sort((a: Chapter, b: Chapter) => a.order - b.order);
	  const sortedChapters = (fetchedBook.chapters || []).sort((a: Chapter, b: Chapter) => a.order - b.order);
      
	  setBook({ ...fetchedBook, chapters });

	  setEditBook({ 
	  title: fetchedBook.title, 
	  author: fetchedBook.author, 
	  series: fetchedBook.series, 
	  booknumber: fetchedBook.seriesBookNumber, 
	  genres: fetchedBook.genres || [], 
	  tropes: fetchedBook.tropes || [], 
	  keywords: fetchedBook.tropes || [] 
	  });

	  //console.log("editBook.title: "+editBook.title);

	const temp2 = "never_translate_English-Italian"
	//console.log("never translate English-Italian", fetchedBook[temp2]);

      // Fetch and set Always Translate and Never Translate words for each translation pair
      
	  const alwaysTranslateWords: { [key: string]: string[] } = {};
      const neverTranslateWords: { [key: string]: string[] } = {};

      fetchedBook.translation_pairs?.forEach((pair: string) => {
        const [sourceLang, targetLang] = pair.split('-').map(lang => lang.replace(' ', '0'));
        const alwaysTranslateKey = `always_translate_${sourceLang}_${targetLang}`;
        const neverTranslateKey  = `never_translate_${sourceLang}_${targetLang}`; 
		//console.log("neverTranslateKey: ",neverTranslateKey)
        alwaysTranslateWords[pair] = fetchedBook[alwaysTranslateKey] || [];
        neverTranslateWords[pair] = fetchedBook[neverTranslateKey] || [];
		//console.log("Setting never translate words for pair:", pair, neverTranslateWords[pair]);
      });

      setAlwaysTranslateWords(alwaysTranslateWords);
      setNeverTranslateWords(neverTranslateWords);
	  
      newRejectedChapters = sortedChapters.filter((chapter: Chapter) => {
		if (chapter.rejected_content) {
          //console.log(`Chapter "${chapter.chapter_name}" was rejected for content`);
          return true;
        }
		else {
                //console.log("RJ check: "+chapter.chapter_name+" "+chapter.rejected_content);
		return false;
		}
      }).map((chapter: Chapter) => chapter.chapter_name);

      setRejectedChapters(newRejectedChapters);

        // Extracting translated blurbs, titles, and series
        const extractedBlurbs: BlurbTranslations = {};
        const extractedTitles: { [key: string]: string } = {};
        const extractedSeries: { [key: string]: string } = {};
		const extractedKeywords: { [key: string]: string[] } = {};
        fetchedBook.translation_pairs?.forEach((pair: string) => {
          const [sourceLang, targetLang] = pair.split('-').map(lang => lang.replace(' ', '0')); // Adjust for field names in Firestore
          const blurbKey = `blurb_${sourceLang}_${targetLang}`;
          const titleKey = `title_${sourceLang}_${targetLang}`;
          const seriesKey = `series_${sourceLang}_${targetLang}`;
		  const keywordsKey = `keywords_${sourceLang}_${targetLang}`;
          if (fetchedBook[blurbKey]) extractedBlurbs[pair] = fetchedBook[blurbKey];
          if (fetchedBook[titleKey]) extractedTitles[pair] = fetchedBook[titleKey];
          if (fetchedBook[seriesKey]) extractedSeries[pair] = fetchedBook[seriesKey];
		  if (fetchedBook[keywordsKey]) extractedKeywords[pair] = fetchedBook[keywordsKey];
        });
        setBlurbTranslations(extractedBlurbs);
        setTitleTranslations(extractedTitles);
        setSeriesTranslations(extractedSeries);
		setKeywordTranslations(extractedKeywords);

	checkProofreadChapters()

      const chapterOrder: Record<string, number> = {};
      chapters.forEach((chapter: Chapter, index: number) => {
        chapterOrder[chapter.chapter_guid] = index + 1;
      });
      setOriginalOrder(chapterOrder);
    })
    .catch(error => {
      console.error("Error fetching book details:", error);
    })
    .finally(() => {
	  if(firstEnter) {
	  setIsEditing(true);
	  setFirstEnter(false);
	  }
      setIsLoading(false);
    });
}

  const calculateMetadataTranslationCreditCost = (): number => {
    let metadataWordCount = 0;
    if (translateMetadata.title) metadataWordCount += 50;
    if (translateMetadata.series) metadataWordCount += 50;
    if (translateMetadata.blurb) metadataWordCount += countWords(book.blurb || '');
    return metadataWordCount; // Assuming 1 credit per word, adjust as needed
  };

  const countWords = (text: string): number => {
    return text.trim().split(/\s+/).length;
  };

const getDefaultLanguage = async () => {
    try {
        const userID = auth.currentUser?.uid;
        const languageResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/get-default-language', {
          params: { userID: userID }});
        if (languageResponse.data && languageResponse.data.defaultLanguage) {
            const defaultLanguage = languageResponse.data.defaultLanguage;
            
            // Check if default language matches the book language
            if (defaultLanguage === bookLanguage) {
                // If both are English, set target to German
                if (defaultLanguage === 'English') {
                    setTargetLanguage('German');
                } else {
                    // For any other language, set target to English
                    setTargetLanguage('English');
                }
            } else {
                // If they don't match, use the default language as target
                setTargetLanguage(defaultLanguage);
            }
        }
    } catch (error) {
        console.error('Error fetching default language:', error);
    }
};

  useEffect(() => {
	  //console.log("useEffect 9");
	  //console.time('useEffect 9');
    const cost = calculateMetadataTranslationCreditCost();
    setUserHasEnoughCreditsMetadata((creditBalance?.credit_balance ?? 0) >= cost);
	//console.timeEnd('useEffect 9');
  }, [translateMetadata, book, creditBalance]);

useEffect(() => {
	//console.log("useEffect 10");
	//console.time('useEffect 10');
	fetchBookDetails();
	getDefaultLanguage();
	//console.timeEnd('useEffect 10');
}, [bookId]);

const handleEditToggleWithCallback = (callback?: () => void) => {
  setIsEditing((prevIsEditing) => {
    const newIsEditing = !prevIsEditing;
    if (!newIsEditing) {
      setEditBook({ title: book.title, author: book.author, series: book.series, booknumber: book.seriesBookNumber, genres: book.genres || [], tropes: book.tropes || [], keywords: book.tropes || [] });
    }
    setSelectedChapter(null);
    if (callback) callback();
    return newIsEditing;
  });
};

const handleEditToggle = () => {
  setIsEditing(!isEditing);
  setEditBook({ title: book.title, author: book.author, series: book.series, booknumber: book.seriesBookNumber, genres: book.genres || [], tropes: book.tropes || [], keywords: book.tropes || [] });
  setSelectedChapter(null);
  //handleEditToggleWithCallback();
};

  const handleInputChange = (key: keyof typeof editBook, value: string) => {
    setEditBook({ ...editBook, [key]: value });
	setHasUnsavedChanges(true);
    // If the key is 'title', then also update the isTitleValid state
    if (key === 'title') {
      setIsTitleValid(!!value.trim()); // title is valid if it's not empty or just whitespace
    }
  };

const handleSave = async () => {
  if (!auth.currentUser) {
    console.error("No user logged in");
    return;
  }

  if (!isTitleValid) {
    alert("The title is required."); // Provide feedback to the user
    return;
  }

  setIsLoading(true);

  try {
    await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/update-book/', { 
		bookID: bookId,
		userID: auth.currentUser.uid,
		title: editBook.title,
		author: editBook.author,
		series: editBook.series,
		booknumber: editBook.booknumber,
		language: bookLanguage,
		genre: genres,
		tropes: tropes,
		keywords: keywords,
    });
    setBook({ ...book, ...editBook, genres: genres });
    //setIsEditing(false);
	
	//savetheblurb
	if (blurb) {
		handleBlurbSave();
	}
	
	//refresh after update
    const updatedBookResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/book/${bookId}`);
    let updatedBook: Book = updatedBookResponse.data;
    updatedBook.chapters = updatedBook.chapters.sort((a: Chapter, b: Chapter) => a.order - b.order);
    setBook(updatedBook);	
  } catch (error) {
    console.error("Error saving book details:", error);
  }
  setHasUnsavedChanges(false);
  setIsLoading(false);
};






  const deleteTranslation = async (chapterGuid: string, sourceLang: string, targetLang: string) => {
    if (!auth.currentUser) {
      console.error("No user logged in");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        `https://askpoeai-nswdzfky7q-uc.a.run.app/delete-translated-chapter/${bookId}/${chapterGuid}`, 
        {
          user_id: auth.currentUser.uid,
          source_language: sourceLang,
          target_language: targetLang
        }
      );

    if (response.data.status === "error") {
      throw new Error(response.data.message);
    }
	
      // Refresh the book data to reflect the deletion
      const updatedBookResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/book/${bookId}`);
      let updatedBook: Book = updatedBookResponse.data;
      updatedBook.chapters = updatedBook.chapters.sort((a: Chapter, b: Chapter) => a.order - b.order);
      setBook(updatedBook);
      message.success("Translation deleted successfully.");
    } catch (error) {
      console.error("Error deleting translation:", error);
      message.error("Error deleting translation",10);
    } finally {
      setIsLoading(false);
    }
  };


  const deleteProofread = async (chapterGuid: string, sourceLang: string, targetLang: string) => {
    if (!auth.currentUser) {
      console.error("No user logged in");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        `https://askpoeai-nswdzfky7q-uc.a.run.app/delete-proofread-chapter/${bookId}/${chapterGuid}`, 
        {
          user_id: auth.currentUser.uid,
		  sourceLang: sourceLang,
		  targetLang: targetLang
        }
      );

    if (response.data.status === "error") {
      throw new Error(response.data.message);
    }
	
      // Refresh the book data to reflect the deletion
      const updatedBookResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/book/${bookId}`);
      let updatedBook: Book = updatedBookResponse.data;
      updatedBook.chapters = updatedBook.chapters.sort((a: Chapter, b: Chapter) => a.order - b.order);
      setBook(updatedBook);
      message.success("Proofread Deleted Successfully.");
    } catch (error) {
      console.error("Error deleting translation:", error);
      message.error("Error deleting translation",10);
    } finally {
      setSelectedChapter(null);
	  setIsLoading(false);
    }
  };


  const deleteEditingNotes = async (chapterGuid: string, sourceLang: string, targetLang: string) => {
    if (!auth.currentUser) {
      console.error("No user logged in");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        `https://askpoeai-nswdzfky7q-uc.a.run.app/delete-editnotes-chapter/${bookId}/${chapterGuid}`, 
        {
          user_id: auth.currentUser.uid,
		  sourceLang: sourceLang,
		  targetLang: targetLang
        }
      );

    if (response.data.status === "error") {
      throw new Error(response.data.message);
    }
	
      // Refresh the book data to reflect the deletion
      const updatedBookResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/book/${bookId}`);
      let updatedBook: Book = updatedBookResponse.data;
      updatedBook.chapters = updatedBook.chapters.sort((a: Chapter, b: Chapter) => a.order - b.order);
      setBook(updatedBook);
      message.success("Editing Notes Deleted Successfully.");
    } catch (error) {
      console.error("Error deleting translation:", error);
      message.error("Error deleting translation",10);
    } finally {
      setSelectedChapter(null);
	  setIsLoading(false);
    }
  };

  const handleChange = (key: keyof Book, value: string) => {
    setBook({ ...book, [key]: value });
  };

  const selectChapter = (chapter: Chapter) => {
    setSelectedChapter(chapter);
	getProofreadOptionProps();
	if (translateVersion == 'Proofread' && proofreadOptionProps.disabled) {
		setTranslateVersion('Original')
	}
    // Close the editing screen when a chapter is selected
    setIsEditing(false);
  };

    const handleLanguageChange = (value: string, type: 'source' | 'target') => {
		if (type === 'source') {
            setSourceLanguage(value);
            if (value === targetLanguage) {
                message.error('Source and target languages must be different');
            }
        } else {
            setTargetLanguage(value);
			updateUntranslatedChaptersAndWordCount(value, selectedAIModel);
            if (value === bookLanguage) {
                message.error('Source and target languages must be different');
            }
        }
    };

  const handleAIModelChange = (value: string): void => {
    // Update the selected AI model and pass the new value to the language change handler
    setSelectedAIModel(value);
    updateUntranslatedChaptersAndWordCount(targetLanguage, value);
  };

const updateUntranslatedChaptersAndWordCount = (newTargetLanguage: string, aiModel: string) => {
  //console.log("updateUntranslatedChaptersAndWordCount");
  if (newTargetLanguage === 'Original') {
    // If the version is 'Original', do not show any warning and reset untranslated chapters list
    setUntranslatedChapters([]);
    setShowWarning(false);
    return; // Early return to skip the rest of the function
  }  
  const translatedChaptersKey = `translated_chapters_${bookLanguage}_${newTargetLanguage}`;
  const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];
  const untranslated = book.chapters.filter(chapter =>
    !translatedChapters.some(tChapter => tChapter.chapter_guid === chapter.chapter_guid)
  ).map(chapter => chapter.chapter_name);

  setUntranslatedChapters(untranslated);
  setShowWarning(untranslated.length > 0);
  const newWordCount = calculateWordCount(newTargetLanguage,aiModel);
  setUserHasEnoughCredits(creditBalance !== null && creditBalance.credit_balance >= newWordCount);
  //console.log(creditBalance);
  //if (creditBalance !== null) {
  //  console.log(creditBalance.credit_balance);
  //}
  //console.log(newWordCount);
  //console.log(creditBalance !== null && creditBalance.credit_balance >= newWordCount);
  //console.log("userHasEnoughCredits: "+userHasEnoughCredits);
};

useEffect(() => {
	//console.log("useEffect 11");
	//console.time('useEffect 11');
  if (!selectedChapter) {
    updateUntranslatedChaptersAndWordCount(targetLanguage, selectedAIModel);
  }
  //console.timeEnd('useEffect 11');
}, [book, targetLanguage]);


const handleTranslation = async () => {
  const userId = auth.currentUser?.uid;
  if (!userId) {
    console.error("No user logged in");
    return;
  }

  toggleTranslateModal();
  setIsConfirmationModalVisible(false);

  try {
    // If a specific chapter is selected, translate only that chapter
    if (selectedChapter) {
      const newTranslation: TranslationStatus = {
        chapterGuid: selectedChapter.chapter_guid,
        chapterName: selectedChapter.chapter_name,
        sourceLang: bookLanguage,
        targetLang: targetLanguage,
        timestamp: new Date().toISOString(),
        status: 'pending',
        bookId: bookId,
        userId: auth.currentUser?.uid,
        word_count: selectedChapter.word_count,
        estimatedTimeMinutes: Math.ceil(selectedChapter.word_count / 500),
        lockGuid: 'Temp',
		error: '',
		type: 'Translation'
      };
        
      setActiveTranslations(prev => [...prev, newTranslation]);		
		
      const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/translate-chapter/', {
        userId: userId,
        bookId: bookId,
        chapterGuid: selectedChapter.chapter_guid,
        sourceLang: bookLanguage,
        targetLang: targetLanguage,
        model: selectedAIModel,
        version: translateVersion,
        genre: genres.join(', '),
        quotationStyle: quotationStyle,
        overwriteFlag: true
      });
	  	    
      if (response.data.status === "error") {
        message.error(response.data.message, 10);
        if (response.data.message.includes("rejected content")) {
          setRejectedChapters([selectedChapter.chapter_name]);
        }
        setIsLoading(false);
        return;
      }
	  
      await fetchActiveTranslations();
      message.success('Translation completed successfully.', 10);
	  
    } else {
      await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/analyze-missing-relationships/', {
        userId: userId,
        bookId: bookId,
        sourceLang: bookLanguage,
        targetLang: targetLanguage,
        api_model: 'Claude3.7'
      });
		
      // Translate all untranslated chapters
      const chaptersToTranslate = book.chapters
        .filter(chapter => {
          const translatedChaptersKey = `translated_chapters_${bookLanguage}_${targetLanguage}`;
          const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];
          return !translatedChapters.some(tChapter => tChapter.chapter_guid === chapter.chapter_guid);
        });
      
      // Manually add records for each chapter to activeTranslations
      const newTranslations: TranslationStatus[] = chaptersToTranslate.map(chapter => ({
        chapterGuid: chapter.chapter_guid,
        chapterName: chapter.chapter_name,
        sourceLang: bookLanguage,
        targetLang: targetLanguage,
        timestamp: new Date().toISOString(),
        status: 'pending',
        bookId: bookId,
        userId: auth.currentUser?.uid,
        word_count: chapter.word_count,
        estimatedTimeMinutes: Math.ceil(chapter.word_count / 750),
        lockGuid: 'Temp',
		error: '',
		type: 'Translation'
      }));
      
      // Update state immediately before making API calls
      setActiveTranslations(prev => [...prev, ...newTranslations]); 
	  
      const chapterPromises = chaptersToTranslate.map(chapter => {
        return axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/translate-chapter/', {
          userId: userId,
          bookId: bookId,
          chapterGuid: chapter.chapter_guid,
          sourceLang: bookLanguage,
          targetLang: targetLanguage,
          model: selectedAIModel,
          version: translateVersion,
          genre: genres.join(', '),
          quotationStyle: quotationStyle,
          overwriteFlag: false
        });
      });
		     
      // Start all translations without waiting for completion
      await Promise.allSettled(chapterPromises);
      
      //message.success('Translation requests submitted successfully.', 10);
    }
	
  } catch (error) {
    console.error('General translation error:', error);
    message.error('Error during translation', 10);
    fetchActiveTranslations();
  } finally {
    // Refresh the book data to reflect the new translations
    fetchActiveTranslations();
    fetchBookDetails();
    setIsLoading(false);
  } 
};


const handleProofread = async () => {
  const userId = auth.currentUser?.uid;
  if (!userId) {
    console.error("No user logged in");
    return;
  }

  toggleProofreadModal();
  setIsProofreadConfirmationModalVisible(false);
  setFailedProofreads([]);
  setSuccessfulProofreads([]);
  setFailedProofreadNotes([]);
  setSuccessfulProofreadNotes([]);

  const sanitizeInput = (input: string): string => {
    // Remove any HTML tags
    input = input.replace(/<[^>]*>/g, '');
    
    // Limit the length of the input
    const maxLength = 250 // Adjust this value as needed
    input = input.slice(0, maxLength);
    
    return input;
  };

  let failedProofreadChapters: string[] = [];
  let successfulProofreadChapters: string[] = [];
  let failedNotesChapters: string[] = [];
  let successfulNotesChapters: string[] = [];  

  try {
    const proofreadStatus: Record<string, string> = {};
    const notesStatus: Record<string, string> = {};
    
    book.chapters.forEach(chapter => {
      proofreadStatus[chapter.chapter_name] = 'pending';
      notesStatus[chapter.chapter_name] = 'pending';
    });

    // If a specific chapter is selected, proofread only that chapter
    if (selectedChapter) {
      if (isProofreadChecked) {
        // Create temporary record for the proofread task
        const newProofread: TranslationStatus = {
          chapterGuid: selectedChapter.chapter_guid,
          chapterName: selectedChapter.chapter_name,
          sourceLang: bookLanguage,
          targetLang: proofreadVersion,
          timestamp: new Date().toISOString(),
          status: 'pending',
          bookId: bookId,
          userId: auth.currentUser?.uid,
          word_count: selectedChapter.word_count,
          estimatedTimeMinutes: Math.ceil(selectedChapter.word_count / 500),
          lockGuid: 'Temp',
          error: '',
          type: 'Proofread'
        };
        
        setActiveTranslations(prev => [...prev, newProofread]);
        
        const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/proofread-chapter/', {
          userId: userId,
          bookId: bookId,
          chapterGuid: selectedChapter.chapter_guid,
          sourceLang: bookLanguage,
          model: selectedAIModel,
          genre: genres.join(', '),
          tense: chapterTense,
          proofreadVersion: proofreadVersion,
          quotationStyle: quotationStyle,
          additionalInstructions: sanitizeInput(proofreadAdditionalInstructions)
        });
    
        if (response.data.status === "error") {
          message.error(response.data.message, 10);
          if (response.data.message.includes("rejected content")) {
            setRejectedChapters([selectedChapter.chapter_name]);
          }
          setIsLoading(false);
          return;
        }
      }
      
      if (isEditingNotesChecked) {
        // Create temporary record for the editing notes task
        const newEditingNotes: TranslationStatus = {
          chapterGuid: selectedChapter.chapter_guid,
          chapterName: selectedChapter.chapter_name,
          sourceLang: bookLanguage,
          targetLang: proofreadVersion,
          timestamp: new Date().toISOString(),
          status: 'pending',
          bookId: bookId,
          userId: auth.currentUser?.uid,
          word_count: selectedChapter.word_count,
          estimatedTimeMinutes: Math.ceil(selectedChapter.word_count / 500),
          lockGuid: 'Temp',
          error: '',
          type: 'EditingNotes'
        };
        
        setActiveTranslations(prev => [...prev, newEditingNotes]);
        
        const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/proofreadnotes-chapter/', {
          userId: userId,
          bookId: bookId,
          chapterGuid: selectedChapter.chapter_guid,
          sourceLang: bookLanguage,
          model: selectedAIModel,
          genre: genres.join(', '),
          tense: chapterTense,
          proofreadVersion: proofreadVersion,
        });
    
        if (response.data.status === "error") {
          message.error(response.data.message, 10);
          if (response.data.message.includes("rejected content")) {
            setRejectedChapters([selectedChapter.chapter_name]);
          }
          setIsLoading(false);
          return;
        }
      }    
    } else {    
      if (isProofreadChecked) {
        await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/analyze-missing-relationships/', {
          userId: userId,
          bookId: bookId,
          sourceLang: bookLanguage,
          targetLang: targetLanguage,
          api_model: 'Claude3.7'
        });
          
        // Find chapters that need proofreading
        const chaptersToProofread = book.chapters.filter(chapter => {
          if (proofreadVersion === "Original") {
            return !chapter.proofread_chapter;
          } else {
            const [sourceLang, targetLang] = proofreadVersion.split('-');
            const translatedChaptersKey = `translated_chapters_${sourceLang}_${targetLang}`;
            const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];
            const translatedChapter = translatedChapters.find(chap => chap.chapter_guid === chapter.chapter_guid);
            return !translatedChapter?.proofread_text;
          }
        });

        // Create temporary records for each proofread task
        const newProofreads: TranslationStatus[] = chaptersToProofread.map(chapter => ({
          chapterGuid: chapter.chapter_guid,
          chapterName: chapter.chapter_name,
          sourceLang: bookLanguage,
          targetLang: proofreadVersion,
          timestamp: new Date().toISOString(),
          status: 'pending',
          bookId: bookId,
          userId: auth.currentUser?.uid,
          word_count: chapter.word_count,
          estimatedTimeMinutes: Math.ceil(chapter.word_count / 500),
          lockGuid: 'Temp',
          error: '',
          type: 'Proofread'
        }));
        
        setActiveTranslations(prev => [...prev, ...newProofreads]);
        
        // Proofread all unproofread chapters
        const chapterPromises = chaptersToProofread.map(chapter => ({
          promise: axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/proofread-chapter/', {
            userId: userId,
            bookId: bookId,
            chapterGuid: chapter.chapter_guid,
            sourceLang: bookLanguage,
            model: selectedAIModel,
            genre: genres.join(', '),
            tense: chapterTense,
            proofreadVersion: proofreadVersion,
            quotationStyle: quotationStyle,
            additionalInstructions: sanitizeInput(proofreadAdditionalInstructions)
          })
          .then(response => {
            proofreadStatus[chapter.chapter_name] = response.data.status === "error" ? 'failed' : 'proofread';
          })
          .catch(error => {
            proofreadStatus[chapter.chapter_name] = 'failed';
          }),
          chapterName: chapter.chapter_name
        }));
    
        const promises = chapterPromises.map(chapter => chapter.promise.catch(() => {}));
        await Promise.allSettled(promises);
        
        // Process results
        Object.entries(proofreadStatus).forEach(([chapterName, status]) => {
          if (status === 'proofread') {
            successfulProofreadChapters.push(chapterName);
          } else if (status === 'failed') {
            failedProofreadChapters.push(chapterName);
          }
        });
    
        setFailedProofreads(failedProofreadChapters);
        setSuccessfulProofreads(successfulProofreadChapters);
      }
        
      if (isEditingNotesChecked) {
        // Find chapters that need editing notes
        const chaptersToAddNotes = book.chapters.filter(chapter => {
          if (proofreadVersion === "Original") {
            return !chapter.proofread_comments;
          } else {
            const [sourceLang, targetLang] = proofreadVersion.split('-');
            const translatedChaptersKey = `translated_chapters_${sourceLang}_${targetLang}`;
            const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];
            const translatedChapter = translatedChapters.find(chap => chap.chapter_guid === chapter.chapter_guid);
            return !translatedChapter?.proofread_comments;
          }
        });

        // Create temporary records for each editing notes task
        const newEditingNotes: TranslationStatus[] = chaptersToAddNotes.map(chapter => ({
          chapterGuid: chapter.chapter_guid,
          chapterName: chapter.chapter_name,
          sourceLang: bookLanguage,
          targetLang: proofreadVersion,
          timestamp: new Date().toISOString(),
          status: 'pending',
          bookId: bookId,
          userId: auth.currentUser?.uid,
          word_count: chapter.word_count,
          estimatedTimeMinutes: Math.ceil(chapter.word_count / 500),
          lockGuid: 'Temp',
          error: '',
          type: 'EditingNotes'
        }));
        
        setActiveTranslations(prev => [...prev, ...newEditingNotes]);
        
        // Generate editing notes for all chapters
        const chapterPromises = chaptersToAddNotes.map(chapter => ({
          promise: axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/proofreadnotes-chapter/', {
            userId: userId,
            bookId: bookId,
            chapterGuid: chapter.chapter_guid,
            sourceLang: bookLanguage,
            model: selectedAIModel,
            genre: genres.join(', '),
            tense: chapterTense,            
            proofreadVersion: proofreadVersion,
          })
          .then(response => {
            notesStatus[chapter.chapter_name] = response.data.status === "error" ? 'failed' : 'proofread';
          })
          .catch(error => {
            notesStatus[chapter.chapter_name] = 'failed';
          }),
          chapterName: chapter.chapter_name
        }));
    
        const promises = chapterPromises.map(chapter => chapter.promise.catch(() => {}));
        await Promise.allSettled(promises);
        
        // Process results
        Object.entries(notesStatus).forEach(([chapterName, status]) => {
          if (status === 'proofread') {
            successfulNotesChapters.push(chapterName);
          } else if (status === 'failed') {
            failedNotesChapters.push(chapterName);
          }
        });
    
        setFailedProofreadNotes(failedNotesChapters);
        setSuccessfulProofreadNotes(successfulNotesChapters);
      }    
    }
    
    // Refresh the book data to reflect the new proofreads
    fetchBookDetails();
    await fetchActiveTranslations();

    if (failedProofreadChapters.length > 0 || failedNotesChapters.length > 0) {
      message.error('Some chapters could not be proofread', 10);
    } else {
      message.success('Proofread completed successfully.', 10);
    }
    
    //if(!selectedChapter) {
    //  setIsFailedProofreadModalVisible(true);
    //}
    
  } catch (error) {
    console.error('General proofread error:', error);
    message.error('Error during proofread', 10);
    fetchActiveTranslations();
  } finally {
    setSelectedChapter(null);
    setIsLoading(false);
  }
};

const handleCoverUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file || !auth.currentUser) return;

    if (file.size > MAX_FILE_SIZE) {
        alert("File size should be less than 5MB.");
        return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axios.post(
            `https://askpoeai-nswdzfky7q-uc.a.run.app/update-book-cover/${bookId}?user_id=${auth.currentUser.uid}`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );

        setBook(prevBook => ({ ...prevBook, coverUrl: response.data.coverUrl }));
        //alert("Cover uploaded successfully");
    } catch (error) {
        console.error("Error uploading cover: ", error);
        alert("Error uploading cover");
    }

	if (event.target) {
	event.target.value = '';
	}
    setIsLoading(false);
};

const handleRemoveCover = async () => {
  if (!auth.currentUser) {
    console.error("No user logged in");
    return;
  }

  setIsLoading(true);

  try {
    const url = `https://askpoeai-nswdzfky7q-uc.a.run.app/remove-book-cover/${bookId}?user_id=${auth.currentUser.uid}`;
    await axios.post(url);
    setBook({ ...book, coverUrl: '' });
    //alert("Cover removed successfully");
  } catch (error) {
    console.error("Error removing cover: ", error);
    alert("Error removing cover");
  }

  setIsLoading(false);
};

const startEditingChapter = (chapter: Chapter) => {
  setEditingChapterGuid(chapter.chapter_guid);
  setEditedChapterName(chapter.chapter_name);
};

const saveChapterName = async (chapterGuid: string) => {
  if (!auth.currentUser) {
    console.error("No user logged in");
    return;
  }

  setIsLoading(true);

  try {
    const response = await axios.post(
      `https://askpoeai-nswdzfky7q-uc.a.run.app/update-chapter-name/${bookId}/${chapterGuid}`,
      {
        user_id: auth.currentUser.uid,
        new_name: editedChapterName
      }
    );

    if (response.data.status === "success") {
      // Update local state
      const updatedChapters = book.chapters.map(chapter => {
        if (chapter.chapter_guid === chapterGuid) {
          return { ...chapter, chapter_name: editedChapterName };
        }
        return chapter;
      });
      setBook({ ...book, chapters: updatedChapters });
      message.success("Chapter name updated successfully");
    } else {
      message.error("Error updating chapter name",10);
    }
  } catch (error) {
    console.error("Error updating chapter name:", error);
    message.error("Error updating chapter name",10);
  }

  // Reset editing state
  setEditingChapterGuid(null);
  setEditedChapterName('');
  setIsLoading(false);
};


const cancelEdit = () => {
  setEditingChapterGuid(null);
  setEditedChapterName('');
};

const toggleExportModal = () => {
  if (exportVersion !== 'Original') {
    const [sourceLang, targetLang] = exportVersion.split('-');
    const translatedChaptersKey = `translated_chapters_${sourceLang}_${targetLang}`;
    const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];

    const untranslated = book.chapters.filter(chapter =>
      !translatedChapters.some(tChapter => tChapter.chapter_guid === chapter.chapter_guid)
    ).map(chapter => chapter.chapter_name);

    setUntranslatedChapters(untranslated);
    setShowWarning(untranslated.length > 0);
  }
  if (exportVersion == 'Proofread' && proofreadOptionProps.disabled) {
  setExportVersion("Original")
  }
  setIsExportModalVisible(!isExportModalVisible);
};


const handleExport = async () => {
  if (!auth.currentUser) {
    console.error("No user logged in");
    return;
  }

  setIsLoading(true);
  //console.log(`Exporting book in ${exportVersion} version and ${exportFormat} format`);
  toggleExportModal();

  try {
    const response = await axios.post(
      `https://askpoeai-nswdzfky7q-uc.a.run.app/export-uploaded-book/${bookId}`, 
      {
        user_id: auth.currentUser.uid,
        version: exportVersion,
        format: exportFormat,
        title: book.title // Include the title of the book
      },
      { responseType: 'blob' } // Important: Set responseType to 'blob' for binary data
    );

    // Create a Blob from the PDF Stream
    const blob = new Blob([response.data], { type: response.headers['content-type'] });

    // Generate the filename based on the book's title and format
    const sanitizedTitle = book.title.replace(/\s+/g, '_').replace(/[^\w-]/g, ''); // Replace spaces with underscores and remove non-alphanumeric characters
    const fileExtension = exportFormat === 'Word' ? 'docx' : exportFormat.toLowerCase();
    const filename = `${sanitizedTitle}_${exportVersion}.${fileExtension}`;

    // Create a link and trigger a download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // Use the generated filename
    document.body.appendChild(link);
    link.click();

    message.success("Book exported successfully");
  } catch (error) {
    console.error("Error exporting book:", error);
    message.error("Error exporting book",10);
  } finally {
    setIsLoading(false);
  }
};

const deleteChapter = async (chapterGuid: string) => {
  if (!auth.currentUser) {
    console.error("No user logged in");
    return;
  }

  const userId = auth.currentUser.uid; // Assign the user ID to a variable

  const deleteWarning = exportFormat === 'ePub' ?
    (<>
      Deleted chapters will not be translated and will remain in the exported ePub in their original language.<br /><br />
      This action cannot be undone.
    </>) : 
    (<>
      Deleted chapters will not be translated.<br /><br />
      This action cannot be undone.
    </>);

  Modal.confirm({
    title: 'Are you sure you want to delete this chapter?',
    content: deleteWarning,
    onOk: async () => {
      setIsLoading(true);

      try {
		const response = await axios.delete(`https://askpoeai-nswdzfky7q-uc.a.run.app/delete-chapter/${bookId}/${chapterGuid}?user_id=${userId}`);
        // Update the local state to reflect the deletion
        const updatedChapters = book.chapters.filter(chapter => chapter.chapter_guid !== chapterGuid);
        setBook({ ...book, chapters: updatedChapters });
        message.success("Chapter deleted successfully.");
      } catch (error) {
        console.error("Error deleting chapter:", error);
        message.error("Error deleting chapter",10);
      } finally {
        setIsLoading(false);
      }
    },
  });
};


const handleBlurbSave = async () => {
  if (!auth.currentUser) {
    console.error("No user logged in");
    return;
  }

  setIsLoading(true);

  try {
    await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/update-book-blurb/', { 
		bookID: bookId,
		userID: auth.currentUser.uid,
		blurb: blurb
    });
    //setIsEditing(false);
	//refresh after update
    const updatedBookResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/book/${bookId}`);
    let updatedBook: Book = updatedBookResponse.data;
    updatedBook.chapters = updatedBook.chapters.sort((a: Chapter, b: Chapter) => a.order - b.order);
    setBook(updatedBook);	
  } catch (error) {
    console.error("Error saving book details:", error);
  }
  setIsLoading(false);
};


const exportBlurbToTxt = () => {
  // Determine which blurb to export based on the blurbActiveTabKey
  let currentBlurb = blurb;
  let fileSuffix = 'Original';
  if (blurbActiveTabKey !== 'original' && blurbActiveTabKey !== 'blurb') {
	const languagePair = blurbActiveTabKey;
    currentBlurb = blurbTranslations[languagePair] || 'No translation available';
    fileSuffix = languagePair.replace('_', ' to ');
  }

  // Sanitize the title for use in the filename
  const sanitizedTitle = book.title.replace(/\s+/g, '_').replace(/[^\w-]/g, '');
  // Create a blob and download the blurb as a text file
  const blob = new Blob([currentBlurb], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${sanitizedTitle}_blurb_${fileSuffix}.txt`;
  link.click();
};

const metadataHasTranslation = (type: 'title' | 'series' | 'blurb', sourceLang: string, targetLang: string) => {
  const key = `${type}_${sourceLang}_${targetLang}`;
  return book[key] !== undefined;
};

const handleTranslateBookClick = () => {
    proceedWithTranslateBookClick();
};

const proceedWithTranslateBookClick = () => {
  if (!hasUnproofreadChapters) {
    setTranslateVersion("Proofread");
    //console.log("test");
  } else {
    setTranslateVersion(prevVersion => {
      //console.log("Setting TranslateVersion to Original");
      return "Original";
    });
    //console.log("test2");
  }
  setSelectedAIModel("Claude3.7");
  setSelectedChapter(null); 
  toggleTranslateModal();
};

const handleProofreadBookClick = () => {
if(!isEditing){
	handleEditToggle();
}
setSelectedChapter(null); 
toggleProofreadModal();
}

function removeHyperlinks(htmlContent: string): string {
  const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
  doc.querySelectorAll('a').forEach(link => {
    link.removeAttribute('href');
    link.style.pointerEvents = 'none';
    link.style.color = 'inherit';
    link.style.textDecoration = 'none';
  });
  return doc.body.innerHTML;
}

const handleExportBookClick = () => {
if(!isEditing){
handleEditToggle();
}
toggleExportModal();
}

const calculateAdjustedCost = () => {
  if (isProofreadModalVisible) {
    const [sourceLang, targetLang] = proofreadVersion.split('-');
    const isHindi = targetLang === 'Hindi';
    const costMultiplier = isHindi ? 1 : 0.5;

    if (selectedChapter) {
      let chapterWordCount = 0;
      if (proofreadVersion === "Original") {
        chapterWordCount = selectedChapter.word_count;
      } else {
        const translatedChaptersKey = `translated_chapters_${sourceLang}_${targetLang}`;
        const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];
        const translatedChapter = translatedChapters.find(chap => chap.chapter_guid === selectedChapter.chapter_guid);
        if (translatedChapter) {
          chapterWordCount = translatedChapter.translated_word_count;
        }
      }
      if (chapterWordCount === 0) {
        return 0;
      } else {
        if (isProofreadChecked && isEditingNotesChecked) {
          return Math.ceil((chapterWordCount * costMultiplier) + (chapterWordCount * costMultiplier));
        } else if (isProofreadChecked || isEditingNotesChecked) {
          return Math.ceil(chapterWordCount * costMultiplier);
        } else {
          return 0;
        }
      }
    } else {
      let cost = 0;
      const translatedChaptersKey = `translated_chapters_${sourceLang}_${targetLang}`;
      const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];

      book.chapters.forEach(chapter => {
        const translatedChapter = translatedChapters.find(chap => chap.chapter_guid === chapter.chapter_guid);
        const chapterWordCount = proofreadVersion === "Original" 
          ? chapter.word_count 
          : translatedChapter?.translated_word_count || 0;

        let needsProofread = false;
        let needsEditingNotes = false;

        if (proofreadVersion === "Original") {
          needsProofread = isProofreadChecked && (!chapter.proofread_chapter);
          needsEditingNotes = isEditingNotesChecked && (!chapter.proofread_comments);
        } else {
          needsProofread = isProofreadChecked && (!translatedChapter?.proofread_text);
          needsEditingNotes = isEditingNotesChecked && (!translatedChapter?.proofread_comments);
        }

        if (needsProofread || needsEditingNotes) {
          const chapterCost = chapterWordCount * costMultiplier;
          if (needsProofread) cost += Math.ceil(chapterCost);
          if (needsEditingNotes) cost += Math.ceil(chapterCost);
        }
      });

      return Math.ceil(cost);
    }
  }
  return 0;
};

  useEffect(() => {
	  //console.log("useEffect 12");
	  //console.time('useEffect 12');
    // Check if the user is part of the beta program
    const userId = auth.currentUser?.uid; 
	const checkBetaStatus = async () => {
      if (userId) {
        try {
          const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/check-beta-status/${userId}`);
          setIsBetaUser(response.data.isBetaUser);
        } catch (error) {
          console.error('Error checking beta status:', error);
        }
      }
    };
	
    checkBetaStatus();
	//console.timeEnd('useEffect 12');
  }, [auth.currentUser?.uid]);	
 
const getCreditCost = () => {
    //console.log("getCreditCost");
    const wordCount = calculateWordCount(targetLanguage,selectedAIModel);
    let totalCost;
	totalCost=wordCount;
    //if (selectedAIModel === 'DeepSeek') {
    //    totalCost = Math.ceil(wordCount * 0.5);
    //} else {
    //    totalCost = wordCount;
    //}

    return Math.ceil(totalCost);
};

  const getProofreadOptionProps = () => {
    if (selectedChapter && !selectedChapter.proofread_chapter) {
      return { value: 'Proofread', disabled: true, text: 'Original (Proofread) (Unavailable - Chapter has not been Proofread)' };
    } 
	else if (!selectedChapter && !hasOriginalProofreadComplete) {
      return { value: 'Proofread', disabled: true, text: 'Original (Proofread) (Unavailable - Not all Chapters have been proofread)' };
    } 
	else {
      return { value: 'Proofread', disabled: false, text: 'Original (Proofread)' };
    }
  };

  const proofreadOptionProps = getProofreadOptionProps();


  const formatLangDisplayText = (pair: string) => {
    const [sourceLang, targetLang] = pair.split('-');
    return `${sourceLang} to ${targetLang}`;
  };


  const getProofreadOptions = () => {
    const proofreadOptions: string[] = [];
    if (book.translation_pairs) {
      book.translation_pairs.forEach(pair => {
        const [sourceLang, targetLang] = pair.split('-');
        const translatedChaptersKey = `translated_chapters_${sourceLang}_${targetLang}`;
        const translatedChapters: TranslatedChapter[] = book[translatedChaptersKey] || [];
        const hasProofread = translatedChapters.some(chapter => chapter.proofread_text);
        if (hasProofread) {
          proofreadOptions.push(`${sourceLang}-${targetLang} (Proofread)`);
        }
      });
    }
	return proofreadOptions;
  };

  const proofreadOptions = getProofreadOptions();

  if (!bookId) {
    return <div>No book ID provided</div>;
  }


  const handleAddLanguage = () => {
    setIsAddLanguageModalVisible(true);
  };

  const handleLanguageAdded = () => {
    // Refresh the book details to update the language list
    fetchBookDetails();
  };

return (
  <Spin spinning={isLoading}>
    <div style={{ padding: '10px' }}>
  <center>
  <div style={{ 
    alignItems: 'center', 
    marginBottom: '10px',
    fontSize: '20px'
  }}>
    <span style={{ fontWeight: 'bold' }}>{book.title}</span>
    <Tooltip title="Edit Metadata">
      <Button 
        type="text" 
        icon={<EditOutlined />} 
        onClick={handleEditToggle} 
        disabled={isEditing}
        size="small"
      />
    </Tooltip>
  </div>
</center>
      {/* Main Content Area */}
      <div style={{ display: 'flex' }}>

        {/* Left Panel */}
<div style={{ 
  flex: '0 0 auto',
  marginRight: '5px',
  minWidth: isSidebarCollapsed ? '50px' : '250px',
  maxWidth: isSidebarCollapsed ? '50px' : '250px',
  transition: 'all 0.3s ease',
  overflow: 'hidden',
  position: 'relative',
  // Add these styles for better visibility when collapsed
  backgroundColor: isSidebarCollapsed ? '#f7f7f7' : 'white',
  borderRight: isSidebarCollapsed ? '3px solid #e6f7ff' : 'none',
  boxShadow: isSidebarCollapsed ? '2px 0 5px rgba(0,0,0,0.05)' : 'none'
}}>
  <div className="droppableArea" style={{ width: isSidebarCollapsed ? '50px' : '250px' }}>
    <div style={{ 
      fontSize: '20px', 
      fontWeight: 'bold', 
      marginBottom: '10px', 
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      {!isSidebarCollapsed && (
        <center style={{ flex: 1 }}>
	
  <div style={{ 

    alignItems: 'center', 
    fontSize: '18px'
  }}>  
          Chapter List
          <Tooltip title="This list contains the chapters of your novel for organizational purposes. Chapter names do not affect the export or translation process.">
            <QuestionCircleOutlined style={{ color: '#1677ff', marginLeft: 8 }} />
          </Tooltip>
</div>
        </center>
      )}
<Tooltip title={isSidebarCollapsed ? "Expand Chapter List" : "Collapse Chapter List"}>
  <Button 
    type="text" 
    icon={isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} 
    onClick={toggleSidebar}
    style={{ 
      padding: '4px', 
      position: isSidebarCollapsed ? 'absolute' : 'static', 
      right: '8px',
      top: isSidebarCollapsed ? '10px' : 'auto',
      backgroundColor: isSidebarCollapsed ? '#e6f7ff' : 'transparent',
      border: isSidebarCollapsed ? '1px solid #1677ff' : 'none',
      transition: 'all 0.3s ease'
    }}
  />
</Tooltip>
    </div>
    
    {!isSidebarCollapsed && (
      <div style={{ height: '79vh', overflowY: 'auto' }}>
{book.chapters.map((chapter, index) => {
  const isTranslating = activeTranslations.some(
    t => t.chapterGuid === chapter.chapter_guid && t.status === 'pending'
  );
  
  const translationInfo = isTranslating ? 
    activeTranslations.find(t => t.chapterGuid === chapter.chapter_guid) : null;
  
  return (
    <div 
      key={chapter.chapter_guid}
      className={`chapterItem ${isSelectedChapter(chapter.chapter_guid) ? 'selectedChapter' : ''}`}
      style={{ 
        display: 'flex', 
        alignItems: 'center', 
        padding: '4px', 
        margin: '4px 0', 
        borderRadius: '3px', 
        cursor: 'pointer', 
        background: isSelectedChapter(chapter.chapter_guid) ? '#f0f0f0' : '',
        fontSize: '0.85em'
      }}
      onClick={() => selectChapter(chapter)}
    >
      <div style={{ flexGrow: 1, cursor: 'pointer' }}>
        {editingChapterGuid === chapter.chapter_guid ? (
          <Input
            value={editedChapterName}
            onChange={(e) => setEditedChapterName(e.target.value)}
            style={{ flexGrow: 1, marginRight: '4px', fontSize: '0.5em' }}
          />
        ) : (
          <span>
            {chapter.chapter_name}
            {isTranslating && (
              <Tooltip title={`Translation or proofreading in process.`}>
                <LoadingOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
              </Tooltip>
            )}
          </span>
        )}
      </div>
      {editingChapterGuid === chapter.chapter_guid ? (
        <>
          <CheckOutlined onClick={() => saveChapterName(chapter.chapter_guid)} />
          <CloseOutlined onClick={cancelEdit} style={{ marginLeft: '5px' }} />
        </>
      ) : (
        <>
          <EditOutlined onClick={() => startEditingChapter(chapter)} />
          <DeleteOutlined onClick={() => deleteChapter(chapter.chapter_guid)} style={{ color: 'red' }} />
        </>
      )}
    </div>
  );
})}
      </div>
    )}
	
{isSidebarCollapsed && (
  <div style={{ 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center',
    marginTop: '50px' 
  }}>
    <Tooltip title="Chapters" placement="right">
      <div style={{ 
        textAlign: 'center',
        color: '#1677ff'
      }}>
        <div style={{ 
          fontSize: '13px', 
          marginTop: '5px',
          writingMode: 'vertical-rl',
          textOrientation: 'mixed',
          transform: 'rotate(180deg)',
          letterSpacing: '1px'
        }}>
          CHAPTER LIST
        </div>
      </div>
    </Tooltip>
  </div>
)}	
	
  </div>
</div>
	  
      {/* Right Panel */}
<div style={{ 
  flex: '1 1 auto',
  backgroundColor: '#f0f2f5', 
  padding: '10px', 
  borderRadius: '10px',
  transition: 'all 0.3s ease',
  marginLeft: isSidebarCollapsed ? '10px' : '0',
  display: 'flex', 
  flexDirection: 'column',
  overflow: 'hidden'  
}}>


<TranslationStatusPanel 
  userId={auth.currentUser?.uid || ""} 
  bookId={bookId}
  translations={activeTranslations}  // Pass activeTranslations directly
  onRefresh={fetchActiveTranslations}
/>


  <Card 
    style={{ 
      backgroundColor: '#ffffff', 
      borderRadius: '10px', 
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)', 
      height: '81vh',
	  marginBottom: '0',
      display: 'flex',
      flexDirection: 'column',  // Add this      
	  width: '100%'
    }}
  >
    <div style={{ 
      flex: 1,  
      display: 'flex',
      flexDirection: 'column'  
    }}> 
	
	{isEditing ? (
      <div style={{ display: 'flex', width: '100%' }}>
        {/* Cover and Buttons */}
        <div style={{ marginRight: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {book.coverUrl ? (
            <img 
			src={book.coverUrl} 
			alt="Cover" 
			key={book.coverUrl} // Add this line
			style={{ width: '180px', height: '286px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }} 
			/>
          ) : (
            <div style={{ width: '180px', height: '286px', backgroundColor: '#e0e0e0', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FileImageOutlined style={{ fontSize: '48px', color: '#999' }} />
            </div>
          )}
<div style={{ 
  marginTop: '10px', 
  marginBottom: '10px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between'
}}>
  <Tooltip title="Translate Book">
    <Button 
      icon={<TranslationOutlined />} 
      onClick={handleTranslateBookClick}
      style={{ width: 'calc(25% - 6px)' }}
    />
  </Tooltip>
  
  <Tooltip title="Proofread Book">
    <Button 
      icon={<CheckOutlined />} 
      onClick={handleProofreadBookClick}
      style={{ width: 'calc(25% - 6px)' }}
    />
  </Tooltip>
  
  <Tooltip title="Export Book">
    <Button 
      icon={<DownloadOutlined />}
      onClick={handleExportBookClick}
      style={{ width: 'calc(25% - 6px)' }}
    />
  </Tooltip>
  
  {book.coverUrl ? (
    <Tooltip title="Remove Cover">
      <Button 
        icon={<DeleteOutlined />} 
        onClick={handleRemoveCover}
        style={{ width: 'calc(25% - 6px)' }}
      />
    </Tooltip>
  ) : (
<Tooltip title={
  <div>
    Upload Cover<br/>
    <span>NOTE: This cover is for organizational purposes only and does not replace the cover in the ePub file when exporting.</span>
  </div>
}>
      <Button 
        icon={<UploadOutlined />} 
        onClick={() => document.getElementById('cover-upload')?.click()}
        style={{ width: 'calc(25% - 6px)' }}
      />
    </Tooltip>
  )}
  
  <input
    id="cover-upload"
    type="file"
    onChange={handleCoverUpload}
    accept="image/*"
    style={{ display: 'none' }}
  />
</div>
		  <center>
		  <a href="#" onClick={(e) => {
		  	e.preventDefault();
		  	setHumanReviewModalVisible(true);
		  }}>
		  	<b>Need a human review of<br/>your manuscript?</b><br/>
		  <img src="/PSLogo.png" alt="Problem Solved Logo" style={{ width: '125px', marginTop: '10px' }} /></a>
		  </center>
        </div>

{/* Metadata Fields */}
<div style={{ flex: 1, overflowX: 'auto' }}>
  <Tabs
    defaultActiveKey="original"
    style={{ 
      flex: 1,
      marginBottom: '15px',
      width: '100%'
    }}
    tabBarStyle={{
      marginBottom: '1px',
      fontWeight: '500'
    }}
    type="card"     
    size="small"
    tabBarGutter={3}
    onChange={(key) => {
      setBlurbActiveTabKey(key);
      setCurrentLanguagePair(key);
      setIsTranslatedTabActive(key !== 'original');
    }}
    tabBarExtraContent={
      <Tooltip title="Add a new metadata language tab">
      <Button
        icon={<PlusOutlined />}
        onClick={handleAddLanguage}
        style={{ marginLeft: '10px' }}
      />
      </Tooltip>
    }
  >
    <TabPane tab="Original" key="original">
      <div style={{height: '70vh',  paddingRight: '15px', overflowX: 'auto', maxWidth: '100%'}}>
        {hasUnsavedChanges && (
          <Alert
            message="You have unsaved changes in your metadata."
            type="warning"
            showIcon
            style={{ marginBottom: '10px' }}
          />
        )}     
        <Form layout="vertical" style={{ marginTop: 0, marginBottom: 0 }}>
          {/* 3-column layout for main metadata */}
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '10px' }}>
            <Form.Item label={<strong>Title</strong>} required style={{ marginBottom: '0px' }} labelCol={{ style: { marginBottom: 0, lineHeight: '1', marginTop: '15px' } }}>
              <Input
                value={editBook.title}
                onChange={(e) => handleInputChange('title', e.target.value)}
                placeholder="Enter book title"
              />
            </Form.Item>
            <Form.Item label={<strong>Author</strong>} style={{ marginBottom: '0px' }} labelCol={{ style: { marginBottom: 0, lineHeight: '1', marginTop: '15px' } }}>
              <Input
                value={editBook.author}
                onChange={(e) => handleInputChange('author', e.target.value)}
                placeholder="Enter author name"
              />
            </Form.Item>
            <Form.Item label={<strong>Series</strong>} style={{ marginBottom: '0px' }} labelCol={{ style: { marginBottom: 0, lineHeight: '1', marginTop: '15px' } }}>
              <Input
                value={editBook.series}
                onChange={(e) => handleInputChange('series', e.target.value)}
                placeholder="Enter series name"
              />
            </Form.Item>
            <Form.Item label={<strong>Series Book Number</strong>} style={{ marginBottom: '0px' }} labelCol={{ style: { marginBottom: 0, lineHeight: '1', marginTop: '15px' } }}>
              <Input
                value={editBook.booknumber}
                onChange={(e) => handleInputChange('booknumber', e.target.value)}
                placeholder="Enter book number in series"
              />
            </Form.Item>
            <Form.Item label={<strong>Language</strong>} style={{ marginBottom: '0px' }} labelCol={{ style: { marginBottom: 0, lineHeight: '1', marginTop: '15px' } }}>
              <Select
                value={bookLanguage}
                suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
                onChange={(value) => {
                  setBookLanguage(value);
                  setHasUnsavedChanges(true);
                }}
              >
                {languages.map(lang => (
                  <Select.Option key={lang} value={lang}>{lang}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item 
              label={
                <Space>
                  <strong>Primary Genre</strong>
                  <Tooltip title="Select or type in the primary genre of your book. ScribeShadow uses this to help determine tone when translating.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Space>
              } style={{ marginBottom: '0px' }}  labelCol={{ style: { marginBottom: 0, lineHeight: '1', marginTop: '15px' } }}
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Type or select a primary genre"
                suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
                value={genres}
                onChange={(selectedGenres) => {
                  if (selectedGenres.length > 1) {
                    message.error('You can only select one primary genre');
                    setGenres(selectedGenres.slice(0, 1));
                  } else {
                    setGenres(selectedGenres);
                  }
                  setHasUnsavedChanges(true);
                }}
                dropdownRender={menu => (
                  <>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div style={{ 
                      display: 'grid', 
                      gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
                      gap: '10px',
                      padding: 8 
                    }}>
                      {commonGenres.map(genre => (
                        <Tag
                          key={genre}
                          style={{ cursor: 'pointer', textAlign: 'center' }}
                          onClick={() => {
                            if (genres.length === 0) {
                              setGenres([genre]);
                            } else {
                              message.error('You can only select one primary genre');
                            }
                          }}
                        >
                          {genre}
                        </Tag>
                      ))}
                    </div>
                  </>
                )}
              >
                {genres.map(genre => (
                  <Select.Option key={genre} value={genre}>{genre}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          {/* 2-column layout for tropes and keywords */}
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
            <Form.Item 
              label={
                <Space>
                  <strong>Tropes</strong>
                  <Tooltip title="Enter one or more tropes related to the book. ScribeShadow will use them when generating keyword suggestions.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Space>
              } style={{ marginBottom: '0px' }}  labelCol={{ style: { marginBottom: 0, lineHeight: '1', marginTop: '15px' } }}
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Type or select tropes"
                value={tropes}
                suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
                onChange={(value) => {
                  setTropes(value);
                  setHasUnsavedChanges(true);
                }}
              >
                {tropes.map(trope => (
                  <Select.Option key={trope} value={trope}>{trope}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item 
              label={
                <Space>
                  <strong>Keywords</strong>
                  <Tooltip title="Enter one or more keywords related to the book, such as those used with Amazon. ScribeShadow will use them when generating keyword suggestions.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Space>
              } style={{ marginBottom: '0px' }}  labelCol={{ style: { marginBottom: 0, lineHeight: '1', marginTop: '15px' } }}
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
                placeholder="Type or select keywords"
                value={keywords}
                onChange={(value) => {
                  setKeywords(value);
                  setHasUnsavedChanges(true);
                }}
              >
                {keywords.map(keyword => (
                  <Select.Option key={keyword} value={keyword}>{keyword}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <Form.Item
            label={
              <Space>
                <strong>Blurb</strong>
              </Space>
            } style={{ marginBottom: '0px' }}  labelCol={{ style: { marginBottom: 0, lineHeight: '1', marginTop: '15px' } }}
          >
            <ReactQuill
              value={blurb}
              onChange={(value) => {
                setBlurb(value);
                setHasUnsavedChanges(true);
              }}
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline'],
                  ['clean']
                ]
              }}
              placeholder="Enter book blurb"
            />
          </Form.Item>
        </Form>
        {!isTitleValid && <Alert message="Title is required." type="error" showIcon style={{ marginTop: '10px' }} />}
        <Space style={{ marginTop: '10px' }}>
          {!isTranslatedTabActive && (
            <Button 
              type="primary"
              onClick={handleSave}
              disabled={!isTitleValid}
              icon={<SaveOutlined />}
            >
              Save Metadata
            </Button>
          )}         
          {!isTranslatedTabActive && (
            <Tooltip title="ScribeShadow can generate three Blurb options for you to choose based on your manuscript. For best results, ensure the Tropes and Primary Genre sections are filled out.">
              <Button 
                onClick={handleGenerateBlurb}
                icon={<FormOutlined />}
              >
                Generate Blurb
              </Button>
            </Tooltip>
          )}       
          <Button onClick={toggleMetadataTranslateModal} icon={<TranslationOutlined />}>Translate Metadata</Button>   
          <Tooltip title="ScribeShadow can suggest keywords for foreign markets.">
            <Button onClick={toggleKeywordSuggestionModal} icon={<BulbOutlined />} >Suggest Keywords</Button>
          </Tooltip>
          <Button onClick={exportBlurbToTxt} disabled={isTranslatedTabActive && !blurbTranslations[blurbActiveTabKey]} icon={<ExportOutlined />}>Export Blurb to TXT</Button>
        </Space>
      </div>
    </TabPane>
    
    {!selectedChapter && book.translation_pairs && book.translation_pairs.map(pair => (
      <TabPane tab={pair.split('-')[1]} key={pair}>
        <div style={{height: '70vh', overflowY: 'auto', paddingRight: '15px', overflowX: 'auto'}}>
          {renderMetadataTranslationTabContent(pair)}
          <Space style={{ marginTop: '10px' }}>       
            <Button onClick={toggleMetadataTranslateModal} icon={<TranslationOutlined />}>Translate Metadata</Button>   
            <Tooltip title="ScribeShadow can suggest keywords for foreign markets.">
              <Button onClick={toggleKeywordSuggestionModal} icon={<BulbOutlined />} >Suggest Keywords</Button>
            </Tooltip>
            <Button onClick={exportBlurbToTxt} disabled={isTranslatedTabActive && !blurbTranslations[blurbActiveTabKey]} icon={<ExportOutlined />}>Export Blurb to TXT</Button>
          </Space>  
        </div>      
      </TabPane>
    ))}
  </Tabs>
</div>
      </div>
          ) : selectedChapter ? (
    <>
	{/* Individual chapters */}

<div style={{ 
  display: 'flex', 
  justifyContent: 'space-between', 
  alignItems: 'center', 
  marginBottom: '10px',
  marginTop: 0,
  backgroundColor: 'white',
  borderRadius: '6px',
}}>
<div style={{ display: 'flex', alignItems: 'center', marginBottom: 0, }}>
  <Select 
    value={selectedTab} 
    onChange={handleTabChange} 
    style={{ 
      width: '280px',
      fontWeight: '500'
    }}
    dropdownStyle={{ 
      padding: '8px 0px' 
    }}
    suffixIcon={<DownOutlined style={{ color: '#1677ff' }} />}
  >
    <Option value="Original">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FileTextOutlined style={{ marginRight: '8px', color: '#1677ff' }} />
        Original
      </div>
    </Option>
    {book.translation_pairs && book.translation_pairs.map(pair => (
      <Option key={pair} value={pair}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TranslationOutlined style={{ marginRight: '8px', color: '#52c41a' }} />
          {formatLangDisplayText(pair)}
        </div>
      </Option>
    ))}
  </Select>
      {rejectedChapters.includes(selectedChapter.chapter_name) && (
        <div style={{ marginLeft: '15px' }}>
          <Tooltip title="This chapter contains content that may be rejected during translation attempts. It may take several attempts or switching AI Models to successfully translate this content.">
            <span style={{ color: 'red' }}>
              <WarningOutlined /> Content Warning
            </span>
          </Tooltip>
        </div>
      )}  
	  </div>
  <div>

    <Tooltip title="Edit Metadata">
      <Button  
        icon={<EditOutlined />} 
        onClick={handleEditToggle} 
        disabled={isEditing}
		style={{ marginRight: '10px' }}
      />
    </Tooltip>
   
   <Tooltip title="Translate">
   <Dropdown
      overlay={
        <Menu>
          <Menu.Item key="translateChapter" onClick={toggleTranslateModal}>Translate Chapter</Menu.Item>
          <Menu.Item key="translateBook" onClick={handleTranslateBookClick}>Translate Book</Menu.Item>
        </Menu>
      }
      trigger={['click']}
    >
      <Button style={{ marginRight: '10px' }} icon={<TranslationOutlined />}>
        
      </Button>
    </Dropdown>
    </Tooltip>
	
	
	<Tooltip title="Proofread">
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item key="proofreadChapter" onClick={toggleProofreadModal}>Proofread Chapter</Menu.Item>
          <Menu.Item key="proofreadBook" onClick={handleProofreadBookClick}>Proofread Book</Menu.Item>
        </Menu>
      }
      trigger={['click']}
    >
      <Button style={{ marginRight: '10px' }} icon={<CheckOutlined />}>
        
      </Button>
    </Dropdown>
    </Tooltip>
    
	<Tooltip title="Export">
    <Button 
      onClick={handleExportBookClick}
      icon={<DownloadOutlined />}
    >
      
    </Button>
	    </Tooltip>
  </div>
</div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
		<Tabs 
		defaultActiveKey="1" 
		style={{ 
			flex: 1,
			marginBottom: '15px'
		}}
		tabBarStyle={{
			marginBottom: '15px',
			fontWeight: '500'
		}}
		type="card" // Makes tabs more visually distinct as buttons
		>
          {selectedTab === 'Original' ? (
            <>
      <TabPane 
        tab={
          <span style={{ padding: '5px 10px' }}>
            <FileTextOutlined style={{ marginRight: '6px' }} />
            Original
          </span>
        } 
        key="1"
      >
          <div style={{ flex: 1, overflowY: 'auto', height: 'calc(65vh - 10px)', paddingRight: '15px'}} dangerouslySetInnerHTML={{ __html: removeHyperlinks(selectedChapter.content) }} />
        </TabPane>
        {(() => {
          const { content, isProofread } = renderProofreadTabContent("Original", "Original");
          return (
            <TabPane 
              tab={
                <>
                  {"Original - Proofread"}
                  {!isProofread && (
                    <Tooltip title="This chapter has not been proofread yet.">
                      <ExclamationCircleOutlined style={{ color: '#CC5500', marginLeft: 8 }} />
                    </Tooltip>
                  )}
                </>
              } 
              key="2"
            >
              {content}
            </TabPane>
          );
        })()}
      </>
    ) : (
            <>
              {(() => {
                const [sourceLang, targetLang] = selectedTab.split('-');
                const { content, isTranslated } = renderTranslatedTabContent(sourceLang, targetLang);
                return (
                  <TabPane 
                    tab={
                      <>
                        {`${formatLangDisplayText(selectedTab)}`}
                        {!isTranslated && (
                          <Tooltip title={`This chapter is not yet translated from ${sourceLang} to ${targetLang}.`}>
                            <ExclamationCircleOutlined style={{ color: '#CC5500', marginLeft: 8 }} />
                          </Tooltip>
                        )}
                      </>
                    } 
                    key="1"
                  >
                    {content}
                  </TabPane>
                );
              })()}
              {(() => {
                const [sourceLang, targetLang] = selectedTab.split('-');
                const { content, isProofread } = renderProofreadTabContent(sourceLang, targetLang);
                return (
                  <TabPane 
                    tab={
                      <>
                        {`${formatLangDisplayText(selectedTab)} - Proofread`}
                        {!isProofread && (
                          <Tooltip title={`This chapter is not yet proofread in ${targetLang}.`}>
                            <ExclamationCircleOutlined style={{ color: '#CC5500', marginLeft: 8 }} />
                          </Tooltip>
                        )}
                      </>
                    } 
                    key="2"
                  >
                    {content}
                  </TabPane>
                );
              })()}
            </>
          )}
        </Tabs>
      </div>
    </>
          ) : (
            <div>No chapter selected</div>
          )}
		  </div>
        </Card>

      </div>
	  
	  
    </div>
	</div>
{isGenerateBlurbModalVisible && (
  <GenerateBlurbModal 
    isVisible={isGenerateBlurbModalVisible}
    onClose={() => setIsGenerateBlurbModalVisible(false)}
    bookId={bookId}
    auth={auth}
    genres={genres}
    selectedAIModel={selectedAIModel}
	setSelectedAIModel={setSelectedAIModel}
    fetchCreditBalance={fetchCreditBalance}
    creditBalance={creditBalance}
    setBlurb={setBlurb}
	isLoading={isLoading}
    setIsLoading={setIsLoading}	
	book={book}
  />
)}
<Modal
  title={
    <>
      Export Book
      {exportFormat === 'ePub' && (
        <Tooltip title="Any chapters from the original ePub that were not translated by Scribeshadow will remain in the ePub in their original form.">
          <QuestionCircleOutlined style={{ marginLeft: '8px', fontSize: '16px' }} />
        </Tooltip>
      )}
    </>
  }
  visible={isExportModalVisible}
  onCancel={toggleExportModal}
  footer={[
    <Button key="cancel" onClick={toggleExportModal}>Cancel</Button>,
    <Button key="export" type="primary" onClick={handleExport}>
      Export
    </Button>,
  ]}
>
  <div style={{ marginBottom: '10px' }}>
    <label style={{ marginRight: '10px' }}>Version:</label>
    <Select
      value={exportVersion}
      suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
      onChange={value => {
        setExportVersion(value);
        setShowWarning((value === 'Proofread' && hasUnproofreadChapters) || 
                       (value !== "Original" && hasUntranslatedChapters));
      }}
      style={{ width: '100%' }}
    >
      <Select.Option value="Original">Original</Select.Option>
        <Select.Option value={proofreadOptionProps.value} disabled={proofreadOptionProps.disabled}>
          {proofreadOptionProps.text}
        </Select.Option>
      {book.translation_pairs && book.translation_pairs.map(pair => (
        <Select.Option key={pair} value={pair}>{formatLangDisplayText(pair)}</Select.Option>
      ))}
            {proofreadOptions.map(option => (
              <Select.Option key={option} value={option}>{formatLangDisplayText(option)}</Select.Option>
            ))}	  
    </Select>
  </div>
  <div>
    <label style={{ marginRight: '10px' }}>Format: 	<Tooltip title="Scribeshadow can export books uploaded as Word documents only as Word documents. Books uploaded as ePubs can be exported as ePubs or Word documents. Please note that the ePub to Word conversion may lose some formatting.">
      <QuestionCircleOutlined style={{ cursor: 'help' }} />
    </Tooltip></label><br/>
    
    <Select
      value={exportFormat}
      suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
      onChange={setExportFormat}
      style={{ width: '100%' }}
    >
      {uploadType === 'Word' && <Select.Option value="Word">Word</Select.Option>}
      {uploadType === 'ePub' && <Select.Option value="ePub">ePub</Select.Option>}
      {uploadType === 'ePub' && <Select.Option value="Word">Word</Select.Option>}
    </Select>
  </div>
  
  {/* German language warning */}
{exportVersion !== "Original" && formatLangDisplayText(exportVersion).includes("German") && (
  <div style={{ marginTop: '15px', padding: '10px', backgroundColor: '#fffbe6', border: '1px solid #ffe58f', borderRadius: '4px' }}>
    <p style={{ margin: 0 }}>
      <InfoCircleOutlined style={{ marginRight: '8px', color: '#faad14' }} />
      <strong>Important Note About Amazon's German Spellcheck:</strong><br/>Amazon's spellchecker often flags a large number of "errors," in German text. These are false positives and not actual spelling mistakes. This behavior stems from limitations in the spellchecker's handling of the German language.
    </p>
  </div>
)}  
  {showWarning && exportVersion.includes('Proofread') && unproofreadChapters.length > 0 && (
    <div>
      <p style={{ color: 'red' }}>Warning: The following chapters are not proofread:</p>
      <ul>
        {unproofreadChapters.map((chapterName, index) => (
          <li key={index}>{chapterName}</li>
        ))}
      </ul>
    </div>
  )}
  {showWarning && exportVersion !== "Original" && !exportVersion.includes('Proofread') && (
    <div>
      <p style={{ color: 'red' }}>Warning: Some chapters are not translated for the selected language pair:</p>
      <ul>
        {untranslatedChapters.map((chapterName, index) => (
          <li key={index}>{chapterName}</li>
        ))}
      </ul>
    </div>
  )}
</Modal>

{/* Failed Proofread Modal */}
<Modal
  title={"Proofreading Results"}
  visible={isFailedProofreadModalVisible}
  onCancel={() => setIsFailedProofreadModalVisible(false)}
  footer={[
    <Button key="close" onClick={() => setIsFailedProofreadModalVisible(false)}>
      Close
    </Button>
  ]}
  width={600} 
>

  {/* Chapters successfully proofread */}
  {!selectedChapter && successfulProofreads.length > 0 && (
    <div>
      <h3>Chapters successfully proofread:</h3>
      <ul>
        {successfulProofreads.map((chapter, index) => (
          <li key={index}>{chapter}</li>
        ))}
      </ul>
    </div>
  )}

  {/* Chapters failed to proofread */}
  {failedProofreads.length > 0 && (
    <div>
      <h3>
        {isTranslationTimedout ? "Proofreading Request Timed Out" : (selectedChapter ? "Chapter that failed to proofread:" : "Chapters Failed proofreading")}
      </h3>
      <p>
        Possible reasons for failure:
        <ul>
          <li><Tooltip title="You are not charged for AI connection errors.">AI API connection issue <QuestionCircleOutlined /></Tooltip></li>
          <li><Tooltip title="You receive a 50% refund for chapters with rejected content.">Content rejection <QuestionCircleOutlined /></Tooltip></li>
        </ul>
        To retry your proofreading:
        <ul>
          <li>Use the "Proofread Chapter" button to proofread individual chapters</li>
          <li>Use the "Proofread Book" button to proofread all unproofread chapters</li>
        </ul>
        <i>Note: "Proofread Book" only processes unproofread chapters, avoiding duplicate charges.</i>
      </p>	  
      <p>
        <b>{isTranslationTimedout ? "Some chapters may be incomplete." : "Unproofread chapters:"}</b>
      </p>		
      <ul>
        {failedProofreads.map((chapter, index) => (
          <li key={index}>{chapter}</li>
        ))}
      </ul>
    </div>
  )}

  {/* Notes successfully proofread */}
  {successfulProofreadNotes.length > 0 && (
    <div>
      <h3>Chapter that successfully generated Editing Notes:</h3>
      <ul>
        {successfulProofreadNotes.map((note, index) => (
          <li key={index}>{note}</li>
        ))}
      </ul>
    </div>
  )}

  {/* Notes failed to proofread */}
  {failedProofreadNotes.length > 0 && (
    <div>
      <h3>
        {isTranslationTimedout ? "Editing Notes Request Timed Out" : (selectedChapter ? "Chapter that failed to generate Editing Notes:" : "Chapters that failed to to generate Editing Notes:")}
      </h3>
      <p>
        {isTranslationTimedout ? "Some chapters may not have finished proofreading." : "The following chapters could not be proofread:"}
      </p>
      <ul>
        {failedProofreadNotes.map((note, index) => (
          <li key={index}>{note}</li>
        ))}
      </ul>
    </div>
  )}
</Modal>

<TranslationModal
  isVisible={isTranslateModalVisible}
  onCancel={toggleTranslateModal}
  onTranslate={() => {
    if (!selectedChapter) {
      setIsConfirmationModalVisible(true);
    } else {
      handleTranslation();
    }
  }}
  selectedChapter={selectedChapter}
  translateVersion={translateVersion}
  setTranslateVersion={setTranslateVersion}
  bookLanguage={bookLanguage}
  targetLanguage={targetLanguage}
  setTargetLanguage={setTargetLanguage}
  handleLanguageChange={handleLanguageChange}
  handleAIModelChange={handleAIModelChange}
  languages={languages}
  betaLanguages={betaLanguages}
  betaLanguageWarning={betaLanguageWarning}
  setBetaLanguageWarning={setBetaLanguageWarning}
  quotationStyle={quotationStyle}
  setQuotationStyle={setQuotationStyle}
  selectedAIModel={selectedAIModel}
  setSelectedAIModel={setSelectedAIModel}
  isBetaUser={isBetaUser}
  creditBalance={creditBalance}
  getCreditCost={getCreditCost}
  isChapterTranslated={isChapterTranslated}
  isPreviousChapterTranslated={isPreviousChapterTranslated}  
  hasUntranslatedChapters={hasUntranslatedChapters}
  userHasEnoughCredits={userHasEnoughCredits}
  hasUnproofreadChapters={!hasOriginalProofreadComplete}
  proofreadOptionProps={proofreadOptionProps}
/>

<Modal
title={
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
<span>Confirm Translation Settings</span>
</div>
}
  visible={isConfirmationModalVisible}
  onCancel={() => setIsConfirmationModalVisible(false)}
  footer={[
    <Button key="back" onClick={() => setIsConfirmationModalVisible(false)}>
      Back
    </Button>,
    <Button key="submit" type="primary" onClick={handleTranslation}>
      Confirm and Translate
    </Button>,
  ]}
>
  <p>Please confirm your translation settings:</p>
  <ul>
    <li><strong>Version to Translate:</strong> {translateVersion === 'Proofread' ? 'Original (Proofread)' : translateVersion}</li>
    <li><strong>Target Language:</strong> {targetLanguage}</li>
    {targetLanguage === 'German' && (
      <li>
        <strong>Quotation Style:</strong> {
          quotationStyle === 'german quotation marks' 
            ? 'German Quotation Marks („Dialog Text")'
            : quotationStyle === 'german guillemets'
              ? 'German Guillemets (»Dialog Text«)'
              : quotationStyle
        }
      </li>
    )}	
    <li><strong>AI Model:</strong> {selectedAIModel === 'Claude' ? 'Claude 3.5' : selectedAIModel}</li>   
  </ul>
  <p>Are you sure you want to proceed with these settings?</p>
  <div>
    <center>      <Tag color="blue" style={{ fontSize: '18px', padding: '10px 15px' }}>
        Translating to: {targetLanguage}
      </Tag></center>
  </div>  
</Modal>
<Modal
  title="Translate Metadata"
  visible={isMetadataTranslateModalVisible}
  onCancel={toggleMetadataTranslateModal}
  footer={[
    <Button key="cancel" onClick={toggleMetadataTranslateModal}>Cancel</Button>,
    <Button 
      key="translate" 
      type="primary" 
      onClick={handleMetadataTranslation} 
      disabled={!isAnyMetadataSelectedForTranslation() || !userHasEnoughCreditsMetadata || !targetLanguage}
    >
      Translate
    </Button>,
  ]}
  width={700}
>
  <div>
    <p>Select which metadata fields (title, series, blurb) you wish to translate.</p>
    
    <div style={{ marginBottom: '15px' }}>
      <div style={{ marginBottom: '10px' }}>
        <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
          <strong>Origin Language:</strong>
        </label>
        <div>{bookLanguage}</div>
      </div>
      
      <div style={{ marginBottom: '15px' }}>
        <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
          <strong>Target Language:</strong>
        </label>
        <Select
          value={targetLanguage}
          onChange={(value) => {
            setTargetLanguage(value);
            if (value === 'German') {
              setQuotationStyle('german guillemets');
            }
          }}
          style={{ width: '100%' }}
          placeholder="Select a target language"
          suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
        >
          {languages.filter(lang => lang !== bookLanguage).map(lang => (
            <Select.Option key={lang} value={lang}>{lang}</Select.Option>
          ))}
        </Select>
      </div>

      {targetLanguage === 'German' && (
        <div style={{ marginBottom: '15px' }}>
          <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
            <strong>Quotation Style:</strong>
          </label>
          <Select
            value={quotationStyle}
            onChange={value => setQuotationStyle(value)}
            style={{ width: '100%' }}
            suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
          >
            <Select.Option value="german quotation marks">German Quotation Marks („Dialog Text")</Select.Option>
            <Select.Option value="german guillemets">Guillemets (»Dialog Text«)</Select.Option>
          </Select>
        </div>
      )}

      <div style={{ marginBottom: '15px' }}>
        <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
          <strong>AI Model:</strong>
        </label>
        <Select
          value={selectedAIModel}
          onChange={(value) => setSelectedAIModel(value)}
          style={{ width: '100%' }}
          suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
        >
          <Select.Option value="Claude3.7">
            Claude 3.7 <Tag color="geekblue" style={{ marginLeft: '8px', position: 'relative', top: '-0.5px' }}>Recommended </Tag>
          </Select.Option>
          <Select.Option value="Claude">Claude 3.5</Select.Option>
          <Select.Option value="Chat-GPT">Chat-GPT</Select.Option>
        </Select>
      </div>
    </div>
    
    <div style={{ 
      background: '#f7f7f7', 
      padding: '15px', 
      borderRadius: '6px', 
      marginBottom: '15px' 
    }}>
      <div style={{ marginBottom: '10px' }}>
        <strong>Select Fields to Translate:</strong>
      </div>
      
      <div style={{ marginBottom: '10px' }}>
        <Checkbox
          checked={translateMetadata.title}
          onChange={(e) => setTranslateMetadata({ ...translateMetadata, title: e.target.checked })}
          disabled={!canTranslateTitle}
        >
          <span style={{ fontWeight: '500' }}>Title</span>
          {translateMetadata.title && metadataHasTranslation('title', bookLanguage, targetLanguage) && (
            <Tooltip title="A translation already exists for this language and will be replaced.">
              <ExclamationCircleOutlined style={{ color: '#CC5500', marginLeft: 8 }} />
            </Tooltip>
          )}
          {!canTranslateTitle && (
            <Tooltip title="Add and save a title to enable this option.">
              <ExclamationCircleOutlined style={{ color: '#CC5500', marginLeft: 8 }} />
            </Tooltip>
          )}     
        </Checkbox>
      </div>
      
      <div style={{ marginBottom: '10px' }}>
        <Checkbox
          checked={translateMetadata.series}
          onChange={(e) => setTranslateMetadata({ ...translateMetadata, series: e.target.checked })}
          disabled={!canTranslateSeries}
        >
          <span style={{ fontWeight: '500' }}>Series Name</span>
          {translateMetadata.series && metadataHasTranslation('series', bookLanguage, targetLanguage) && (
            <Tooltip title="A translation already exists for this language and will be replaced.">
              <ExclamationCircleOutlined style={{ color: '#CC5500', marginLeft: 8 }} />
            </Tooltip>
          )}
          {!canTranslateSeries && (
            <Tooltip title="Add and save a series name to enable this option.">
              <ExclamationCircleOutlined style={{ color: '#CC5500', marginLeft: 8 }} />
            </Tooltip>
          )}    
        </Checkbox>
      </div>
      
      <div>
        <Checkbox
          checked={translateMetadata.blurb}
          onChange={(e) => setTranslateMetadata({ ...translateMetadata, blurb: e.target.checked })}
          disabled={!canTranslateBlurb}
        >
          <span style={{ fontWeight: '500' }}>Blurb</span>
          {translateMetadata.blurb && metadataHasTranslation('blurb', bookLanguage, targetLanguage) && (
            <Tooltip title="A translation already exists for this language and will be replaced.">
              <ExclamationCircleOutlined style={{ color: '#CC5500', marginLeft: 8 }} />
            </Tooltip>
          )}
          {!canTranslateBlurb && (
            <Tooltip title="Add and save a blurb to enable this option.">
              <ExclamationCircleOutlined style={{ color: '#CC5500', marginLeft: 8 }} />
            </Tooltip>
          )}  
        </Checkbox>
      </div>
    </div>
    
    <div style={{ 
      background: '#f0f7ff', 
      padding: '15px', 
      borderRadius: '6px', 
      marginBottom: '15px' 
    }}>
      <div style={{ marginBottom: '8px' }}>
        <strong>Credit Balance: </strong>
        <span>{creditBalance ? creditBalance.credit_balance : 'Loading...'}</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <strong>Translation Credit Cost: </strong>
        <span style={{ marginLeft: '5px', marginRight: '5px' }}>{calculateMetadataTranslationCreditCost()}</span>
        <Tooltip title="Title and Series translations each return three options to choose from and have a credit cost of 50 each.">
          <QuestionCircleOutlined style={{ color: '#1677ff' }} />
        </Tooltip>
      </div>
    </div>
    
    {!userHasEnoughCreditsMetadata && (
      <Alert
        message="You do not have enough credits for this translation."
        type="error"
        showIcon
        icon={<ExclamationCircleOutlined />}
        style={{ marginBottom: '8px' }}
      />
    )}
  </div>
</Modal>

<ProofreadModal
  isVisible={isProofreadModalVisible}
  onCancel={toggleProofreadModal}
  onProofread={() => {
    if (!selectedChapter) {
      setIsProofreadConfirmationModalVisible(true);
    } else {
      handleProofread();
    }
  }}
  selectedChapter={selectedChapter}
  proofreadVersion={proofreadVersion}
  setProofreadVersion={setProofreadVersion}
  isProofreadChecked={isProofreadChecked}
  setIsProofreadChecked={setIsProofreadChecked}
  isEditingNotesChecked={isEditingNotesChecked}
  setIsEditingNotesChecked={setIsEditingNotesChecked}
  quotationStyle={quotationStyle}
  setQuotationStyle={setQuotationStyle}
  proofreadAdditionalInstructions={proofreadAdditionalInstructions}
  setProofreadAdditionalInstructions={setProofreadAdditionalInstructions}  
  selectedAIModel={selectedAIModel}
  setSelectedAIModel={setSelectedAIModel}
  creditBalance={creditBalance}
  calculateAdjustedCost={calculateAdjustedCost}
  userHasEnoughCredits={userHasEnoughCredits}
  hasUnproofreadChapters={hasUnproofreadChapters}
  hasUnproofreadNotesChapters={hasUnproofreadNotesChapters}
  canChapterBeProofread={canChapterBeProofread}
  hasChapterBeenProofread={hasChapterBeenProofread}
  hasChapterBeenEditNotes={hasChapterBeenEditNotes}
  hasUntranslatedChaptersForProofread={hasUntranslatedChaptersForProofread}
  book={book}
  formatLangDisplayText={formatLangDisplayText}
  bookLanguage={bookLanguage}
/>

      <Modal
        title="Confirm Proofreading Settings"
        visible={isProofreadConfirmationModalVisible}
        onCancel={() => setIsProofreadConfirmationModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setIsProofreadConfirmationModalVisible(false)}>
            Back
          </Button>,
          <Button key="submit" type="primary" onClick={handleProofread}>
            Confirm and Proofread
          </Button>,
        ]}
      >
        <p>Please confirm your proofreading settings:</p>
        <ul>
          <li><strong>Version to Proofread:</strong> {proofreadVersion.replace('-', ' to ')}</li>
          <li><strong>Proofread Entire Book:</strong> {isProofreadChecked ? 'Yes' : 'No'}</li>
          <li><strong>Get Editing Notes:</strong> {isEditingNotesChecked ? 'Yes' : 'No'}</li>
          {proofreadVersion.includes('-German') && (
            <li>
              <strong>Quotation Style:</strong> {
                quotationStyle === 'german quotation marks' 
                  ? 'German Quotation Marks („Dialog Text")'
                  : 'German Guillemets (»Dialog Text«)'
              }
            </li>
          )}
          <li><strong>AI Model:</strong> {selectedAIModel === 'Claude' ? 'Claude 3.5' : selectedAIModel}</li>
        </ul>
        <p>Are you sure you want to proceed with these settings?</p>
      </Modal>

{isTranslationOptionsModalVisible && (
  <TranslationOptionsModal
    isVisible={isTranslationOptionsModalVisible}
    onCancel={() => setIsTranslationOptionsModalVisible(false)}
    translationOptions={translationOptions}
    translateMetadata={translateMetadata}
    bookId={bookId}
    bookLanguage={bookLanguage}
    targetLanguage={targetMetadataLanguage}
    fetchBookDetails={fetchBookDetails}
  />
)}
	
{isKeywordSuggestionModalVisible && (
  <KeywordSuggestionModal
    isVisible={isKeywordSuggestionModalVisible}
    toggleModal={toggleKeywordSuggestionModal}
    bookId={bookId}
    bookLanguage={bookLanguage}
    genres={genres.join(', ') || ''}
    tropes={book.tropes || []}
    keywords={book.keywords || []}	
	blurb={book.blurb || ''}
    selectedAIModel={selectedAIModel}
    fetchCreditBalance={fetchCreditBalance}
    creditBalance={creditBalance}
    auth={auth}
    languages={languages}
    betaLanguages={betaLanguages}
	fetchBookDetails={fetchBookDetails}
	isLoading={isLoading}
    setIsLoading={setIsLoading}
	targetLanguage={targetLanguage}
  />
)}
	
    <Modal
      title="Delete Translated Chapters"
      visible={showDeleteTranslatedChaptersModal}
	  onCancel={handleDeleteTranslatedChaptersCancel}
      footer={[
        <Button key="back" onClick={handleDeleteTranslatedChaptersCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleDeleteTranslatedChaptersOK}>
          OK
        </Button>,
      ]}
    >
      <p>Are you sure you want to delete all <strong>{deleteChaptersTargetLang}</strong> translated chapters? This action cannot be undone.</p>
    </Modal>	
	
    <Modal
      title="Delete Translated Chapter"
      visible={showDeleteSingleTranslatedChapterModal}
	  onCancel={handleDeleteSingleTranslatedChapterCancel}
      footer={[
        <Button key="back" onClick={handleDeleteSingleTranslatedChapterCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleDeleteSingleTranslatedChapterOK}>
          OK
        </Button>,
      ]}
    >
      <p>Are you sure you want to delete the <strong>{deleteSingleChapterTargetLang}</strong> translation for this chapter? This action cannot be undone.</p>
    </Modal>		

    <Modal
      title="Delete Proofread Chapters"
      visible={showDeleteProofreadChaptersModal}
	  onCancel={handleDeleteProofreadChaptersCancel}
      footer={[
        <Button key="back" onClick={handleDeleteProofreadChaptersCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleDeleteProofreadChaptersOK}>
          OK
        </Button>,
      ]}
    >
      <p>Are you sure you want to delete all <strong>{deleteChaptersTargetLang}</strong> proofread chapters? This action cannot be undone.</p>
    </Modal>	
	
    <Modal
      title="Delete Proofread Chapter"
      visible={showDeleteSingleProofreadChapterModal}
	  onCancel={handleDeleteSingleProofreadChapterCancel}
      footer={[
        <Button key="back" onClick={handleDeleteSingleProofreadChapterCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleDeleteSingleProofreadChapterOK}>
          OK
        </Button>,
      ]}
    >
      <p>Are you sure you want to delete this chapter's proofreading? This action cannot be undone.</p>
    </Modal>	

<SummarizeOfferModal
  isVisible={showSummarizeOffer}
  onClose={handleSummarizeDecline}
  onAccept={handleSummarizeAccept}
  creditBalance={creditBalance}
  selectedAIModel={selectedAIModel}
  setSelectedAIModel={setSelectedAIModel}
  book={book}
/>

<Modal
  title={
    <div>
      <center>Human Translation Review Services</center>
    </div>
  }
  visible={humanReviewModalVisible}
  onCancel={() => setHumanReviewModalVisible(false)}
  footer={null}
  width={800}
>
      <div>
      <center><a href="http://translation.problemsolved.es/" target="_blank" rel="noopener noreferrer"><img src="/PSLogo.png" alt="Problem Solved Logo" style={{ width: '300px', marginRight: '10px' }} /></a></center>
    </div>
  <p>
    Having a human proofread or review your AI Translation can provide:
  </p>
  <ul>
    <li>Peace of mind that your translation is accurate</li>
    <li>An added layer of humanization to the text</li>
    <li>Access to publishing platforms that do not allow AI-only translations</li>
  </ul>
  <p>
    For those seeking a human review, we've partnered with <a href="http://translation.problemsolved.es/" target="_blank" rel="noopener noreferrer">Problem Solved translation services</a>.
  </p>
  <p>
    Problem Solved offers:
  </p>
  <ul>
    <li>An exclusive rate for ScribeShadow translated manuscripts</li>
    <li>A certificate of authenticity</li>
  </ul>
  <p>
    <center><a href="mailto:max@problemsolved.es">Contact Max from Problem Solved directly for more information and to get started with your human review.</a><br/><a href="mailto:max@problemsolved.es">Max@ProblemSolved.es</a></center>
  </p>
</Modal>

<AddLanguageModal
  isVisible={isAddLanguageModalVisible}
  onClose={() => setIsAddLanguageModalVisible(false)}
  onLanguageAdded={handleLanguageAdded}
  bookId={bookId}
  existingLanguages={book.translation_pairs?.map((pair) => pair.split('-')[1]) || []}
  allLanguages={languages}
  sourceLanguage={bookLanguage}
  betaLanguages={betaLanguages} // Add this line
/>

<NeverTranslateModal
  isVisible={isNeverTranslateModalVisible}
  onClose={() => setIsNeverTranslateModalVisible(false)}
  currentLanguagePair={currentLanguagePair}
  neverTranslateWords={neverTranslateWords}
  setNeverTranslateWords={setNeverTranslateWords}
  bookGuid={bookId} 
/>

<AlwaysTranslateModal
  isVisible={isAlwaysTranslateModalVisible}
  onClose={() => setIsAlwaysTranslateModalVisible(false)}
  currentLanguagePair={currentLanguagePair}
  alwaysTranslateWords={alwaysTranslateWords}
  setAlwaysTranslateWords={setAlwaysTranslateWords}
  bookGuid={bookId}
/>

  </Spin>
);

};

export default BookDetailPage;
