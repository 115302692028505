// CharactersTab.tsx
import React, { ChangeEvent, useState, useEffect } from 'react';
import { Input, Select, Tooltip, Button, Space, Modal, Spin, Checkbox, List, Row, Col, Divider, Card, Popconfirm, message, Dropdown, Menu, Radio  } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons'
import { MoreOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { User } from "firebase/auth";
import { getFirestore, doc, updateDoc, Timestamp, getDoc, collection, addDoc, getDocs, deleteDoc, setDoc  } from "firebase/firestore"; 
import './GeneratingGifStyle.css';
import styled from 'styled-components';

import { Typography, Tag } from 'antd';

import { MenuInfo } from 'rc-menu/lib/interface';

//utility imports
import { SharedState, UsersSharedState, TabProps, CustomAxiosError, MainCharacter, SupportingCharacter, Character } from './types';
import { formatCharacters, formatLocations } from './formatFunctions'; 


const { TextArea } = Input;
const { Option } = Select;

const StyledList = styled(List)`
  width: 95%;
  
  .ant-list-item {
    cursor: pointer;
  }
  
  .ant-list-item:hover {
    background-color: #f0f0f0;
  }
  
  .ant-list-item-selected {
    background-color: #e6f7ff;
  }
`;

const CharactersTab: React.FC<TabProps> = ({ sharedState, setSharedState, isAuth, user, usersSharedState, setUsersSharedState }) => {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [infoTitle, setInfoTitle] = useState("");
  const [infoContent, setInfoContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isConfirmMainCharactersModalVisible, setIsConfirmMainCharactersModalVisible] = useState(false);
  const [isConfirmSupportingCharactersModalVisible, setIsConfirmSupportingCharactersModalVisible] = useState(false);
  const [shouldSkipMainCharactersConfirm, setShouldSkipMainCharactersConfirm] = useState(false);
  const [shouldSkipSupportingCharactersConfirm, setShouldSkipSupportingCharactersConfirm] = useState(false);
  const [editIndex, setEditIndex] = useState(null);


  const initialMainCharacters: MainCharacter[] = Array.isArray(sharedState.mainCharacters) 
  ? sharedState.mainCharacters 
  : [];
  const [mainCharacters, setMainCharacters] = useState<MainCharacter[]>(initialMainCharacters);

  const [selectedMainCharacter, setSelectedMainCharacter] = useState<number | null>(null);
  const [currentName, setCurrentName] = useState('');
  const [currentDescription, setCurrentDescription] = useState('');
  const [currentBackground, setCurrentBackground] = useState('');

  const initialSupportingCharacters: SupportingCharacter[] = Array.isArray(sharedState.supportingCharacters) 
  ? sharedState.supportingCharacters 
  : [];
  const [supportingCharacters, setSupportingCharacters] = useState<SupportingCharacter[]>(initialSupportingCharacters);
  
  const [selectedSupportingCharacter, setSelectedSupportingCharacter] = useState<number | null>(null);
  const [currentSupportingName, setCurrentSupportingName] = useState('');
  const [currentSupportingDescription, setCurrentSupportingDescription] = useState('');
  const [currentSupportingBackground, setCurrentSupportingBackground] = useState('');

  const [characterRoles, setCharacterRoles] = useState<string[]>([]);
  
  const [costState, setCostState] = useState<Record<string, any>>({}); 
  const [apiModel, setApiModel] = useState("Claude")
  const [bookGenre, setBookGenre] = useState("")
  const [bookPOV, setBookPOV] = useState("First Person")
  const isSinglePoVSelected = mainCharacters.some(char => char.povFlag === 'Yes');
	const [showModal, setShowModal] = useState(false);
	const [additionalDetails, setAdditionalDetails] = useState('');

  const db = getFirestore();

useEffect(() => {
  if (!isAuth) {
    // User has logged out, so clear the state
    setMainCharacters([]);
    setSupportingCharacters([]);
	setCurrentSupportingName('');
	setCurrentSupportingDescription('');
	setCurrentSupportingBackground('');
	setCurrentName('');
	setCurrentDescription('');
	setCurrentBackground('');
	setSelectedMainCharacter(null);
	setSelectedSupportingCharacter(null);
	setApiModel("Claude");
	setBookGenre("");
	setBookPOV("");
  }
}, [isAuth]);

useEffect(() => {
    setMainCharacters([]);
    setSupportingCharacters([]);
	setCurrentSupportingName('');
	setCurrentSupportingDescription('');
	setCurrentSupportingBackground('');
	setCurrentName('');
	setCurrentDescription('');
	setCurrentBackground('');
	setSelectedMainCharacter(null);
	setSelectedSupportingCharacter(null);
	setApiModel("Claude");
	setBookGenre("");	
	setBookPOV("");
}, [sharedState.bookguid]);

  useEffect(() => {
    const fetchGenerationCosts = async () => {
      setIsLoading(true);
      try {
        const generationCostsResponse = await axios.get('http://192.168.1.187:8000/get_generation_cost/');
        const costsData: Record<string, any> = generationCostsResponse.data.creditCosts;
        const generationTypes = [
          'BasicPlot', 'Tropes', 'WritingStyle', 'MainCharacters', 
          'SupportingCharacters', 'Locations', 'Outline', 
          'Title', 'Blurb', 'Novel', 'ChapterSummary','CharacterName','CharacterDetails',
          'LocationName','LocationDetails'
        ];

        let newState: Record<string, any> = {}; 
        for (let genType of generationTypes) {
          const key = `${genType}_${apiModel}`;
          newState[`gen${genType}Cost`] = costsData[key];
        }

        // Updating the new costState with the fetched costs
        setCostState(newState);
        
		console.log("Cost State genTropesCost: "+costState.genTropesCost);
		
      } catch (error) {
        console.error("Failed to fetch generation costs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (apiModel) {  // Ensuring model is available before fetching
      fetchGenerationCosts();
    }
  }, [sharedState.bookguid, apiModel]);


  ///Load Book data
  useEffect(() => {
    const fetchData = async () => {
      if (!sharedState.bookguid) return;  // Exit if no bookguid is available

      setIsLoading(true);
      try {
        const response = await axios.get(`http://192.168.1.187:8000/api/books/${sharedState.bookguid}`);
        const data = response.data;
        
          setApiModel(data.model);
		  setMainCharacters(data.mainCharacters);
		  setSupportingCharacters(data.supportingCharacters);
		  setBookGenre(data.genre);
		  setBookPOV(data.pov);
		  
      } catch (error: any) {
        console.error("Failed to fetch book details: ", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [sharedState.bookguid]); 



const handleBlurSave = async (
  character: MainCharacter | SupportingCharacter,
  characterType: 'mainCharacters' | 'supportingCharacters'
) => {
  if (sharedState.bookguid && isAuth && user) {
    try {
      if (character.id) {
		  
		//console.log("character:", character);
		
        // Create or Update character's individual document
        const characterRef = doc(db, 'books', sharedState.bookguid, characterType, character.id);
        const updatedData: any = {
          name: character.name,
          description: character.description,
          background: character.background,
        };
		
		// Check if role exists before adding to updatedData
        if (character.role !== undefined) {
          updatedData.role = character.role;
        }

        if (character.povFlag !== undefined) {
          updatedData.povFlag = character.povFlag;
        }

        // Use setDoc() with merge option to either create or update the document
        await setDoc(characterRef, updatedData, { merge: true });
      } else {
        // Update sharedState
        const bookRef = doc(db, 'books', sharedState.bookguid);
        await updateDoc(bookRef, { lastModifiedDate: Timestamp.now() });
      }
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  }
};

const updateCharacter = (updatedCharacter: MainCharacter) => {
  if (selectedMainCharacter !== null) {
    const updatedMainCharacters = [...mainCharacters];
    updatedMainCharacters[selectedMainCharacter] = updatedCharacter;
    setMainCharacters(updatedMainCharacters);

    // Update character's document with the new data
    handleBlurSave(updatedCharacter, 'mainCharacters');
  }
};

  const handleInfoModal = (visible: boolean, source: string) => {
    if (source === 'MainCharacters')
	{
	setInfoTitle("Main Characters Information");
    setInfoContent(`ScribeShadow can generate a list of main characters for your story, including their physical descriptions and backgrounds. 
	
	ScribeShadow will use the Genre, Tropes, and Plot provided so far when creating your characters. You can also write your own characters.
	
	Any character names or locations included in the Basic Plot could be used when ScribeShadow generates characters, so make sure you are happy with them before continuing.
	
	ScribeShadow will use the Main Characters when generating Supporting Characters, Locations, and your Outline.`);
    }	
	else if (source === 'SupportingCharacters')
	{
	setInfoTitle("Supporting Characters Information");
    setInfoContent(`ScribeShadow can generate a list of supporting characters for your story, including their physical descriptions and backgrounds. 
	
	ScribeShadow will use the Genre, Tropes, Plot, and Main Characters provided so far when creating your supporting characters. You can also write your own supporting characters.
	
	Any character names or locations included in the Basic Plot and Main Characters could be used when ScribeShadow generates supporting characters, so make sure you are happy with them before continuing.
	
	ScribeShadow will use the Supporting Characters when generating Locations and your Outline.`);
    }	
    setIsInfoModalVisible(visible);
  };

  let infoModalContent = (
    <p>
      {infoContent.split('\n').map((line, i) => (
        <span key={i}>
          {line}
          <br />
        </span>
      ))}
    </p>
  );

    
  const handleErrorModal = (visible: boolean) => {
    setErrorModalVisible(visible);
  };

// Confirmation handlers
const handleConfirmMainCharactersModal = async (confirm: boolean) => {
  setIsConfirmMainCharactersModalVisible(false);
  if (confirm) {
    handleGenerateMainCharacters();
  }
};

const handleConfirmSupportingCharactersModal = async (confirm: boolean) => {
  setIsConfirmSupportingCharactersModalVisible(false);
  if (confirm) {
    handleGenerateSupportingCharacters();
  }
};


const handleGenerateMainCharactersClick = async () => {
//  if (sharedState.mainCharacters.trim() !== '' && !shouldSkipMainCharactersConfirm) {
//    setIsConfirmMainCharactersModalVisible(true);
//  } else {
    handleGenerateMainCharacters();
//  }
};

const handleGenerateSupportingCharactersClick = async () => {
//  if (sharedState.supportingCharacters.trim() !== '' && !shouldSkipSupportingCharactersConfirm) {
//    setIsConfirmSupportingCharactersModalVisible(true);
//  } else {
    handleGenerateSupportingCharacters();
//  }
};

const handleSupportingCharacterSelect = (index: number) => {
    setSelectedSupportingCharacter(index);
    setCurrentSupportingName(supportingCharacters[index].name || '');
    setCurrentSupportingDescription(supportingCharacters[index].description || '');
    setCurrentSupportingBackground(supportingCharacters[index].background || '');
};

const updateSupportingCharacter = (updatedCharacter: SupportingCharacter) => {
  if (selectedSupportingCharacter !== null) {
    const updatedSupportingCharacters = [...supportingCharacters];
    updatedSupportingCharacters[selectedSupportingCharacter] = updatedCharacter;
    setSupportingCharacters(updatedSupportingCharacters);
    handleBlurSave(updatedCharacter, 'supportingCharacters');
  }
}

const handleAddSupportingCharacter = async () => {
    const newCharacter: SupportingCharacter = {
        name: 'My New Character',
        description: '',
        background: '',
        id: '' 
    };

    if (sharedState.bookguid && isAuth && user) {
        try {
            // Add new character to Firestore
            const charactersCollection = collection(db, 'books', sharedState.bookguid, 'supportingCharacters');
            const characterDocRef = await addDoc(charactersCollection, newCharacter);

            // Update newCharacter object with the newly generated ID
            newCharacter.id = characterDocRef.id;

            // Update supportingCharacters state
            const updatedSupportingCharacters = [...supportingCharacters, newCharacter as SupportingCharacter];
            setSupportingCharacters(updatedSupportingCharacters);

            // Update other states related to the new character
            setSelectedSupportingCharacter(updatedSupportingCharacters.length - 1);  // Select the new character
            setCurrentSupportingName(newCharacter.name || '');
            setCurrentSupportingDescription(newCharacter.description || '');
            setCurrentSupportingBackground(newCharacter.background || '');

        } catch (error) {
            console.error("Error adding new supporting character: ", error);
        }
    }
};

const handleGenerateMainCharacters = () => {
  setShowModal(true);
};

  const handleGenerateWithDetails  = async () => {
  console.log("in handleGenerateWithDetails")
  setIsLoading(true);
  setShowModal(false);
  try {
    
    const response = await axios.post('http://192.168.1.187:8000/generate_maincharacters/', { 
      bookguid: sharedState.bookguid,
      userID: usersSharedState.userID,
	  instructions: additionalDetails
    });

    // Parse the response to get the count of characters created and the credits after deduction
    const { characterCount, creditsRemaining } = response.data;

    // Update user credits
    setUsersSharedState((prevState: UsersSharedState) => ({
      ...prevState,
      credits: creditsRemaining,
    }));

	if (sharedState.bookguid && isAuth && user) {
		try {
			// Fetching mainCharacters from Firestore
			const mainCharacterCollection = collection(db, 'books', sharedState.bookguid, 'mainCharacters');
			const mainCharacterSnapshot = await getDocs(mainCharacterCollection);
			const mainCharactersFromFirestore = mainCharacterSnapshot.docs.map(doc => ({
				id: doc.id,
				...doc.data()
			}));
	
			setMainCharacters(mainCharactersFromFirestore);
		} catch (error) {
			console.error("Error fetching characters from Firestore: ", error);
		}
	}

  } catch (err) {
    const axiosErr = err as AxiosError;
    if (axiosErr.response && axiosErr.response.data && typeof (axiosErr.response.data as any).detail === 'string') {
      setErrorMessage((axiosErr.response.data as any).detail);
    } else {
      setErrorMessage('An error occurred while generating the main characters.');
    }
    handleErrorModal(true);
  } finally {
    setIsLoading(false);
  }
};

const handleGenerateSupportingCharacters = async () => {
  setIsLoading(true);
  console.log("bookguid:", sharedState.bookguid);
  console.log("userID:", usersSharedState.userID);
  try {

    const response = await axios.post('http://192.168.1.187:8000/generate_supportingcharacters/', { 
      bookguid: sharedState.bookguid,
      userID: usersSharedState.userID, 
	  instructions: ""
    });

    setUsersSharedState((prevState: UsersSharedState) => ({
      ...prevState,
      credits: response.data.creditsRemaining,
    }));

    if (sharedState.bookguid && isAuth && user) {
      try {
        // Fetching supportingCharacters from Firestore
        const supportingCharacterCollection = collection(db, 'books', sharedState.bookguid, 'supportingCharacters');
        const supportingCharacterSnapshot = await getDocs(supportingCharacterCollection);
        const supportingCharactersFromFirestore = supportingCharacterSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

			setSupportingCharacters(supportingCharactersFromFirestore);
		} catch (error) {
			console.error("Error fetching characters from Firestore: ", error);
		}
	}

  } catch (err) {
    const axiosErr = err as AxiosError;
    if (axiosErr.response && axiosErr.response.data && typeof (axiosErr.response.data as any).detail === 'string') {
      setErrorMessage((axiosErr.response.data as any).detail);
    } else {
      setErrorMessage('An error occurred while generating the supporting characters.');
    }
    handleErrorModal(true);
  } finally {
    setIsLoading(false);
  }
};

  
const handleMainCharacterSelect = (index: number) => {
  setSelectedMainCharacter(index);
  setCurrentName(mainCharacters[index].name || '');
  setCurrentDescription(mainCharacters[index].description || '');
  setCurrentBackground(mainCharacters[index].background || '');
};


  const handleEditChange = (index: number, field: keyof MainCharacter, value: string) => {
    let newCharacters = [...mainCharacters];
    newCharacters[index][field] = value;
    setMainCharacters(newCharacters);
  };
  
const handleDeleteCharacter = (charType: string, index: number) => {
  Modal.confirm({
    title: 'Are you sure you want to delete this character?',
    content: 'This action cannot be undone.',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk: async () => {
      if (charType === 'main') {
        const characterToDelete = mainCharacters[index];

        // 1. Delete from Firestore
        const characterDoc = doc(db, 'books', sharedState.bookguid, 'mainCharacters', characterToDelete.id);
        await deleteDoc(characterDoc);

        // 2. Update sharedState.mainCharacters and other local states
		const updatedCharacters = mainCharacters.filter((_, idx) => idx !== index);

        setMainCharacters(updatedCharacters);

        if (updatedCharacters.length > 0) {
          const lastCharacterIndex = updatedCharacters.length - 1;
          setSelectedMainCharacter(lastCharacterIndex);
          setCurrentName(updatedCharacters[lastCharacterIndex].name || '');
          setCurrentDescription(updatedCharacters[lastCharacterIndex].description || '');
          setCurrentBackground(updatedCharacters[lastCharacterIndex].background || '');
        } else {
          setSelectedMainCharacter(null);
          setCurrentName('');
          setCurrentDescription('');
          setCurrentBackground('');
        }
      } else {
        const characterToDelete = supportingCharacters[index];

        // 1. Delete from Firestore
        const characterDoc = doc(db, 'books', sharedState.bookguid, 'supportingCharacters', characterToDelete.id);
        await deleteDoc(characterDoc);

        // 2. Update sharedState.mainCharacters and other local states
		const updatedCharacters = supportingCharacters.filter((_, idx) => idx !== index);

        setSupportingCharacters(updatedCharacters);

        if (updatedCharacters.length > 0) {
          const lastCharacterIndex = updatedCharacters.length - 1;
          setSelectedSupportingCharacter(lastCharacterIndex);
          setCurrentSupportingName(updatedCharacters[lastCharacterIndex].name || '');
          setCurrentSupportingDescription(updatedCharacters[lastCharacterIndex].description || '');
          setCurrentSupportingBackground(updatedCharacters[lastCharacterIndex].background || '');
        } else {
          setSelectedSupportingCharacter(null);
          setCurrentSupportingName('');
          setCurrentSupportingDescription('');
          setCurrentSupportingBackground('');
        }
      }
    },
  });
};

const handleAddCharacter = async () => {
  const newCharacter = {
    name: 'My New Character',
    description: '',
    background: '',
	id: ''
  };

  if (sharedState.bookguid && isAuth && user) {
    try {
      // Add new main character to Firestore
      const charactersCollection = collection(db, 'books', sharedState.bookguid, 'mainCharacters');
      const characterDocRef = await addDoc(charactersCollection, newCharacter);

      // Update newCharacter object with the newly generated ID
      newCharacter.id = characterDocRef.id;

      // Update mainCharacters state
      const updatedMainCharacters = [...mainCharacters, newCharacter];
      setMainCharacters(updatedMainCharacters);

      // Update other states related to the new character
      setSelectedMainCharacter(updatedMainCharacters.length - 1);  // Select the new character
      setCurrentName(newCharacter.name);
      setCurrentDescription(newCharacter.description);
      setCurrentBackground(newCharacter.background);

    } catch (error) {
      console.error("Error adding new main character: ", error);
    }
  }
}


const { confirm } = Modal;

// Function to display confirmation dialog
const showConfirm = (title: string, content: string, onOk: () => void) => {
  confirm({
    title: title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    onOk: onOk
  });
}

const handleRegenerateCharacter = (charType: string, index: number, operationType: string) => async () => {
  setIsLoading(true);

  const character = charType === 'main' ? mainCharacters[index] : supportingCharacters[index];
  const characterID = character.id; // Assuming the character object has an id property

  try {
    const response = await axios.post(`http://192.168.1.187:8000/regenerate_character/`, {
      charType, 
      operationType,
      userID: usersSharedState.userID,
      bookguid: sharedState.bookguid,
      characterID
    });

    if (response.data.message !== 'Success') {
      throw new Error('API did not return a success message');
    }

    if (sharedState.bookguid && isAuth && user) {
      if (charType === 'main') {
        // Fetching mainCharacters from Firestore
        const mainCharacterCollection = collection(db, 'books', sharedState.bookguid, 'mainCharacters');
        const mainCharacterSnapshot = await getDocs(mainCharacterCollection);
        const mainCharactersFromFirestore: MainCharacter[] = mainCharacterSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setMainCharacters(mainCharactersFromFirestore);
        setCurrentName(mainCharactersFromFirestore[index].name || '');
        setCurrentDescription(mainCharactersFromFirestore[index].description || '');
        setCurrentBackground(mainCharactersFromFirestore[index].background || '');
  
      } else {
        // Fetching supportingCharacters from Firestore
        const supportingCharacterCollection = collection(db, 'books', sharedState.bookguid, 'supportingCharacters');
        const supportingCharacterSnapshot = await getDocs(supportingCharacterCollection);
        const supportingCharactersFromFirestore: SupportingCharacter[] = supportingCharacterSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setSupportingCharacters(supportingCharactersFromFirestore);
        setCurrentSupportingName(supportingCharactersFromFirestore[index].name || '');
        setCurrentSupportingDescription(supportingCharactersFromFirestore[index].description || '');
        setCurrentSupportingBackground(supportingCharactersFromFirestore[index].background || '');
      }
    }

  } catch (error) {
    console.log('There was an error:', error);
    setErrorMessage('An error occurred while regenerating the character.');
    setErrorModalVisible(true);
  } finally {
    setIsLoading(false);
  }
};

const [visibleMainDropdownIndex, setVisibleMainDropdownIndex] = useState(-1);
const [visibleSupportDropdownIndex, setVisibleSupportDropdownIndex] = useState(-1);

const resetCharacterDetails = () => {
    setSelectedMainCharacter(null);
    setSelectedSupportingCharacter(null);
    setCurrentName('');
    setCurrentDescription('');
    setCurrentBackground('');
    setCurrentSupportingName('');
    setCurrentSupportingDescription('');
    setCurrentSupportingBackground('');
};

const handleSwitchToSupportingCharacter = async (index: number) => {
  setVisibleMainDropdownIndex(-1);
  setVisibleSupportDropdownIndex(-1);
  setSelectedMainCharacter(null);
  setSelectedSupportingCharacter(null);
  setCurrentSupportingName('');
  setCurrentSupportingDescription('');
  setCurrentSupportingBackground('');
  setCurrentName('');
  setCurrentDescription('');
  setCurrentBackground('');  
  
  // Extract the character from mainCharacters
  const characterToSwitch = mainCharacters[index];
  
  characterToSwitch.role = '';
  
  // 1. Delete character from mainCharacters in Firestore
  const characterDoc = doc(db, 'books', sharedState.bookguid, 'mainCharacters', characterToSwitch.id);
  await deleteDoc(characterDoc);
  
  // 2. Remove character from mainCharacters in local state
  //const updatedMainCharacters = [...mainCharacters];
  //updatedMainCharacters.splice(index, 1);
  //setMainCharacters(updatedMainCharacters);
  
  // 3. Add character to supportingCharacters in local state
  //const updatedSupportingCharacters = [...supportingCharacters, characterToSwitch];
  //setSupportingCharacters(updatedSupportingCharacters);
  
  setMainCharacters((prevMainCharacters) => prevMainCharacters.filter((_, i) => i !== index));
  setSupportingCharacters((prevSupportingCharacters) => [...prevSupportingCharacters, characterToSwitch]); 
  
  // 4. Save the switched character in supportingCharacters in Firestore
  handleBlurSave(characterToSwitch, 'supportingCharacters');
  
  // Reset current character details
  resetCharacterDetails();  
  
};

const handleSwitchToMainCharacter = async (index: number) => {
  setVisibleMainDropdownIndex(-1);
  setVisibleSupportDropdownIndex(-1);
  setSelectedMainCharacter(null);
  setSelectedSupportingCharacter(null);
  setCurrentSupportingName('');
  setCurrentSupportingDescription('');
  setCurrentSupportingBackground('');
  setCurrentName('');
  setCurrentDescription('');
  setCurrentBackground('');
  
  // Extract the character from supportingCharacters
  const characterToSwitch = supportingCharacters[index];
  
  // 1. Delete character from supportingCharacters in Firestore
  const characterDoc = doc(db, 'books', sharedState.bookguid, 'supportingCharacters', characterToSwitch.id);
  await deleteDoc(characterDoc);
  
  // 2. Remove character from supportingCharacters in local state
  //const updatedSupportingCharacters = [...supportingCharacters];
  //updatedSupportingCharacters.splice(index, 1);
  //setSupportingCharacters(updatedSupportingCharacters);
  
  // 3. Add character to mainCharacters in local state
  //const updatedMainCharacters = [...mainCharacters, characterToSwitch];
  //setMainCharacters(updatedMainCharacters);
  
  setSupportingCharacters((prevSupportingCharacters) => prevSupportingCharacters.filter((_, i) => i !== index));
  setMainCharacters((prevMainCharacters) => [...prevMainCharacters, characterToSwitch]);  
  
  // 4. Save the switched character in mainCharacters in Firestore
  handleBlurSave(characterToSwitch, 'mainCharacters');
  
  // Reset current character details
  resetCharacterDetails();  
  
};

const handlePoVChange = (index: number, checked: boolean) => {
  if (selectedMainCharacter !== null) {
    const newCharacters = mainCharacters.map((char, idx) => ({
      ...char,
      povFlag: (bookPOV === "First Person (Single PoV)" && checked) ? (idx === index ? 'Yes' : 'No') : (bookPOV === "First Person (Multiple PoV)" ? (idx === index ? (checked ? 'Yes' : 'No') : char.povFlag) : 'No')
    }));
    setMainCharacters(newCharacters);
    handleBlurSave(newCharacters[index], 'mainCharacters');
  }
};

return (
  <div>
  
    {!sharedState.bookguid && isAuth && (
      <Tooltip title="Please start or open a book in the Book Management tab.">
        <p>
          <b>
            Please start or open a book in the Book Management tab.
            <br />
            Fields will become available once a book is in progress.
          </b>
        </p>
      </Tooltip>
    )}
    {!isAuth && (
      <Tooltip title="Please click Get Started.">
        <p>
          <b>Click Get Started to sign up and start generating books!</b>
        </p>
      </Tooltip>
    )}
    <Row gutter={16}>
      <Col span={6}>
         <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={{marginRight: '5px', fontSize: '16px'}}><b>Main Characters</b></p>
            <Tooltip title="Click for more information">
              <InfoCircleOutlined
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={() => handleInfoModal(true, 'MainCharacters')}
              />
            </Tooltip>
          </div>
          <StyledList
            dataSource={mainCharacters}
            renderItem={(item: any, index: number) => (
<List.Item
  key={index}
  style={{ 
    cursor: 'pointer',
    backgroundColor: index === selectedMainCharacter ? '#e6f7ff' : undefined  
  }} 
  onClick={() => handleMainCharacterSelect(index)}
  actions={[
<Dropdown 
  overlay={
<Menu>
  <Menu.Item key="1" onClick={() => {
    handleRegenerateCharacter('main', index, 'name')();
    setVisibleMainDropdownIndex(-1);
  }}>
    Regenerate Character Name 
    <Tag color="blue" style={{ fontWeight: 'bold', marginLeft: '5px' }}>
      {costState.genCharacterNameCost} Credit
    </Tag>
  </Menu.Item>
  <Menu.Item key="2" onClick={() => {
    handleRegenerateCharacter('main', index, 'description')();
    setVisibleMainDropdownIndex(-1);
  }}>
    Regenerate Character Description 
    <Tag color="blue" style={{ fontWeight: 'bold', marginLeft: '5px' }}>
      {costState.genCharacterDetailsCost} Credit
    </Tag>
  </Menu.Item>
  <Menu.Item key="3" onClick={() => {
    handleRegenerateCharacter('main', index, 'background')();
    setVisibleMainDropdownIndex(-1);
  }}>
    Regenerate Character Background 
    <Tag color="blue" style={{ fontWeight: 'bold', marginLeft: '5px' }}>
      {costState.genCharacterDetailsCost} Credit
    </Tag>
  </Menu.Item>
  <Menu.Item key="4" onClick={() => {
    handleDeleteCharacter('main',index);
    setVisibleMainDropdownIndex(-1);
  }}>
    Delete Character
  </Menu.Item>
  <Menu.Item key="5" onClick={() => handleSwitchToSupportingCharacter(index)}>
    Switch to Supporting Character
  </Menu.Item>
</Menu>

  }
      trigger={['click']}
      visible={visibleMainDropdownIndex  === index}
      onVisibleChange={(visible) => {
        if (!visible) {
          setVisibleMainDropdownIndex(-1);
        }
      }}
    >
      <MoreOutlined
        style={{ cursor: 'pointer', fontSize: '16px', color: '#000' }}
        onClick={(event) => {
          event.stopPropagation(); // Prevent triggering the onClick event of the List.Item
          if (selectedMainCharacter !== index) {
            handleMainCharacterSelect(index);
          }
          setVisibleMainDropdownIndex(visibleMainDropdownIndex === index ? -1 : index);
        }}
      />
    </Dropdown>
  ]}
>
  {item.name}
</List.Item>
            )}
          />
<Tooltip title={mainCharacters.length >= usersSharedState.maxCharacters ? `You have reached the maximum number of main characters. ${usersSharedState.maxCharacters < 5 ? "Upgrade your account to increase the limit." : ""}` : ""}>
<Button 
  style={{ 
    marginTop: '10px', 
  }} 
  onClick={handleAddCharacter}
  disabled={mainCharacters.length >= usersSharedState.maxCharacters || !isAuth || !sharedState.bookguid}
>
  Manually Add Character
</Button>
</Tooltip>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
<Tooltip title={mainCharacters.length >= usersSharedState.maxCharacters ? `You have reached the maximum number of main characters. ${usersSharedState.maxCharacters < 5 ? "Upgrade your account to increase the limit." : ""}` : ""}>
  <Button onClick={handleGenerateMainCharactersClick} disabled={!isAuth || !sharedState.bookguid || mainCharacters.length >= usersSharedState.maxCharacters}>Generate Characters</Button>
</Tooltip>

<Tag color="blue" style={{ fontWeight: 'bold', marginTop: '5px' }}>
  {costState.genMainCharactersCost} {costState.genMainCharactersCost > 1 ? 'Credits' : 'Credit'}
</Tag>

          </div>
        </div>
      </Col>
      <Col span={18}>
        <p style={{fontSize: '16px'}}><b>Character Details</b></p>
        <p style={{marginBottom: '1px'}}>Character Name</p>
        <Input 
         
          value={currentName}
          onChange={e => setCurrentName(e.target.value)}
          disabled={!isAuth || !sharedState.bookguid}
          onBlur={() => {
            if (selectedMainCharacter !== null) {
              const updatedCharacter = {...mainCharacters[selectedMainCharacter], name: currentName};
              updateCharacter(updatedCharacter);
            }
          }}
        />
		<p style={{marginBottom: '1px', marginTop: '10px'}}>Character Description</p>
		<TextArea 
          rows={4} 
          
          value={currentDescription}
          onChange={e => setCurrentDescription(e.target.value)}
          disabled={!isAuth || !sharedState.bookguid}
          onBlur={() => {
            if (selectedMainCharacter !== null) {
              const updatedCharacter = {...mainCharacters[selectedMainCharacter], description: currentDescription};
              updateCharacter(updatedCharacter);
            }
          }}
        />
		<p style={{marginBottom: '1px', marginTop: '10px'}}>Character Background</p>
		<TextArea 
          rows={4} 
          
          value={currentBackground}
          onChange={e => setCurrentBackground(e.target.value)}
          disabled={!isAuth || !sharedState.bookguid}
          onBlur={() => {
            if (selectedMainCharacter !== null) {
              const updatedCharacter = {...mainCharacters[selectedMainCharacter], background: currentBackground};
              updateCharacter(updatedCharacter);
            }
          }}
        />
{
  (bookGenre.toLowerCase().includes("steamy") || bookGenre.toLowerCase().includes("erotica")) && selectedMainCharacter !== null && 
  <div style={{marginTop: '10px'}}>
    <p style={{marginBottom: '1px'}}>Character Role
      <Tooltip title="Roles such as FMC (Female Main Character) or MMC (Male Main Character) are used to determine the main love interests in the book.">
        <InfoCircleOutlined style={{ marginLeft: '5px' }} />
      </Tooltip>	</p>
<Radio.Group
  value={mainCharacters[selectedMainCharacter]?.role || 'None'}
  onChange={(e) => {
    const updatedMainCharacters = [...mainCharacters];
    if (typeof selectedMainCharacter === "number") {
      updatedMainCharacters[selectedMainCharacter] = {
        ...updatedMainCharacters[selectedMainCharacter],
        role: e.target.value
      };
      setMainCharacters(updatedMainCharacters);
      handleBlurSave(updatedMainCharacters[selectedMainCharacter], 'mainCharacters');
    }
  }}
  disabled={!isAuth || !sharedState.bookguid}
>
  <Radio.Button value="FMC">FMC</Radio.Button>
  <Radio.Button value="MMC">MMC</Radio.Button>
  <Radio.Button value="None">None</Radio.Button>
</Radio.Group>
  </div>
}
{
  selectedMainCharacter !== null && (bookPOV === "First Person (Single PoV)" || bookPOV === "First Person (Multiple PoV)") &&
  <div style={{ marginTop: '20px' }}>
    <Checkbox
      checked={mainCharacters[selectedMainCharacter]?.povFlag === 'Yes'}
      onChange={e => {
        if (typeof selectedMainCharacter === 'number') {
          handlePoVChange(selectedMainCharacter, e.target.checked);
        }
      }}
      disabled={!isAuth || (bookPOV === "First Person (Single PoV)" && mainCharacters[selectedMainCharacter]?.povFlag !== 'Yes' && isSinglePoVSelected)}
    >
      PoV Character
      <Tooltip title={
        `This selection determines which character's point of view the story will be told from.${
          !isAuth ? " You are not authorized." : 
          (bookPOV === "First Person (Single PoV)" && mainCharacters[selectedMainCharacter]?.povFlag !== 'Yes' && isSinglePoVSelected) ? 
          " Only one PoV character is allowed in this mode and another character is already selected." : 
          (bookPOV === "First Person (Multiple PoV)" ? " Multiple characters can be selected as PoV." : "")
        }`
      }>
        <InfoCircleOutlined style={{ marginLeft: '5px' }} />
      </Tooltip>
    </Checkbox>
  </div>
}
      </Col>	
  
      <Divider />
        <Col span={6}>
          {/* Supporting characters section */}
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{marginRight: '5px', fontSize: '16px'}}><b>Supporting Characters</b></p>
              <Tooltip title="Click for more information">
                <InfoCircleOutlined
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={() => handleInfoModal(true, 'SupportingCharacters')}
                />
              </Tooltip>
            </div>
<StyledList
  dataSource={supportingCharacters}
  style={{ width: '95%' }}
  renderItem={(item: any, index: number) => (
    <List.Item
      key={index}
      style={{ 
        cursor: 'pointer',
        backgroundColor: index === selectedSupportingCharacter ? '#e6f7ff' : undefined  
      }} 
      onClick={() => handleSupportingCharacterSelect(index)}
      actions={[
<Dropdown 
  overlay={
<Menu>
  <Menu.Item key="1" onClick={() => {
    handleRegenerateCharacter('supporting', index, 'name')();
    setVisibleSupportDropdownIndex(-1);
  }}>
    Regenerate Character Name 
    <Tag color="blue" style={{ fontWeight: 'bold', marginLeft: '5px' }}>
      {costState.genCharacterNameCost} Credit
    </Tag>
  </Menu.Item>
  <Menu.Item key="2" onClick={() => {
    handleRegenerateCharacter('supporting', index, 'description')();
    setVisibleSupportDropdownIndex(-1);
  }}>
    Regenerate Character Description 
    <Tag color="blue" style={{ fontWeight: 'bold', marginLeft: '5px' }}>
      {costState.genCharacterDetailsCost} Credit
    </Tag>
  </Menu.Item>
  <Menu.Item key="3" onClick={() => {
    handleRegenerateCharacter('supporting', index, 'background')();
    setVisibleSupportDropdownIndex(-1);
  }}>
    Regenerate Character Background 
    <Tag color="blue" style={{ fontWeight: 'bold', marginLeft: '5px' }}>
      {costState.genCharacterDetailsCost} Credit
    </Tag>
  </Menu.Item>
  <Menu.Item key="4" onClick={() => {
    handleDeleteCharacter('supporting',index);
    setVisibleSupportDropdownIndex(-1);
  }}>
    Delete Character
  </Menu.Item>
  <Menu.Item key="5" onClick={() => handleSwitchToMainCharacter(index)}>
    Switch to Main Character
  </Menu.Item>  
</Menu>

  }
          trigger={['click']}
          visible={visibleSupportDropdownIndex  === index}
          onVisibleChange={(visible) => {
            if (!visible) {
              setVisibleSupportDropdownIndex(-1);
            }
          }}
        >
          <MoreOutlined
            style={{ cursor: 'pointer', fontSize: '20px', color: '#000' }}
            onClick={(event) => {
              event.stopPropagation(); // Prevent triggering the onClick event of the List.Item
              if (selectedSupportingCharacter !== index) {
                handleSupportingCharacterSelect(index);
              }
              setVisibleSupportDropdownIndex(visibleSupportDropdownIndex  === index ? -1 : index);
            }}
          />
        </Dropdown>
      ]}
    >
      {item.name}
    </List.Item>
  )}
/>
<Tooltip title={supportingCharacters.length >= usersSharedState.maxCharacters ? `You have reached the maximum number of supporting characters. ${usersSharedState.maxCharacters < 10 ? "Upgrade your account to increase the limit." : ""}` : ""}>
<Button 
  style={{ marginTop: '10px', marginRight: '10px' }} 
  onClick={handleAddSupportingCharacter}
  disabled={supportingCharacters.length >= usersSharedState.maxCharacters || !isAuth || !sharedState.bookguid}
>
  Manually Add Character
</Button>
</Tooltip>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
<Tooltip title={supportingCharacters.length >= usersSharedState.maxCharacters ? `You have reached the maximum number of supporting characters. ${usersSharedState.maxCharacters < 10 ? "Upgrade your account to increase the limit." : ""}` : ""}>
  <Button onClick={handleGenerateSupportingCharactersClick} disabled={!isAuth || !sharedState.bookguid || supportingCharacters.length >= usersSharedState.maxCharacters}>Generate Characters</Button>
</Tooltip>
<Tag color="blue" style={{ fontWeight: 'bold', marginTop: '5px' }}>
  {costState.genSupportingCharactersCost} {costState.genSupportingCharactersCost > 1 ? 'Credits' : 'Credit'}
</Tag>			  
            </div>
          </div>
        </Col>
		<Col span={18}>
		{/* Character details section */}
		<p style={{fontSize: '16px'}}><b>Character Details</b></p>
		<p style={{marginBottom: '1px'}}>Character Name</p>
		<Input 
			
			value={currentSupportingName}
			onChange={e => setCurrentSupportingName(e.target.value)}
			disabled={!isAuth || !sharedState.bookguid}
			onBlur={() => {
			if (selectedSupportingCharacter !== null) {
				const updatedCharacter = {...supportingCharacters[selectedSupportingCharacter], name: currentSupportingName};
				updateSupportingCharacter(updatedCharacter);
			}
			}}
		/>
		<p style={{marginBottom: '1px', marginTop: '10px'}}>Character Description</p>
		<TextArea 
			rows={4} 
			
			value={currentSupportingDescription}
			onChange={e => setCurrentSupportingDescription(e.target.value)}
			disabled={!isAuth || !sharedState.bookguid}
			onBlur={() => {
			if (selectedSupportingCharacter !== null) {
				const updatedCharacter = {...supportingCharacters[selectedSupportingCharacter], description: currentSupportingDescription};
				updateSupportingCharacter(updatedCharacter);
			}
			}}
		/>
		<p style={{marginBottom: '1px', marginTop: '10px'}}>Character Background</p>
		<TextArea 
			rows={4} 
			
			value={currentSupportingBackground}
			onChange={e => setCurrentSupportingBackground(e.target.value)}
			disabled={!isAuth || !sharedState.bookguid}
			onBlur={() => {
			if (selectedSupportingCharacter !== null) {
				const updatedCharacter = {...supportingCharacters[selectedSupportingCharacter], background: currentSupportingBackground};
				updateSupportingCharacter(updatedCharacter);
			}
			}}
		/>
		</Col>
      </Row>  
      
      <Modal 
        title={infoTitle}
        visible={isInfoModalVisible}
        onOk={() => handleInfoModal(false, "")}
        onCancel={() => handleInfoModal(false, "")}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button key="submit" type="primary" onClick={() => handleInfoModal(false, "")}>
              OK
            </Button>
          </div>
        }
      >
        {infoModalContent}
      </Modal>
	  
	  <Modal 
	  title="Error"
	  visible={isErrorModalVisible}
	  onOk={() => setErrorModalVisible(false)}
	  onCancel={() => setErrorModalVisible(false)}
	  footer={
	 	 <div style={{ textAlign: 'center' }}>
	 	 <Button key="submit" type="primary" onClick={() => setErrorModalVisible(false)}>
	 		 OK
	 	 </Button>
	 	 </div>
	  }
	  >
	  <p>{errorMessage}</p>
	  </Modal>
	  
	  <Modal
	  title={null}
	  visible={isLoading}
	  footer={null}
	  centered
	  closable={false}
	  maskClosable={false}
	  style={{ 
	 	 textAlign: 'center', 
	 	 background: 'transparent', 
	 	 boxShadow: 'none',
	 	 width: 'auto'
	  }}
	  >
	  <img src="generating.gif" alt="Generating..." className="responsive-gif" />
	  </Modal>	  

	  <Modal 
	  title="Are you sure you want to regenerate your Main Characters?"
	  visible={isConfirmMainCharactersModalVisible}
	  onOk={() => handleConfirmMainCharactersModal(true)}
	  onCancel={() => handleConfirmMainCharactersModal(false)}
	  >
	  <p>If you proceed, the existing Main Characters text will be deleted and replaced. <br/>
	  
	  Regenerating Main Characters will NOT change any other text fields already generated.<br/><br/>
	  
	  Do you want to continue?</p>
	  <Checkbox onChange={(e) => setShouldSkipMainCharactersConfirm(e.target.checked)}>Don't show this again for this session</Checkbox>
	  </Modal>
	  
	  <Modal 
	  title="Are you sure you want to regenerate your Supporting Characters?"
	  visible={isConfirmSupportingCharactersModalVisible}
	  onOk={() => handleConfirmSupportingCharactersModal(true)}
	  onCancel={() => handleConfirmSupportingCharactersModal(false)}
	  >
	  <p>If you proceed, the existing Supporting Characters text will be deleted and replaced. <br/>
	    
	  Regenerating Supporting Characters will NOT change any other text fields already generated.<br/><br/>
	  
	  Do you want to continue?</p>
	  <Checkbox onChange={(e) => setShouldSkipSupportingCharactersConfirm(e.target.checked)}>Don't show this again for this session</Checkbox>
	  </Modal>

<Modal
  title="Additional Details"
  visible={showModal}
  onOk={() => {
    handleGenerateWithDetails();
    setShowModal(false);
  }}
  onCancel={() => setShowModal(false)}
  footer={[
    <Button key="cancel" onClick={() => setShowModal(false)}>
      Cancel
    </Button>,
    <Button 
      key="submit" 
      type="primary" 
      onClick={() => {
        handleGenerateWithDetails();
        setShowModal(false);
      }}
    >
      Submit
    </Button>,
  ]}
>
  <div className="modal-content" style={{ display: 'flex', justifyContent: 'center' }}>
    <textarea
      value={additionalDetails}
      onChange={(e) => setAdditionalDetails(e.target.value)}
      placeholder="(OPTIONAL) Enter any additional details for the AI..."
	  style={{
        width: '90%',
        height: '200px',
        padding: '10px',
        fontSize: '16px',
        lineHeight: '1.5',
        resize: 'none'
      }}
    />
  </div>
</Modal>
  	  
    </div>
  );
};

export default CharactersTab;
