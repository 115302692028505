// MetadataTab.tsx
import React, { ChangeEvent, useState } from 'react';
import { Input, Select, Tooltip, Button, Space, Modal, Spin, Checkbox, Tag, Card, message, Row, Col } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons'
import axios, { AxiosError, AxiosResponse } from 'axios';
import { User } from "firebase/auth";
import { getFirestore, doc, updateDoc, Timestamp, getDoc  } from "firebase/firestore"; 
import './GeneratingGifStyle.css';

import { SharedState, UsersSharedState, TabProps, CustomAxiosError } from './types';


const { TextArea } = Input;
const { Option } = Select;

interface CoverCardProps {
  genre: string;
  onSelect: (styleName: string) => void;
}

const CoverCard: React.FC<CoverCardProps> = ({ genre, onSelect }) => {
  const coverStyles = getCoverStylesForGenre(genre); // Function to get cover styles based on genre

  return (
    <Row gutter={[16, 16]}>
      {coverStyles.map((style, index) => (
        <Col key={index} span={8}>
          <Card
            hoverable
            cover={<img alt={style.name} src={style.imageUrl} />}
            onClick={() => onSelect(style.name)}
          >
            <Card.Meta title={style.name} />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

function getCoverStylesForGenre(genre: string): { name: string; imageUrl: string }[] {
  // Return an array of cover styles for the given genre
  // This is just a placeholder, replace it with real data
  return [
    { name: 'Style 1', imageUrl: 'url_to_image_1' },
    { name: 'Style 2', imageUrl: 'url_to_image_2' },
    // ...
  ];
}

const MetadataTab: React.FC<TabProps> = ({ sharedState, setSharedState, isAuth, user, usersSharedState, setUsersSharedState }) => {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [infoTitle, setInfoTitle] = useState("");
  const [infoContent, setInfoContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [shouldSkipTitleConfirm, setShouldSkipTitleConfirm] = useState(false);
  const [shouldSkipBlurbConfirm, setShouldSkipBlurbConfirm] = useState(false);
  const [titleOptions, setTitleOptions] = useState<string[]>([]);
  const [isTitleSelectionModalVisible, setIsTitleSelectionModalVisible] = useState(false);
  const [transferMessage, setTransferMessage] = useState("");  
  const [coverApiResponse, setCoverApiResponse] = useState('');
  const [isCoverGenModalVisible, setIsCoverGenModalVisible] = useState(false);

  const db = getFirestore();

  const handleInfoModal = (visible: boolean, source: string) => {
    if (source === 'BasicPlot')
	{
	setInfoTitle("Basic Plot Information");
    setInfoContent(`ScribeShadow can generate a basic plot based on the Genre and Tropes in the High Level tab. You can also write your own plot that you want ScribeShadow to use.
	
	Any character names or locations included in the Basic Plot will be used when ScribeShadow generates characters or locations, so make sure you are happy with them before continuing.
	
	ScribeShadow will use the Basic Plot when generating Characters, Locations, and your Outline.`);
    }	
    setIsInfoModalVisible(visible);
  };

  let infoModalContent = (
    <p>
      {infoContent.split('\n').map((line, i) => (
        <span key={i}>
          {line}
          <br />
        </span>
      ))}
    </p>
  );

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSharedState({ ...sharedState, title: e.target.value });
  };

  const handleBlurbChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setSharedState({ ...sharedState, blurb: e.target.value });
  };

  const saveTitleToFirestore = async () => {
    const db = getFirestore();
    await updateDoc(doc(db, "books", sharedState.bookguid), { title: sharedState.title });
  };

  const saveBlurbToFirestore = async () => {
    const db = getFirestore();
    await updateDoc(doc(db, "books", sharedState.bookguid), { blurb: sharedState.blurb });
  };
    
  const handleErrorModal = (visible: boolean) => {
    setErrorModalVisible(visible);
  };
  
  
const handleExportToWord = async (type: 'novel' | 'blurb', vella: boolean = false) => {
  if (!sharedState.bookguid || !isAuth || (type === 'novel' && sharedState.novel.length === 0) || (type === 'blurb' && !sharedState.blurb)) {
    setErrorMessage(`Cannot export ${type}. Please make sure you are logged in and the ${type} is populated.`);
    setErrorModalVisible(true);
    return;
  }
  //console.log("Vella: ",vella)
  setIsLoading(true);

  try {
    let input;
    if (type === 'novel') {
      const titlePage = `Title: ${sharedState.title}\nAuthor: ${sharedState.author}\n<PAGE BREAK>\n`;
      const chaptersWithPageBreaks = sharedState.novel.map(chapter => `${chapter}\n<PAGE BREAK>\n`);
      input = titlePage + chaptersWithPageBreaks.join('\n');
    } else {
      input = sharedState.blurb;
    }
  
	const extension = vella ? 'zip' : 'docx';
	const response = await axios.post('http://192.168.1.187:8000/export/word', {
		input,
		title: sharedState.title,
		type,
		vella,
	}, {
		responseType: 'blob', // Important to set this, as we're expecting a Stream/Blob
	});
	
	const url = window.URL.createObjectURL(new Blob([response.data]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', `${sanitizeFilename(sharedState.title)}_${type}.${extension}`); 
	document.body.appendChild(link);
	link.click();
	
  } catch (error) {
    const axiosErr = error as AxiosError;
    if (axiosErr.response && axiosErr.response.data && typeof (axiosErr.response.data as any).detail === 'string') {
      setErrorMessage((axiosErr.response.data as any).detail);
    } else {
      setErrorMessage('An error occurred while exporting.');
    }
    handleErrorModal(true);
  } finally {
    setIsLoading(false);
  }
};

  
const handleGenerateTitle = async () => {
  setIsLoading(true);
  try {
    const response = await axios.post('http://192.168.1.187:8000/generate_title/', { 
      bookguid: sharedState.bookguid,
      userID: usersSharedState.userID	
    });
        
    setUsersSharedState((prevState: UsersSharedState) => ({
      ...prevState,
      credits: response.data.creditsRemaining,
    }));

    // Extract JSON string from the response
    const jsonString = response.data.title.match(/\{.*\}/s)?.[0];
    if (jsonString) {
      const jsonData = JSON.parse(jsonString);
      const titles = jsonData.Titles;
      if (titles && titles.length) {
        setTitleOptions(titles);
        setIsTitleSelectionModalVisible(true);
      } else {
        setErrorMessage('No titles were generated.');
        handleErrorModal(true);
      }
    } else {
      setErrorMessage('Invalid response format.');
      handleErrorModal(true);
    }
	
  } catch (err) {
    const axiosErr = err as AxiosError;
    if (axiosErr.response && axiosErr.response.data && typeof (axiosErr.response.data as any).detail === 'string') {
      setErrorMessage((axiosErr.response.data as any).detail); 
    } else {
      setErrorMessage('An error occurred while generating the title.');
    }
    handleErrorModal(true);
  } finally {
    setIsLoading(false);
  }
};

  const handleGenerateCoversClick = async () => {
    setIsCoverGenModalVisible(true); 
  };

  const handleCoverSelection = (styleName: string) => {
    console.log('Selected style:', styleName);
    // Handle the cover style selection
    setIsCoverGenModalVisible(false); // Close the modal after selection
  };



  const handleGenerateCovers = async () => {
	setIsLoading(true);
    try {
		
  	// Convert arrays to strings
    const mainCharactersString = sharedState.mainCharacters.map(character => {
		return `${character.name} - Background: ${character.background}, Description: ${character.description}`;
		}).join('\n');
		
    const supportingCharactersString = sharedState.supportingCharacters.map(character => {
		return `${character.name} - Background: ${character.background}, Description: ${character.description}`;
		}).join('\n');				
		
		const response = await axios.post('http://192.168.1.187:8000/generate_covers/', { 
		apimodel: sharedState.model, 
		genre: sharedState.genre, 
		tropes: sharedState.tropes, 
		plot: sharedState.basicPlot,
		userID: usersSharedState.userID,
        mainCharacters: sharedState.mainCharacters,
        supportingCharacters: sharedState.supportingCharacters, 
		locations: sharedState.locations, 
		writingStyle: sharedState.writingStyle
		});

      // Update the state with the API response
      setCoverApiResponse(response.data.prompt);
    } catch (error) {
      console.error('Error generating covers:', error);
      setCoverApiResponse('Failed to generate covers');
    }
	finally {
    setIsLoading(false);
    }	
  };

const handleTitleSelection = async (selectedTitle: string) => {
  setSharedState({ ...sharedState, title: selectedTitle });
  setIsTitleSelectionModalVisible(false);

  if (sharedState.bookguid && isAuth) {
    try {
      const bookRef = doc(db, 'books', sharedState.bookguid);
      await updateDoc(bookRef, { title: selectedTitle });
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  }
};

const handleGenerateBlurb = async () => {
  setIsLoading(true);
  
  try {
    const response = await axios.post('http://192.168.1.187:8000/generate_blurb/', { 
      apimodel: sharedState.model, 
      genre: sharedState.genre, 
      tropes: sharedState.tropes, 
      plot: sharedState.basicPlot,
      pov: sharedState.pov,
	  tense: sharedState.tense,
	  writingStyle: sharedState.writingStyle,
      userID: usersSharedState.userID	
    });
        
    const updatedBlurb = response.data.blurb;
    setSharedState({ ...sharedState, blurb: updatedBlurb });

    setUsersSharedState((prevState: UsersSharedState) => ({
      ...prevState,
      credits: response.data.creditsRemaining,
    }));

    if (sharedState.bookguid && isAuth && user) {
      try {
        const bookRef = doc(db, 'books', sharedState.bookguid);
        await updateDoc(bookRef, { blurb: updatedBlurb });
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  } catch (err) {
    const axiosErr = err as AxiosError;
    if (axiosErr.response && axiosErr.response.data && typeof (axiosErr.response.data as any).detail === 'string') {
      setErrorMessage((axiosErr.response.data as any).detail); 
    } else {
      setErrorMessage('An error occurred while generating the blurb.');
    }
    handleErrorModal(true);
  } finally {
    setIsLoading(false);
  }
};

	
	function sanitizeFilename(filename: string) {
		return filename.replace(/[^a-zA-Z0-9\.-]/g, '');
	}	


	const handleExportToText = async (type: 'novel' | 'blurb') => {
	if (!sharedState.bookguid || !isAuth || (type === 'novel' && sharedState.novel.length === 0) || (type === 'blurb' && !sharedState.blurb)) {
		setErrorMessage(`Cannot export ${type}. Please make sure you are logged in and the ${type} is populated.`);
		setErrorModalVisible(true);
		return;
	}
	
	setIsLoading(true);
	
	try {
		const input = type === 'novel' ? sharedState.novel.join('\n') : sharedState.blurb;
		const response = await axios.post('http://192.168.1.187:8000/export/text', {
			input,
			title: sharedState.title,
			type,
		}, {
			responseType: 'blob', // Important to set this, as we're expecting a Stream/Blob
		});
	
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${sanitizeFilename(sharedState.title)}_${type}.txt`); 
		document.body.appendChild(link);
		link.click();
	} catch (error) {
		const axiosErr = error as AxiosError;
		if (axiosErr.response && axiosErr.response.data && typeof (axiosErr.response.data as any).detail === 'string') {
		setErrorMessage((axiosErr.response.data as any).detail);
		} else {
		setErrorMessage('An error occurred while exporting.');
		}
		handleErrorModal(true);
	} finally {
		setIsLoading(false);
	}
	};

const handleTransferToTranslate = async () => {
  if (!sharedState.bookguid || !isAuth) {
    message.error('Cannot transfer to translate. Please make sure you are logged in and have selected a book.');
    return;
  }

  setIsLoading(true);

  try {
    const response = await axios.post('http://192.168.1.187:8000/translate_transfer', {
      userId: usersSharedState.userID,
      bookId: sharedState.bookguid
    });

    if (response.data.status === "success") {
      message.success(response.data.message || "Successfully transferred to Translate.");
    } else {
      throw new Error(response.data.message || 'Failed to transfer to Translate.');
    }
    
  } catch (error) {
    let errorMessage = 'An error occurred while transferring to translate.';
    if (axios.isAxiosError(error)) {
      errorMessage = error.response?.data?.detail || error.message || errorMessage;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }
    message.error(errorMessage);
  } finally {
    setIsLoading(false);
  }
};


  return (
    
	<div style={{ backgroundColor: '#ffffff', padding: '0em', borderRadius: '5px' }}>
	
          {!sharedState.bookguid && isAuth && <Tooltip title="Please start or open a book in the Book Management tab."><p><b>Please start or open a book in the Book Management tab.<br/>Fields will become available once a book is in progress.</b></p></Tooltip>}
		  {!isAuth && <Tooltip title="Please click Get Started."><p><b>Click Get Started to sign up and start generating books!</b></p></Tooltip>}

  {/* Covers Section */}
  {/* 
  <div style={{ marginBottom: '20px' }}>
    <p style={{ fontWeight: 'bold' }}>Covers:</p>
    {isAuth && sharedState.bookguid ? (
      <div>
            
            <Button onClick={handleGenerateCoversClick}>Generate Covers</Button>

            
              <div style={{ marginTop: '10px' }}>
                <Input.TextArea value={coverApiResponse} readOnly />
              </div>	  
	*/}
        {/* 
        <div style={{ marginBottom: '10px' }}>
          <img src={selectedCoverImageUrl} alt="Selected Cover" style={{ maxWidth: '100%', borderRadius: '5px' }} />
        </div>

        
        <div style={{ display: 'flex', overflowX: 'auto' }}>
          {otherCoverImages.map((cover, index) => (
            <div key={index} style={{ marginRight: '10px' }}>
              <img src={cover.url} alt={`Cover ${index + 1}`} style={{ maxWidth: '100px', borderRadius: '5px' }} onClick={() => setSelectedCoverImageUrl(cover.url)} />
            </div>
          ))}
		 
        </div> */}
		{/*
      </div>
    ) : (
      <Tooltip title="Please log in and select a book to use this feature.">
        <div>No covers to display</div>
      </Tooltip>
    )}
		
  </div>*/}

		  
      <div style={{ marginBottom: '20px' }}>
        <Space>
          <p style={{ fontWeight: 'bold' }}>Title:</p>
        </Space>
        <Input value={sharedState.title} onChange={handleTitleChange} onBlur={saveTitleToFirestore} disabled={!isAuth || !sharedState.bookguid}/>
<div style={{ marginTop: '10px' }}>
  <div style={{ display: 'inline-block' }}>
    {isAuth && sharedState.bookguid ? (
      <Button onClick={handleGenerateTitle}>Generate Title Options</Button>
    ) : (
      <Tooltip title="Please log in and select a book to use this feature.">
        <Button disabled>Generate Title</Button>
      </Tooltip>
    )}
    {sharedState.genTitleCost !== null && (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
        <Tag color="blue" style={{ fontWeight: 'bold' }}>
          {sharedState.genTitleCost} {sharedState.genTitleCost > 1 ? 'Credits' : 'Credit'}
        </Tag>
      </div>
    )}
  </div>
</div>

      </div>
	  
      <div style={{ marginBottom: '20px' }}>
        <Space>
          <p style={{ fontWeight: 'bold' }}>Blurb:</p>
        </Space>
        <TextArea rows={10} value={sharedState.blurb} onChange={handleBlurbChange} onBlur={saveBlurbToFirestore} disabled={!isAuth || !sharedState.bookguid}/>
<div style={{ marginTop: '10px' }}>
  <div style={{ display: 'inline-block' }}>
    {isAuth && sharedState.bookguid ? (
      <Button onClick={handleGenerateBlurb}>Generate Blurb</Button>
    ) : (
      <Tooltip title="Please log in and select a book to use this feature.">
        <Button disabled>Generate Blurb</Button>
      </Tooltip>
    )}
    {sharedState.genBlurbCost !== null && (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
        <Tag color="blue" style={{ fontWeight: 'bold' }}>
          {sharedState.genBlurbCost} {sharedState.genBlurbCost > 1 ? 'Credits' : 'Credit'}
        </Tag>
      </div>
    )}
  </div>
</div>

      </div>

<div style={{ marginBottom: '20px' }}>
  <p style={{ fontWeight: 'bold' }}>Export:</p>
  <div style={{ display: 'flex', justifyContent: 'left' }}>
    <div style={{ marginRight: '20px' }}> {/* Novel buttons */}
      <Button style={{ width: '225px', marginBottom: '10px' }} onClick={() => handleExportToWord('novel')} disabled={!isAuth || !sharedState.bookguid}>Export Novel to Word</Button><br/>
      <Button style={{ width: '225px', marginBottom: '10px' }} onClick={() => handleExportToText('novel')} disabled={!isAuth || !sharedState.bookguid}>Export Novel to Text</Button><br/>
      <Button style={{ width: '225px', marginBottom: '10px' }} onClick={() => handleExportToWord('novel', true)} disabled={!isAuth || !sharedState.bookguid}>
        Export Novel for Vella
        <Tooltip title="This will export a zip file with each chapter contained in its own docx file.">
          <InfoCircleOutlined style={{ marginLeft: '5px' }} />
        </Tooltip>
      </Button> <br/>
      <Button style={{ width: '225px', marginBottom: '10px' }} onClick={() => handleTransferToTranslate()} disabled={!isAuth || !sharedState.bookguid}>
        Translate with ScribeShadow
        <Tooltip title="This will transfer the book's data to the Translate section of ScribeShadow. Make sure your novel is completed before translating as changes made here will not replicate over automatically.">
          <InfoCircleOutlined style={{ marginLeft: '5px' }} />
        </Tooltip>
      </Button> 	  
    </div>
    <div> {/* Blurb buttons */}
      <Button style={{ width: '225px', marginBottom: '10px' }} onClick={() => handleExportToWord('blurb')} disabled={!isAuth || !sharedState.bookguid}>Export Blurb to Word</Button><br/>
      <Button style={{ width: '225px' }} onClick={() => handleExportToText('blurb')} disabled={!isAuth || !sharedState.bookguid}>Export Blurb to Text</Button>
    </div>
  </div>
</div>      
      <Modal 
        title={infoTitle}
        visible={isInfoModalVisible}
        onOk={() => handleInfoModal(false, "")}
        onCancel={() => handleInfoModal(false, "")}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button key="submit" type="primary" onClick={() => handleInfoModal(false, "")}>
              OK
            </Button>
          </div>
        }
      >
        {infoModalContent}
      </Modal>
	  
	  <Modal 
	  title="Error"
	  visible={isErrorModalVisible}
	  onOk={() => setErrorModalVisible(false)}
	  onCancel={() => setErrorModalVisible(false)}
	  footer={
	 	 <div style={{ textAlign: 'center' }}>
	 	 <Button key="submit" type="primary" onClick={() => setErrorModalVisible(false)}>
	 		 OK
	 	 </Button>
	 	 </div>
	  }
	  >
	  <p>{errorMessage}</p>
	  </Modal>

<Modal
  title="Choose a Title"
  visible={isTitleSelectionModalVisible}
  onCancel={() => setIsTitleSelectionModalVisible(false)}
  footer={
    <Button key="cancel" onClick={() => setIsTitleSelectionModalVisible(false)}>
      Cancel
    </Button>
  }
>
  <div>
    {titleOptions.map((title, index) => (
      <Card
        key={index}
        hoverable
        style={{ marginBottom: 10 }}
        onClick={() => handleTitleSelection(title)}
      >
        <p>{title}</p>
      </Card>
    ))}
  </div>
</Modal>

	  
	  <Modal
	  title={null}
	  visible={isLoading}
	  footer={null}
	  centered
	  closable={false}
	  maskClosable={false}
	  style={{ 
	 	 textAlign: 'center', 
	 	 background: 'transparent', 
	 	 boxShadow: 'none',
	 	 width: 'auto'
	  }}
	  >
	  <img src="generating.gif" alt="Generating..." className="responsive-gif" />
	  </Modal>	  

      <Modal
        title={`Select a Cover Style for ${sharedState.genre}`}
        visible={isCoverGenModalVisible}
        onCancel={() => setIsCoverGenModalVisible(false)}
        footer={null}
      >
        <CoverCard genre={sharedState.genre} onSelect={handleCoverSelection} />
      </Modal>
	  	  
    </div>

  );
};

export default MetadataTab;
