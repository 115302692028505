// KeywordSuggestionModal.tsx

import React, { useState, useEffect } from 'react';
import { Modal, Button, Select, Checkbox, Tooltip, message, Spin, Tag, Alert } from 'antd';
import { QuestionCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { DownOutlined } from '@ant-design/icons';
import axios from 'axios';

interface KeywordSuggestionModalProps {
  isVisible: boolean;
  toggleModal: () => void;
  bookId: string;
  bookLanguage: string;
  genres: string;
  tropes: string[];
  keywords: string[];
  blurb: string;
  selectedAIModel: string;
  fetchCreditBalance: (userId: string) => Promise<void>;
  creditBalance: { credit_balance: number } | null;
  auth: any;
  languages: string[];
  betaLanguages: string[];
  fetchBookDetails: () => void; 
  isLoading: boolean; 
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  targetLanguage: string;
}

const KeywordSuggestionModal: React.FC<KeywordSuggestionModalProps> = ({
  isVisible,
  toggleModal,
  bookId,
  bookLanguage,
  genres,
  tropes,
  keywords,
  blurb,
  selectedAIModel,
  fetchCreditBalance,
  creditBalance,
  auth,
  languages,
  betaLanguages,
  fetchBookDetails,
  isLoading,
  setIsLoading,  
  targetLanguage,
}) => {
  const [keywordAIModel, setKeywordAIModel] = useState<string>('');
  const [targetLang, setTargetLang] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState({
    genre: false,
    tropes: false,
    keywords: false,
    blurb: false,
  });

  useEffect(() => {
    setKeywordAIModel(selectedAIModel);
    // Update targetLang based on targetLanguage prop
    setTargetLang(targetLanguage || '');
  }, [selectedAIModel, targetLanguage]);

  const isAnyOptionSelected = Object.values(selectedOptions).some(option => option);

  const calculateKeywordSuggestionCost = () => {
    let cost = 0;
    if (isAnyOptionSelected) {
      cost = 500;
    }
    return cost;
  };

  const userHasEnoughCredits = creditBalance?.credit_balance ?? 0 >= calculateKeywordSuggestionCost();

  const handleKeywordSuggestions = async () => {
    if (auth.currentUser) {
	
	  setIsLoading(true);	
		
      const payload = {
        userId: auth.currentUser.uid,
        bookId: bookId,
        originLanguage: bookLanguage,
        targetLanguage: targetLang,
        options: selectedOptions,
        model: keywordAIModel,
      };

      let success = false;

      try {
        toggleModal();
        const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/suggest-keywords/', payload);
        success = true;
		fetchBookDetails();
      } catch (error) {
        success = false;
      } finally {
		setIsLoading(false);  
        if (success) {
          message.success("Keywords generated successfully.");
        } else {
          message.error("Error generating keywords.");
        }
      }
    }
  };

const renderCheckbox = (label: string, value: boolean, onChange: (e: any) => void, disabled: boolean, tooltip: string) => {
  const displayLabel = label === 'Keywords' ? `${bookLanguage} Keywords` : label;
  
  return (  
    <Tooltip title={disabled ? tooltip : ''}>
      <Checkbox checked={value} onChange={onChange} disabled={disabled}>
        {displayLabel}
      </Checkbox>
    </Tooltip>
  )
};


  // Status messages to display
  const getStatusMessages = () => {
    const messages = [];
    
    // Error messages
    if (!userHasEnoughCredits) {
      messages.push({
        type: 'error',
        message: 'You do not have enough credits for this operation.',
        icon: <ExclamationCircleOutlined />
      });
    }
    
    if (!targetLang) {
      messages.push({
        type: 'error',
        message: 'Please select a target language to proceed.',
        icon: <ExclamationCircleOutlined />
      });
    }
    
    if (!isAnyOptionSelected) {
      messages.push({
        type: 'error',
        message: 'Please select at least one data source for keyword generation.',
        icon: <ExclamationCircleOutlined />
      });
    }
    
    // Warning messages
    if (genres.length === 0 && tropes.length === 0 && keywords.length === 0 && blurb.length === 0) {
      messages.push({
        type: 'warning',
        message: 'No metadata is available. Add genre, tropes, keywords, or blurb to your book for better results.',
        icon: <WarningOutlined />
      });
    }
    
    return messages;
  };

  return (
    <Modal
      title="Keyword Suggestions"
      visible={isVisible}
      onCancel={toggleModal}
      footer={[
        <Button key="cancel" onClick={toggleModal}>Cancel</Button>,
        <Button 
          key="suggest" 
          type="primary" 
          onClick={handleKeywordSuggestions} 
          disabled={!isAnyOptionSelected || !userHasEnoughCredits || !targetLang}
        >
          Generate Keywords
        </Button>,
      ]}
      width={700}
    >
      <div>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            <strong>Origin Language:</strong>
          </label>
          <div>{bookLanguage}</div>
        </div>
        
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            <strong>Target Language:</strong>
          </label>
          <Select
            value={targetLang}
            onChange={(value) => setTargetLang(value)}
            style={{ width: '100%' }}
            suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
          >
            {languages.filter(lang => lang !== bookLanguage).map(lang => (
              <Select.Option key={lang} value={lang}>
                {lang} {betaLanguages.includes(lang) ? "(BETA)" : ""}
              </Select.Option>
            ))}
          </Select>    
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>
            <strong>AI Model:</strong>
          </label>
          <Select
            value={keywordAIModel}
            onChange={(value) => setKeywordAIModel(value)}
            style={{ width: '100%' }}
            suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
          >
            <Select.Option value="Claude3.7">
              Claude 3.7 <Tag color="geekblue" style={{ marginLeft: '8px', position: 'relative', top: '-0.5px' }}>Recommended</Tag>
            </Select.Option>
            <Select.Option value="Claude">Claude 3.5</Select.Option>
            <Select.Option value="Chat-GPT">Chat-GPT</Select.Option>	
          </Select>
        </div>
        
        <div style={{ 
          background: '#f7f7f7', 
          padding: '15px', 
          borderRadius: '6px', 
          marginBottom: '15px' 
        }}>
          <div style={{ marginBottom: '10px' }}>
            <strong>Data Sources for Keyword Generation</strong>
          </div>
          <p>Check what data you want ScribeShadow to use when suggesting keywords. For best results, populate Keywords, Genre, and Tropes in your metadata.</p>
          {renderCheckbox('Genre', selectedOptions.genre, (e) => setSelectedOptions({ ...selectedOptions, genre: e.target.checked }), genres.length === 0, "Genre has not been provided.")}
          {renderCheckbox('Tropes', selectedOptions.tropes, (e) => setSelectedOptions({ ...selectedOptions, tropes: e.target.checked }), tropes.length === 0, "Tropes have not been provided.")}
          {renderCheckbox('Keywords', selectedOptions.keywords, (e) => setSelectedOptions({ ...selectedOptions, keywords: e.target.checked }), keywords.length === 0, "Keywords have not been provided.")}
          {renderCheckbox('Blurb', selectedOptions.blurb, (e) => setSelectedOptions({ ...selectedOptions, blurb: e.target.checked }), blurb.length === 0, "Blurb has not been provided.")}
        </div>
        
        <div style={{ 
          background: '#f0f7ff', 
          padding: '15px', 
          borderRadius: '6px', 
          marginBottom: '15px' 
        }}>
          <div style={{ marginBottom: '8px' }}>
            <strong>Credit Balance: </strong>
            <span>{creditBalance?.credit_balance ?? 0}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <strong>Credit Cost: </strong>
            <span style={{ marginLeft: '5px', marginRight: '5px' }}>{calculateKeywordSuggestionCost()}</span>
            <Tooltip title="The cost for generating keyword suggestions">
              <QuestionCircleOutlined style={{ color: '#1677ff' }} />
            </Tooltip>
          </div>
        </div>
        
        {/* Status Messages */}
        <div style={{ marginTop: '12px' }}>
          {getStatusMessages().map((msg, index) => (
            <Alert
              key={index}
              message={msg.message}
              type={msg.type as any}
              icon={msg.icon}
              style={{ marginBottom: '8px' }}
              showIcon
              closable={false}
              banner={false}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default KeywordSuggestionModal;