import React, { MouseEvent, useEffect, useState, useCallback, useMemo } from 'react';
import { Spin, Modal, Button, Input, Upload, Card, Divider, Select, message, Dropdown, Menu, Tooltip, Empty } from 'antd';
import { 
  UploadOutlined, 
  TranslationOutlined, 
  DeleteOutlined, 
  EllipsisOutlined, 
  ClearOutlined, 
  SearchOutlined,
  FilterOutlined,
  BookOutlined,
  ReadOutlined,
  UserOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
  FileWordOutlined,
  FilePdfOutlined  
} from '@ant-design/icons';
import { User } from "firebase/auth";
import axios, { AxiosError } from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";

import BookUploadForm from './BookUploadForm';

const { Option } = Select;

const WordIcon = () => (
  <svg viewBox="0 0 24 24" width="16" height="16" fill="#2b5797">
    <path d="M14,2H6C4.89,2 4,2.89 4,4V20C4,21.11 4.89,22 6,22H18C19.11,22 20,21.11 20,20V8L14,2M18,20H6V4H13V9H18V20M9.5,11.5L7.5,16H9.5L10.25,14H12.75L13.5,16H15.5L13.5,11.5C13.5,11.5 12.5,11.5 11.5,11.5C10.5,11.5 9.5,11.5 9.5,11.5M11.5,12.5H12.5L13,13.5H11L11.5,12.5Z" />
  </svg>
);

// Modern styled components
const Container = styled.div`
  background-color: #f6f8fa;
  padding: 2em;
  min-height: calc(100vh - 64px);
  
  @media (max-width: 768px) {
    padding: 1em;
  }
`;

const StyledCard = styled(Card)`
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  padding: 2em;
  
  @media (max-width: 768px) {
    padding: 1em;
  }
`;

const BookItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const AuthorBooksContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 40px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.3);
  overflow: hidden;
`;

const AuthorHeader = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  padding: 18px 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: linear-gradient(to right, #f8f9fa, #ffffff);
  border-left: 4px solid #1890ff;
  
  &:hover {
    color: #1890ff;
    background: linear-gradient(to right, #f0f7ff, #ffffff);
  }
  
  svg {
    margin-right: 10px;
    color: #1890ff;
  }
`;

// Series header with subtle line separator
const SeriesHeader = styled.div`
  font-size: 1.2rem;
  margin: 0;
  padding: 14px 24px 14px 48px;
  color: #595959;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-top: 1px solid #f0f0f0;
  background-color: #fafafa;
  
  &:hover {
    color: #1890ff;
    background-color: #f5faff;
  }
  
  svg {
    margin-right: 10px;
    color: #1890ff;
    font-size: 1rem;
  }
`;


const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 16px;
  
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Header = styled.h2`
  margin: 0;
  font-size: 1.8rem;
  font-weight: 600;
  color: #262626;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 12px;
    color: #1890ff;
  }
`;

const BookCover = styled.div<{ coverUrl?: string }>`
  background-image: url(${props => props.coverUrl || '/default-cover.jpg'});
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  height: 220px;
  width: 140px;
  margin-bottom: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const BookTitle = styled.div<{ coverUrl?: string }>`
  display: ${props => props.coverUrl ? 'none' : 'flex'};
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #2c3e50, #4a5568);
  color: white;
  height: 100%;
  text-align: center;
  padding: 10px;
  font-weight: 500;
`;

const BookTitleOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  color: white;
  padding: 12px 8px;
  font-size: 0.85rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;

  ${BookCover}:hover & {
    opacity: 1;
  }
`;

const BookGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 24px;
  margin-top: 16px;
  padding: 0 24px 0 72px;
  
  @media (max-width: 576px) {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-gap: 16px;
    padding: 0 16px 0 40px;
  }
`;

const ModalTitle = styled.h3`
  text-align: center;
  margin: 0;
  font-size: 1.4rem;
  font-weight: 500;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 16px;
  background-color: #f5f7fa;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FilterSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  
  @media (max-width: 768px) {
    width: 100%;
    
    .ant-select, .ant-input-search {
      width: 100% !important;
    }
  }
`;

const MenuButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  border: none;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease, background-color 0.2s ease, transform 0.2s ease;
  
  ${BookCover}:hover & {
    opacity: 1;
  }
  
  &:hover {
    background-color: rgba(255, 255, 255, 1);
    transform: scale(1.1);
  }
  
  &:active {
    transform: scale(0.95);
  }
`;

const BookMenu = styled.div`
  position: absolute;
  right: 0;
  top: 40px;
  background-color: white;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  width: 180px;
  z-index: 100;
  animation: fadeIn 0.2s ease;
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }
`;

const MenuOption = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.2s;
  color: #595959;

  &:hover {
    background-color: #f5f5f5;
    color: #1890ff;
  }
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  text-align: center;
`;

const EmptyStateText = styled.p`
  color: #8c8c8c;
  font-size: 1.1rem;
  margin: 16px 0;
`;

const BookText = styled.div`
  font-size: 0.9rem;
  margin-top: 5px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
  color: #262626;
  font-weight: 500;
`;

const SeriesInfo = styled.span`
  display: inline-block;
  font-size: 0.8rem;
  color: #8c8c8c;
  margin-top: 2px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
`;

const BookCountBadge = styled.span`
  font-size: 0.75rem;
  background-color: #f0f2f5;
  color: #5c6b77;
  padding: 2px 8px;
  border-radius: 10px;
  margin-left: 8px;
  font-weight: normal;
`;

const CollapseIcon = styled.span`
  margin-right: 10px;
  transition: transform 0.3s ease;
  display: inline-flex;
  font-size: 14px;
`;

const SeriesContent = styled.div`
  padding: 0 0 20px 0;
  background-color: #ffffff;
`;

const FileTypeBadge = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 4px;
  padding: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Interface for books
interface IBook {
  bookguid: string;
  title: string;
  author: string; 
  series: string; 
  seriesBookNumber?: number;
  coverUrl?: string;
  uploadType?: string;
}

interface Series {
  name: string;
  books: IBook[];
}

interface AuthorSeriesGroup {
  author: string;
  series: Series[];
}

interface BookUploadFormProps {
  onUpload: (formData: FormData) => Promise<void>;
  onReset: () => void;
  authors: string[];
  seriesList: string[];
}

interface ErrorResponse {
  message?: string;
  status?: string;
}

interface BookItemWithMenuProps {
  book: IBook;
  onDelete: (event: React.MouseEvent<HTMLElement>, bookGuid: string) => void;
  onTranslate: (bookGuid: string) => void;
  onMarketing: (bookGuid: string) => void;
  onAudio: (bookGuid: string) => void;
  isMenuOpen: boolean;
  toggleMenu: (e: React.MouseEvent, bookGuid: string) => void;
}

// Memoize the BookItemWithMenu component to prevent unnecessary re-renders
const BookItemWithMenu = React.memo(({ 
  book, 
  onDelete, 
  onTranslate, 
  onMarketing, 
  onAudio, 
  isMenuOpen, 
  toggleMenu 
}: BookItemWithMenuProps) => {
  const handleMenuItemClick = useCallback((e: React.MouseEvent, action: 'translate' | 'delete' | 'marketing' | 'audio') => {
    e.stopPropagation();
    if (action === 'translate') {
      onTranslate(book.bookguid);
    } else if (action === 'delete') {
      onDelete({} as React.MouseEvent<HTMLElement>, book.bookguid);
    } else if (action === 'marketing') {
      onMarketing(book.bookguid);
    } else if (action === 'audio') {
      onAudio(book.bookguid);
    }
  }, [book.bookguid, onDelete, onTranslate, onMarketing, onAudio]);

  const bookTitle = book.title.length > 20 
    ? `${book.title.substring(0, 20)}...` 
    : book.title;

  // Create a function to get the appropriate file type icon
  const getFileTypeIcon = () => {
    if (!book.uploadType) return null;
    switch(book.uploadType.toLowerCase()) {
      case 'word':
        return <FileWordOutlined style={{ color: '#2b5797', fontSize: '16px' }} />;
      case 'epub':
        return <ReadOutlined style={{ color: '#6ba54a', fontSize: '16px' }} />;
      case 'pdf':
        return <FilePdfOutlined style={{ color: '#e24329', fontSize: '16px' }} />;
      default:
        return <BookOutlined style={{ color: '#8c8c8c', fontSize: '16px' }} />;
    }
  };

  return (
    <BookItem className="book-item">
      <BookCover coverUrl={book.coverUrl} onClick={() => onTranslate(book.bookguid)}>
        <BookTitle coverUrl={book.coverUrl}>{book.title}</BookTitle>

        {/* File type badge */}
        {book.uploadType && (
		  <Tooltip title={book.uploadType === 'Word' ? 'Word' : 'ePub'}>
            <FileTypeBadge>
              {getFileTypeIcon()}
            </FileTypeBadge>
          </Tooltip>
        )}

        <MenuButton onClick={(e) => toggleMenu(e, book.bookguid)}>
          <EllipsisOutlined style={{ fontSize: '16px' }} />
        </MenuButton>
      </BookCover>
      <BookText>{bookTitle}</BookText>

      {isMenuOpen && (
        <BookMenu>
          {/*
		  <MenuOption onClick={(e) => handleMenuItemClick(e, 'translate')}>
            <TranslationOutlined /> Translations
          </MenuOption>          
          <MenuOption onClick={(e) => handleMenuItemClick(e, 'marketing')}>
            <ShoppingCartOutlined /> Marketing
          </MenuOption>
          <MenuOption onClick={(e) => handleMenuItemClick(e, 'audio')}>
            <AudioOutlined /> AudioBooks
          </MenuOption>
          */}
          <MenuOption onClick={(e) => handleMenuItemClick(e, 'delete')}>
            <DeleteOutlined /> Delete
          </MenuOption>
        </BookMenu>
      )}
    </BookItem>
  );
});

// Main component
const BookManagementTab: React.FC<{ user: User | null }> = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bookDetails, setBookDetails] = useState<IBook[]>([]);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const navigate = useNavigate();
  const [authorSeriesGroups, setAuthorSeriesGroups] = useState<AuthorSeriesGroup[]>([]);
  const [uniqueAuthors, setUniqueAuthors] = useState<string[]>([]);
  const [uniqueSeries, setUniqueSeries] = useState<string[]>([]);  
  const pageDisabled = false;
  const disabledMessage = "After a lot of feedback, we're making improvements. Check back soon! -Team ScribeShadow";
  const [openMenuBookGuid, setOpenMenuBookGuid] = useState<string | null>(null);
  const [selectedAuthor, setSelectedAuthor] = useState<string | null>(null);
  const [selectedSeries, setSelectedSeries] = useState<string | null>(null);
  const [filteredAuthors, setFilteredAuthors] = useState<string[]>([]);
  const [filteredSeries, setFilteredSeries] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [expandedAuthors, setExpandedAuthors] = useState<Record<string, boolean>>({});
  const [expandedSeries, setExpandedSeries] = useState<Record<string, boolean>>({});

  // Memoize handlers to prevent recreation on each render
  const handleMarketingClick = useCallback((bookGuid: string) => {
     navigate(`/marketing/${bookGuid}`);
  }, [navigate]); 

  const handleTranslateClick = useCallback((bookGuid: string) => {
     navigate(`/book-detail/${bookGuid}`);
  }, [navigate]);  

  const handleAudiobookClick = useCallback((bookGuid: string) => {
     navigate(`/audio/${bookGuid}`);
  }, [navigate]);  

  const toggleMenu = useCallback((e: React.MouseEvent, bookGuid: string) => {
    e.stopPropagation();
    setOpenMenuBookGuid(prevGuid => prevGuid === bookGuid ? null : bookGuid);
  }, []);

  const toggleAuthorExpansion = useCallback((author: string) => {
    setExpandedAuthors(prev => ({
      ...prev,
      [author]: !prev[author]
    }));
  }, []);

  const toggleSeriesExpansion = useCallback((author: string, series: string) => {
    const key = `${author}-${series}`;
    setExpandedSeries(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  }, []);

  useEffect(() => {
    // Initialize expansion states for authors and series
    if (authorSeriesGroups.length > 0) {
      const authorsExpanded: Record<string, boolean> = {};
      const seriesExpanded: Record<string, boolean> = {};
      
      authorSeriesGroups.forEach(group => {
        // Default all authors to expanded
        authorsExpanded[group.author] = true;
        
        group.series.forEach(serie => {
          // Default all series to expanded
          seriesExpanded[`${group.author}-${serie.name}`] = true;
        });
      });
      
      setExpandedAuthors(authorsExpanded);
      setExpandedSeries(seriesExpanded);
    }
  }, [authorSeriesGroups]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (openMenuBookGuid && !(event.target as Element).closest('.book-item')) {
        setOpenMenuBookGuid(null);
      }
    };

    const handleClickOutsideWrapper = (event: Event) => {
      handleClickOutside(event as unknown as MouseEvent);
    };

    document.addEventListener('click', handleClickOutsideWrapper);

    return () => {
      document.removeEventListener('click', handleClickOutsideWrapper);
    };
  }, [openMenuBookGuid]);
  
  const handleModalOpen = useCallback(() => {
    setIsUploadModalVisible(true);
    setFormKey(prevKey => prevKey + 1);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsUploadModalVisible(false);
    setFormKey(prevKey => prevKey + 1);
  }, []);

  const fetchBooks = useCallback(async () => {
    setIsLoading(true);
    setBookDetails([]);
    if (!user) {
      setBookDetails([]);
      console.error("User is not authenticated");
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/user/${user.uid}`);
      setBookDetails(response.data);

      const fetchedAuthors = new Set<string>();
      const fetchedSeries = new Set<string>();
      response.data.forEach((book: IBook) => {
        fetchedAuthors.add(book.author);
        if (book.series) {
          fetchedSeries.add(book.series);
        }
      });
      setUniqueAuthors(Array.from(fetchedAuthors));
      setUniqueSeries(Array.from(fetchedSeries));
    } catch (error) {
      console.error("Error fetching books: ", (error as AxiosError).response?.data || (error as AxiosError).message);
      message.error('Failed to load your books. Please try again.');
    }
    setIsLoading(false);
  }, [user]);

  // Memoize the expensive groupBooks function
  const groupBooks = useCallback((books: IBook[], query: string) => {
    // Filter books by search query if present
    const filteredBooks = query 
      ? books.filter(book => 
          book.title.toLowerCase().includes(query.toLowerCase()) ||
          book.author.toLowerCase().includes(query.toLowerCase()) ||
          (book.series && book.series.toLowerCase().includes(query.toLowerCase()))
        )
      : books;
      
    // Sort the books array
    const sortedBooks = [...filteredBooks].sort((a, b) => {
      // First sort by author
      const authorCompare = (a.author || '').localeCompare(b.author || '');
      if (authorCompare !== 0) return authorCompare;

      // Then sort by series name
      const seriesA = a.series || 'No Series';
      const seriesB = b.series || 'No Series';
      const seriesCompare = seriesA.localeCompare(seriesB);
      if (seriesCompare !== 0) return seriesCompare;

      // Then sort by series number
      const numberA = a.seriesBookNumber || 0;
      const numberB = b.seriesBookNumber || 0;
      if (numberA !== numberB) return numberA - numberB;

      // Finally, sort alphabetically by title if series numbers are the same
      return (a.title || '').localeCompare(b.title || '');
    });

    const grouped = sortedBooks.reduce((acc: AuthorSeriesGroup[], book: IBook) => {
      const bookSeries = book.series || "No Series";
      const authorIndex = acc.findIndex(g => g.author === book.author);

      if (authorIndex > -1) {
        const seriesIndex = acc[authorIndex].series.findIndex(s => s.name === bookSeries);

        if (seriesIndex > -1) {
          acc[authorIndex].series[seriesIndex].books.push(book);
        } else {
          // Insert new series in alphabetical order
          const newSeries = { name: bookSeries, books: [book] };
          const insertIndex = acc[authorIndex].series.findIndex(s => s.name.localeCompare(bookSeries) > 0);
          if (insertIndex === -1) {
            acc[authorIndex].series.push(newSeries);
          } else {
            acc[authorIndex].series.splice(insertIndex, 0, newSeries);
          }
        }
      } else {
        // Insert new author in alphabetical order
        const newAuthorGroup = {
          author: book.author,
          series: [{ name: bookSeries, books: [book] }]
        };
        const insertIndex = acc.findIndex(g => g.author.localeCompare(book.author) > 0);
        if (insertIndex === -1) {
          acc.push(newAuthorGroup);
        } else {
          acc.splice(insertIndex, 0, newAuthorGroup);
        }
      }
      return acc;
    }, []);

    // Sort books within each series
    grouped.forEach(group => {
      group.series.forEach(serie => {
        serie.books.sort((a, b) => {
          const numberA = a.seriesBookNumber || 0;
          const numberB = b.seriesBookNumber || 0;
          if (numberA !== numberB) return numberA - numberB;
          return (a.title || '').localeCompare(b.title || '');
        });
      });
    });

    return grouped;
  }, []);
  
  // Use useMemo to only recalculate when dependencies change
  useEffect(() => {
    if (bookDetails.length > 0) {
      const groupedBooks = groupBooks(bookDetails, searchQuery);
      setAuthorSeriesGroups(groupedBooks);
    }
  }, [bookDetails, searchQuery, groupBooks]);  
 
  useEffect(() => {
    if (!user) {
      // Clear bookDetails if there is no user logged in
      setBookDetails([]);
      setAuthorSeriesGroups([]);
      setUniqueAuthors([]);
      setUniqueSeries([]);
      setIsLoading(false);
    } else {
      fetchBooks();
    }
  }, [user, fetchBooks]);  

  const deleteBook = useCallback(async (event: React.MouseEvent<HTMLElement> | undefined, bookGuid: string) => {
    if (event && event.stopPropagation) {
      event.stopPropagation(); // Only call if the event exists and has this method
    }
    Modal.confirm({
      title: 'Are you sure you want to delete this book?',
      content: 'This action cannot be undone.',
      okText: 'Yes, Delete',
      okButtonProps: { danger: true },
      cancelText: 'Cancel',
      onOk: async () => {
        if (!user) {
          console.error("User is not authenticated");
          return;
        }

        try {
          setIsLoading(true);
          await axios.delete(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/${bookGuid}`, {
            data: { user_id: user.uid }
          });

          // Update both bookDetails and authorSeriesGroups
          const updatedBookDetails = bookDetails.filter(book => book.bookguid !== bookGuid);
          setBookDetails(updatedBookDetails);
          const updatedGroups = groupBooks(updatedBookDetails, searchQuery);
          setAuthorSeriesGroups(updatedGroups);
          message.success('Book deleted successfully');
        } catch (error) {
          console.error("Error deleting book: ", error);
          message.error('Failed to delete book');
        } finally {
          setIsLoading(false);
        }
      },
    });
  }, [user, bookDetails, groupBooks, searchQuery]);

  const handleUpload = useCallback(async (formData: FormData) => {
    if (!user) {
      console.error("User is not authenticated");
      throw new Error("User is not authenticated");
    }

    setIsLoading(true);
    formData.append('user_id', user.uid);

    try {
      console.log("Uploading Book");
      const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/upload-book/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.data.status === 'error') {
        throw new Error(response.data.message);
      }

      // Close the modal
      handleModalClose();

      // Navigate to the book detail page using the bookguid
      const newBookGuid = response.data.book_id;
      navigate(`/book-detail/${newBookGuid}`);
      
    } catch (error) {
      console.error("Error uploading file: ", error);
      throw error; // Propagate the error to be caught in BookUploadForm
    } finally {
      setIsLoading(false);
    }
  }, [user, navigate, handleModalClose]);

  //functions for filtering
  useEffect(() => {
    if (bookDetails.length > 0) {
      const authors = new Set<string>();
      const series = new Set<string>();

      bookDetails.forEach((book) => {
        authors.add(book.author);
        if (book.series) {
          series.add(book.series);
        }
      });

      setUniqueAuthors(Array.from(authors));
      setUniqueSeries(Array.from(series));
    }
  }, [bookDetails]);

  useEffect(() => {
    if (selectedAuthor) {
      const filteredSeries = bookDetails
        .filter((book) => book.author === selectedAuthor)
        .map((book) => book.series)
        .filter((series): series is string => !!series);
      setFilteredSeries(Array.from(new Set(filteredSeries)));
    } else {
      setFilteredSeries(uniqueSeries);
    }

    if (selectedSeries) {
      const filteredAuthors = bookDetails
        .filter((book) => book.series === selectedSeries)
        .map((book) => book.author);
      setFilteredAuthors(Array.from(new Set(filteredAuthors)));
    } else {
      setFilteredAuthors(uniqueAuthors);
    }
  }, [selectedAuthor, selectedSeries, bookDetails, uniqueAuthors, uniqueSeries]);

  const handleAuthorChange = useCallback((value: string | null) => {
    setSelectedAuthor(value);
    if (!value) {
      setSelectedSeries(null);
    }
  }, []);

  const handleSeriesChange = useCallback((value: string | null) => {
    setSelectedSeries(value);
    if (!value) {
      setSelectedAuthor(null);
    }
  }, []);

  const clearFilters = useCallback(() => {
    setSelectedAuthor(null);
    setSelectedSeries(null);
    setSearchQuery('');
  }, []);

  // Memoize filteredGroups to prevent recalculation on every render
  const filteredGroups = useMemo(() => {
    return authorSeriesGroups.filter(group => {
      if (selectedAuthor && group.author !== selectedAuthor) {
        return false;
      }
      if (selectedSeries) {
        return group.series.some(serie => serie.name === selectedSeries);
      }
      return true;
    });
  }, [authorSeriesGroups, selectedAuthor, selectedSeries]);

  return (
    <Container>
      <StyledCard>
        {isLoading && (
          <LoadingContainer>
            <Spin size="large" tip="Loading your books..." />
          </LoadingContainer>
        )}
        
        {!isLoading && user && !user?.emailVerified ? (
          <EmptyState>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Please verify your email to access this page."
            />
            <Button type="primary">Resend Verification Email</Button>
          </EmptyState>
        ) : (
          <>    
          {!isLoading && pageDisabled ? (
            <EmptyState>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={disabledMessage}
              />
            </EmptyState>
          ) : (
            <>
            {!isLoading && user ? (
              <>
                <HeaderContainer>
                  <Header>
                    My Books Library
                  </Header>
                  <Tooltip title="Upload a new book">
                    <Button 
                      type="primary" 
                      icon={<UploadOutlined />} 
                      onClick={handleModalOpen}
                      size="large"
                    >
                      Upload Book
                    </Button>
                  </Tooltip>
                </HeaderContainer>
                
                <FilterContainer>
                  <FilterSection>
                    <FilterOutlined style={{ color: '#8c8c8c' }} />
                    <Select
                      style={{ width: 180 }}
                      placeholder="Filter by Author"
                      onChange={handleAuthorChange}
                      value={selectedAuthor}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                    >
                      {filteredAuthors.sort((a, b) => a.localeCompare(b)).map(author => (
                        <Option key={author} value={author}>{author}</Option>
                      ))}
                    </Select>
                  </FilterSection>
                  
                  <FilterSection>
                    <Select
                      style={{ width: 180 }}
                      placeholder="Filter by Series"
                      onChange={handleSeriesChange}
                      value={selectedSeries}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                    >
                      {filteredSeries.sort((a, b) => a.localeCompare(b)).map(series => (
                        <Option key={series} value={series}>{series}</Option>
                      ))}
                    </Select>
                  </FilterSection>
                  
                  <FilterSection>
                    <Input.Search
                      placeholder="Search books..."
                      allowClear
                      style={{ width: 220 }}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      prefix={<SearchOutlined style={{ color: '#8c8c8c' }} />}
                    />
                  </FilterSection>
                  
                  {(selectedAuthor || selectedSeries || searchQuery) && (
                    <Button 
                      icon={<ClearOutlined />} 
                      onClick={clearFilters}
                    >
                      Clear Filters
                    </Button>
                  )}
                </FilterContainer>
              
                <Modal 
                  title={<ModalTitle>Upload Book</ModalTitle>} 
                  visible={isUploadModalVisible} 
                  onCancel={handleModalClose} 
                  footer={null}
                  width={600}
                  centered
                  destroyOnClose
                >
                  <BookUploadForm
                    key={formKey}
                    onUpload={handleUpload}
                    onReset={handleModalClose}
                    authors={uniqueAuthors}
                    seriesList={uniqueSeries}
                  />
                </Modal>
                
                {filteredGroups.length === 0 ? (
                  <EmptyState>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_DEFAULT}
                      description={
                        bookDetails.length === 0 
                          ? "You don't have any books yet. Start by uploading your first book!" 
                          : "No books match your current filters."
                      }
                    />
                    {bookDetails.length === 0 && (
                      <Button 
                        type="primary" 
                        icon={<UploadOutlined />} 
                        onClick={handleModalOpen}
                        size="large"
                        style={{ marginTop: '16px' }}
                      >
                        Upload Your First Book
                      </Button>
                    )}
                  </EmptyState>
                ) : (
                  <>
                    {filteredGroups.map(group => (
                      <AuthorBooksContainer key={group.author}>
                        <AuthorHeader onClick={() => toggleAuthorExpansion(group.author)}>
                          <CollapseIcon>
                            {expandedAuthors[group.author] 
                              ? <CaretDownOutlined /> 
                              : <CaretRightOutlined />
                            }
                          </CollapseIcon>
                          
                          {group.author}
                          <BookCountBadge>
                            {group.series.reduce((count, series) => count + series.books.length, 0)} books
                          </BookCountBadge>
                        </AuthorHeader>
                        
                        {expandedAuthors[group.author] && group.series
                          .filter(serie => !selectedSeries || serie.name === selectedSeries)
                          .map(serie => {
                            const seriesKey = `${group.author}-${serie.name}`;
                            return (
                              <SeriesContent key={serie.name}>
                                <SeriesHeader onClick={() => toggleSeriesExpansion(group.author, serie.name)}>
                                  <CollapseIcon>
                                    {expandedSeries[seriesKey] 
                                      ? <CaretDownOutlined /> 
                                      : <CaretRightOutlined />
                                    }
                                  </CollapseIcon>
                                  
                                  {serie.name === "No Series" ? "Standalone Books" : serie.name}
                                  {serie.books.length > 1 && 
                                    <BookCountBadge>{serie.books.length} books</BookCountBadge>
                                  }
                                </SeriesHeader>
                                
                                {expandedSeries[seriesKey] && (
                                  <BookGrid>
                                    {serie.books.map(book => (
                                      <BookItemWithMenu
                                        key={book.bookguid}
                                        book={book}
                                        onDelete={deleteBook}
                                        onMarketing={handleMarketingClick}
                                        onAudio={handleAudiobookClick}
                                        onTranslate={handleTranslateClick}
                                        isMenuOpen={openMenuBookGuid === book.bookguid}
                                        toggleMenu={toggleMenu}
                                      />
                                    ))}
                                  </BookGrid>
                                )}
                              </SeriesContent>
                            );
                          })}
                      </AuthorBooksContainer>
                    ))}
                  </>
                )}
              </>
            ) : (
              <>
                {!isLoading && !user && (
                  <EmptyState>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_DEFAULT}
                      description="Please click Get Started to log in and view your books."
                    />
                  </EmptyState>
                )}
              </>
            )}
            </>
          )}  
          </>
        )}      
      </StyledCard>
    </Container>
  );
};

export default BookManagementTab;