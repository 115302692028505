//ProofreadModal.tsx
import React from 'react';
import { Modal, Button, Select, Checkbox, Tooltip, Input, Tag } from 'antd';
import Alert from 'antd/es/alert';
import { QuestionCircleOutlined, CheckCircleOutlined, DownOutlined, WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons';


interface ProofreadModalProps {
  isVisible: boolean;
  onCancel: () => void;
  onProofread: () => void;
  selectedChapter: any;
  proofreadVersion: string;
  setProofreadVersion: (value: string) => void;
  isProofreadChecked: boolean;
  setIsProofreadChecked: (value: boolean) => void;
  isEditingNotesChecked: boolean;
  setIsEditingNotesChecked: (value: boolean) => void;
  quotationStyle: string;
  setQuotationStyle: (value: string) => void;
  proofreadAdditionalInstructions: string;
  setProofreadAdditionalInstructions: (value: string) => void;  
  selectedAIModel: string;
  setSelectedAIModel: (value: string) => void;
  creditBalance: { credit_balance: number } | null;
  calculateAdjustedCost: () => number;
  userHasEnoughCredits: boolean;
  hasUnproofreadChapters: boolean;
  hasUnproofreadNotesChapters: boolean;
  canChapterBeProofread: boolean;
  hasChapterBeenProofread: boolean;
  hasChapterBeenEditNotes: boolean;
  hasUntranslatedChaptersForProofread: boolean;
  book: any;
  formatLangDisplayText: (pair: string) => string;
  bookLanguage: string;
}

const ProofreadModal: React.FC<ProofreadModalProps> = ({
  isVisible,
  onCancel,
  onProofread,
  selectedChapter,
  proofreadVersion,
  setProofreadVersion,
  isProofreadChecked,
  setIsProofreadChecked,
  isEditingNotesChecked,
  setIsEditingNotesChecked,
  quotationStyle,
  setQuotationStyle,
  proofreadAdditionalInstructions,
  setProofreadAdditionalInstructions,  
  selectedAIModel,
  setSelectedAIModel,
  creditBalance,
  calculateAdjustedCost,
  userHasEnoughCredits,
  hasUnproofreadChapters,
  hasUnproofreadNotesChapters,
  canChapterBeProofread,
  hasChapterBeenProofread,
  hasChapterBeenEditNotes,
  hasUntranslatedChaptersForProofread,
  book,
  formatLangDisplayText,
  bookLanguage
}) => {
	
  const hasEnoughCredits = creditBalance && creditBalance.credit_balance >= calculateAdjustedCost();

  // Group status messages to display
  const getStatusMessages = () => {
    const messages = [];
    
    // Warning messages (orange)
    if (selectedChapter && isProofreadChecked && hasChapterBeenProofread) {
      messages.push({
        type: 'warning',
        message: 'This chapter already has a proofread version and will be overwritten.',
        icon: <WarningOutlined />
      });
    }
    
    if (selectedChapter && isEditingNotesChecked && hasChapterBeenEditNotes) {
      messages.push({
        type: 'warning',
        message: 'This chapter already has editing notes.',
        icon: <WarningOutlined />
      });
    }
    
    // Error messages (red)
    if (!hasEnoughCredits) {
      messages.push({
        type: 'error',
        message: 'You do not have enough credits for this proofreading.',
        icon: <ExclamationCircleOutlined />
      });
    }
    
    if (!hasUnproofreadChapters && isProofreadChecked && !selectedChapter) {
      messages.push({
        type: 'error',
        message: 'All chapters have been proofread.',
        icon: <ExclamationCircleOutlined />
      });
    }
    
    if (!hasUnproofreadNotesChapters && isEditingNotesChecked && !selectedChapter) {
      messages.push({
        type: 'error',
        message: 'All chapters have editing notes.',
        icon: <ExclamationCircleOutlined />
      });
    }
    
    if (!selectedChapter && proofreadVersion && hasUntranslatedChaptersForProofread) {
      messages.push({
        type: 'error',
        message: 'Unable to proofread this language. Not all chapters have been translated to the target language.',
        icon: <ExclamationCircleOutlined />
      });
    }
    
    if (selectedChapter && proofreadVersion && !canChapterBeProofread) {
      messages.push({
        type: 'error',
        message: 'Unable to proofread. This chapter has not been translated to the specified language.',
        icon: <ExclamationCircleOutlined />
      });
    }
    
    if (!proofreadVersion) {
      messages.push({
        type: 'error',
        message: 'Please select a version to proofread to proceed.',
        icon: <ExclamationCircleOutlined />
      });
    }
    
    // Success messages (green)
    if (!selectedChapter && isProofreadChecked && hasUnproofreadChapters) {
      messages.push({
        type: 'success',
        message: 'ScribeShadow will only proofread chapters that have not yet been proofread.',
        icon: <CheckCircleOutlined />
      });
    }
    
    if (!selectedChapter && isEditingNotesChecked && hasUnproofreadChapters) {
      messages.push({
        type: 'success',
        message: 'ScribeShadow will only provide editing notes for chapters that do not yet have editing notes.',
        icon: <CheckCircleOutlined />
      });
    }
    
    return messages;
  };
	
return (
  <Modal
    title={selectedChapter ? "Proofread Chapter" : "Proofread Book"}
    visible={isVisible}
    onCancel={onCancel}
    footer={[
      <Button key="cancel" onClick={onCancel}>Cancel</Button>,
      <Button 
        key="proofread" 
        type="primary" 
        onClick={onProofread} 
        disabled={
          (!hasUnproofreadChapters && isProofreadChecked && !selectedChapter) ||
          (!hasUnproofreadNotesChapters && isEditingNotesChecked && !selectedChapter) ||
          !hasEnoughCredits ||
          (!isProofreadChecked && !isEditingNotesChecked) ||
          calculateAdjustedCost() === 0 ||
          (selectedChapter && !canChapterBeProofread) ||
          (!selectedChapter && hasUntranslatedChaptersForProofread)
        }
      >
        Proofread
      </Button>
    ]}
	width={700}
  >
    <div>
      
      <div style={{ marginBottom: '15px' }}>
        <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
          <strong>Version to Proofread:</strong>
        </label>
        <Select
          value={proofreadVersion}
          onChange={value => {
            setProofreadVersion(value);
            if (value.includes('-German')) {
              setQuotationStyle('german guillemets');
            }
          }}
          style={{ width: '100%' }}
          suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
        >
          <Select.Option value="Original">Original</Select.Option>
          {book.translation_pairs && book.translation_pairs.map((pair: string) => (
            <Select.Option key={pair} value={pair}>{formatLangDisplayText(pair)}</Select.Option>
          ))}
        </Select>
      </div>

      <div style={{ 
        background: '#f7f7f7', 
        padding: '15px', 
        borderRadius: '6px', 
        marginBottom: '15px' 
      }}>
        <div style={{ marginBottom: '10px' }}>
          <Checkbox 
            checked={isProofreadChecked} 
            onChange={e => setIsProofreadChecked(e.target.checked)} 
            disabled={selectedChapter ? !canChapterBeProofread : undefined}
          >
            <span style={{ fontWeight: '500' }}>
              {selectedChapter ? "Proofread this Chapter" : "Proofread Entire Book"}
            </span>
          </Checkbox>
          <Tooltip title="Select this option to proofread the chapter or book, improving its language and readability.">
            <QuestionCircleOutlined style={{ color: '#1677ff', marginLeft: 8 }} />
          </Tooltip>
        </div>

        <div>
          <Checkbox 
            checked={isEditingNotesChecked} 
            onChange={e => setIsEditingNotesChecked(e.target.checked)} 
            disabled={selectedChapter ? !canChapterBeProofread : undefined}
          >
            <span style={{ fontWeight: '500' }}>
              {selectedChapter ? "Get Editing Notes for this Chapter" : "Get Editing Notes for Entire Book"}
            </span>
          </Checkbox>
          <Tooltip title="Select this option if you want to receive editing notes, providing insights and suggestions for further improvements.">
            <QuestionCircleOutlined style={{ color: '#1677ff', marginLeft: 8 }} />
          </Tooltip>
        </div>
      </div>

        {/* Combined AI Model and Quotation Style in a row */}
        <div style={{ 
          display: 'flex', 
          gap: '15px', 
          marginBottom: '15px' 
        }}>
          {/* Left column - AI Model */}
          <div style={{ flex: 1 }}>
            <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
              <strong>AI Model:</strong>
            </label>
            <Select
              value={selectedAIModel}
              onChange={(value) => setSelectedAIModel(value)}
              style={{ width: '100%' }}
              suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
            >
              <Select.Option value="Claude3.7">
                Claude 3.7 <Tag color="geekblue" style={{ marginLeft: '8px', position: 'relative', top: '-0.5px' }}>Recommended </Tag>
              </Select.Option>
              <Select.Option value="Claude">Claude 3.5</Select.Option>
              <Select.Option value="Chat-GPT">Chat-GPT</Select.Option>	
            </Select>
          </div>
          
          {/* Right column - Quotation Style */}
          <div style={{ flex: 1 }}>
            <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
              <strong>Quotation Style:</strong>
            </label>
            <Select
              value={quotationStyle}
              onChange={value => setQuotationStyle(value)}
              style={{ width: '100%' }}
              suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
              disabled={!(proofreadVersion.includes('-German') || (proofreadVersion === 'Original' && bookLanguage === 'German'))}
            >
              <Select.Option value="german quotation marks">German Quotation Marks („Dialog Text")</Select.Option>
              <Select.Option value="german guillemets">Guillemets (»Dialog Text«)</Select.Option>
            </Select>
            {!(proofreadVersion.includes('-German') || (proofreadVersion === 'Original' && bookLanguage === 'German')) && (
              <div style={{ color: '#999', fontSize: '12px', marginTop: '5px' }}>
                Only available for German language
              </div>
            )}
          </div>
        </div>
      
      {isProofreadChecked && (
        <div style={{ marginBottom: '15px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
            <label style={{ marginRight: '5px' }}><strong>Additional Instructions (optional):</strong></label>
            <Tooltip title="You can provide specific instructions or preferences for the proofreading process here.">
              <QuestionCircleOutlined style={{ color: '#1677ff' }} />
            </Tooltip>
          </div>
          <Input.TextArea
            value={proofreadAdditionalInstructions}
            onChange={(e) => setProofreadAdditionalInstructions(e.target.value)}
            placeholder="Enter any additional instructions for proofreading"
            rows={3}
            style={{ width: '100%' }}
          />
        </div>
      )}
      
      <div style={{ 
        background: '#f0f7ff', 
        padding: '15px', 
        borderRadius: '6px', 
        marginBottom: '15px' 
      }}>
        <div style={{ marginBottom: '8px' }}>
          <strong>Credit Balance: </strong>
          <span>{creditBalance ? creditBalance.credit_balance : 'Loading...'}</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>Credit Cost: </strong>
          <span style={{ marginLeft: '5px', marginRight: '5px' }}>{calculateAdjustedCost()}</span>
          <Tooltip title="This value may differ from the word count of your document due to the inclusion of hidden tags and metadata necessary to maintain formatting integrity.">
            <QuestionCircleOutlined style={{ color: '#1677ff' }} />
          </Tooltip>
        </div>
      </div>
      
      {/* Status Messages */}
        <div style={{ marginTop: '12px' }}>
          {getStatusMessages().map((msg, index) => (
            <Alert
              key={index}
              message={msg.message}
              type={msg.type as any}
              icon={msg.icon}
              style={{ marginBottom: '8px' }}
              showIcon
              closable={false}
              banner={false}
            />
          ))}
        </div>
    </div>
  </Modal>
);
};

export default ProofreadModal;