import React, { useState, useEffect, useRef } from 'react';
import { Card, Badge, Button, Tabs, Tooltip, Empty, notification, Popconfirm, Modal, List, Tag } from 'antd';
import { SyncOutlined, ClockCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, 
         MinusCircleOutlined, DeleteOutlined, ClearOutlined, InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

const { TabPane } = Tabs;

// Updated interface to include type field for Translation or Proofreading
interface TranslationStatus {
  chapterGuid: string;
  chapterName: string;
  sourceLang: string;
  targetLang: string;
  timestamp: string;
  status: 'pending' | 'completed' | 'failed';
  bookId?: string;
  userId?: string;
  word_count?: number;
  estimatedTimeMinutes?: number;
  completionTime?: string;
  cleared?: boolean;
  lockGuid: string;
  error?: string;
  type: 'Translation' | 'Proofread' | 'EditingNotes';
}

interface TranslationStatusPanelProps {
  userId: string;
  bookId?: string;
  translations: TranslationStatus[];
  onRefresh: () => void;
}

const TranslationStatusPanel: React.FC<TranslationStatusPanelProps> = ({
  userId,
  bookId,
  translations: allTranslations,
  onRefresh
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('pending');
  const [clearingItem, setClearingItem] = useState<string | null>(null);
  const [clearAllModalVisible, setClearAllModalVisible] = useState<'completed' | 'failed' | null>(null);
  const prevTranslationsRef = useRef<TranslationStatus[]>([]);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [currentError, setCurrentError] = useState<string | undefined>(undefined);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const lastRefreshTimeRef = useRef<number>(0);

  // Handle refresh with throttling and loading indicator
  const handleRefresh = () => {
    const now = Date.now();
    const timeSinceLastRefresh = now - lastRefreshTimeRef.current;
    
    // Throttle to one refresh every 10 seconds
    if (timeSinceLastRefresh < 10000) {
      notification.info({
        message: 'Please wait',
        description: 'Refreshing too frequently, please wait a moment',
        placement: 'bottomRight',
        duration: 2
      });
      return;
    }
    
    // Set refreshing state to show visual indicator
    setIsRefreshing(true);
    lastRefreshTimeRef.current = now;
    
    // Call the original refresh function
    onRefresh();
    
    // Reset refreshing state after a short delay
    setTimeout(() => {
      setIsRefreshing(false);
    }, 1000);
  };

const formatTargetLang = (type: string, targetLang: string): string => {
  if ((type === 'Proofread' || type === 'EditingNotes') && targetLang.includes('-')) {
    // Get everything after the first hyphen
    const indexOfHyphen = targetLang.indexOf('-');
    return targetLang.substring(indexOfHyphen + 1);
  }
  return targetLang;
};

  // Function to show error details
  const showErrorDetails = (error: string | undefined) => {
    setCurrentError(error || 'No error details available');
    setErrorModalVisible(true);
  };

  useEffect(() => {
    if (allTranslations.length === 0) return;
    
    // Get the previous translation IDs
    const prevIds = new Set(prevTranslationsRef.current.map(t => t.lockGuid));
    
    // Check if there are any new translations (not in the previous set)
    const hasNewTranslation = allTranslations.some(t => !prevIds.has(t.lockGuid));
    
    // If there are new translations and the panel isn't already open, open it
    if (hasNewTranslation && !isVisible) {
      setIsVisible(true);
      setActiveTab('pending');
    }
    
    // Update the reference to the current translations
    prevTranslationsRef.current = [...allTranslations];
  }, [allTranslations, isVisible]);

// Helper function to parse UTC timestamps consistently
const parseUTCTimestamp = (dateString: string) => {
  if (!dateString) return new Date();
  // If the dateString doesn't end with 'Z', append it to indicate UTC
  if (!dateString.endsWith('Z')) {
    dateString = dateString + 'Z';
  }
  return new Date(dateString);
};

const formatRelativeTime = (dateString: string) => {
  const date = parseUTCTimestamp(dateString);
  const now = new Date();
  const diffMs = now.getTime() - date.getTime();
  const diffSec = Math.round(diffMs / 1000);
  const diffMin = Math.round(diffSec / 60);
  const diffHour = Math.round(diffMin / 60);
  const diffDay = Math.round(diffHour / 24);

  if (diffSec < 5) {
    return 'just now';
  } else if (diffSec < 60) {
    return `${diffSec} seconds ago`;
  } else if (diffMin < 60) {
    return `${diffMin} ${diffMin === 1 ? 'minute' : 'minutes'} ago`;
  } else if (diffHour < 24) {
    return `${diffHour} ${diffHour === 1 ? 'hour' : 'hours'} ago`;
  } else if (diffDay < 30) {
    return `${diffDay} ${diffDay === 1 ? 'day' : 'days'} ago`;
  } else {
    // Format the date for older entries, considering UTC
    return `on ${date.toLocaleDateString()}`;
  }
};

  // Format a future date relative to now (like "in 5 minutes")
  const formatFutureRelativeTime = (date: Date): string => {
    const now = new Date();
    const diffMs = date.getTime() - now.getTime();
    const diffSec = Math.round(diffMs / 1000);
    const diffMin = Math.round(diffSec / 60);
    const diffHour = Math.round(diffMin / 60);
    const diffDay = Math.round(diffHour / 24);

    if (diffSec < 0) {
      return 'in < 1 minute';
    } else if (diffMin < 60) {
      return `in ${diffMin} ${diffMin === 1 ? 'minute' : 'minutes'}`;
    } else if (diffHour < 24) {
      return `in ${diffHour} ${diffHour === 1 ? 'hour' : 'hours'}`;
    } else {
      return `in ${diffDay} ${diffDay === 1 ? 'day' : 'days'}`;
    }
  };

  const getTimeElapsed = (timestamp: string): string => {
    return formatRelativeTime(timestamp);
  };

const estimatedCompletion = (translation: TranslationStatus): string => {
  if (!translation.estimatedTimeMinutes || translation.estimatedTimeMinutes <= 0) 
    return 'in < 1 minute';
  
  // Just use the estimated minutes directly
  const minutes = translation.estimatedTimeMinutes;
  
  if (minutes < 1) {
    return 'in < 1 minute';
  } else if (minutes < 60) {
    return `in ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
  } else {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    
    if (remainingMinutes === 0) {
      return `in ${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    } else {
      return `in ${hours} ${hours === 1 ? 'hour' : 'hours'}, ${remainingMinutes} ${remainingMinutes === 1 ? 'minute' : 'minutes'}`;
    }
  }
};


const getTypeTag = (type: string) => {
  let color;
  if (type === 'Translation') color = 'green';
  else if (type === 'Proofread') color = 'blue';
  else if (type === 'EditingNotes') color = 'purple';
  else color = 'default';
  
  // Display "Editing Notes" when type is "EditingNotes"
  const displayText = type === 'EditingNotes' ? 'Editing Notes' : type;
  return <Tag color={color}>{displayText}</Tag>;
};

  // Clear a single translation entry
  const clearTranslation = async (lockGuid: string) => {
    try {
      setClearingItem(lockGuid);
      const clearResponse = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/translationlock/clear', {
        lockGuid,
      });
      
      console.log('Clear response:', clearResponse.data);
      
      // Then trigger a refresh to update from server
      onRefresh();
      
      // After refresh, log the new translations
      setTimeout(() => {
        console.log('Current translations after clear:', allTranslations);
      }, 1000);
    } catch (error) {
      console.error('Error clearing translation:', error);
      notification.error({
        message: 'Failed to clear',
        description: 'There was an error clearing the translation entry',
        placement: 'bottomRight'
      });
    } finally {
      setClearingItem(null);
    }
  };

  // Clear all translations of a specific status
  const clearAllTranslations = async (status: 'completed' | 'failed') => {
    try {
      const lockGuidsToDelete = filteredTranslations
        .filter(t => t.status === status && !t.cleared)
        .map(t => t.lockGuid);
      
      if (lockGuidsToDelete.length === 0) {
        notification.info({
          message: 'Nothing to clear',
          description: 'There are no entries to clear',
          placement: 'bottomRight'
        });
        return;
      }
      
      await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/translationlock/clear-all', {
        lockGuids: lockGuidsToDelete
      });
      
      // Immediately update local state to hide cleared items
      const updatedTranslations = [...allTranslations];
      lockGuidsToDelete.forEach(guid => {
        const index = updatedTranslations.findIndex(t => t.lockGuid === guid);
        if (index !== -1) {
          updatedTranslations[index] = { ...updatedTranslations[index], cleared: true };
        }
      });
      
      onRefresh();
    } catch (error) {
      console.error('Error clearing translations:', error);
      notification.error({
        message: 'Failed to clear',
        description: `There was an error clearing the ${status} notification entries`,
        placement: 'bottomRight'
      });
    } finally {
      setClearAllModalVisible(null);
    }
  };

  // Filter translations
  const filteredTranslations = bookId 
    ? allTranslations.filter(t => t.bookId === bookId)
    : allTranslations;

  // Organize into categories - exclude cleared items
  const pending = filteredTranslations.filter(t => t.status === 'pending');
  const completed = filteredTranslations
    .filter(t => t.status === 'completed' && !t.cleared)
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  const failed = filteredTranslations
    .filter(t => t.status === 'failed' && !t.cleared)
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

  const totalCount = pending.length + completed.length + failed.length;
  
  // Get counts by type for the minimized button
  const pendingTranslations = pending.filter(t => t.type === 'Translation').length;
  const pendingProofreading = pending.filter(t => t.type === 'Proofread').length;
  const pendingEditingNotes = pending.filter(t => t.type === 'EditingNotes').length;

  // Common empty state style to ensure consistent height
  const emptyStateStyle = {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    height: 325
  };

  // Common content container style to ensure consistent height
  const contentContainerStyle = {
    height: 325,
    overflowY: 'auto' as const,
    width: '100%'
  };

  return (
    <>
      {isVisible && (
        <Card
          title={
            <div style={{ 
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              position: 'relative'
            }}>
              Processing Status
              <Button 
                type="text" 
                onClick={() => setIsVisible(false)}
                style={{
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)'
                }}
              >
                X
              </Button>
            </div>
          }
          style={{ 
            position: 'fixed', 
            bottom: 20, 
            right: 20, 
            width: 450, 
            zIndex: 1000,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
          }}
          bodyStyle={{ padding: '12px' }}
        >
          <div style={{ minHeight: 385 }}> {/* Fixed overall height container */}
            <Tabs 
              activeKey={activeTab} 
              onChange={setActiveTab}
              centered
              style={{ width: '100%' }}
            >
              <TabPane 
                tab={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <SyncOutlined spin={pending.length > 0} style={{ marginRight: '8px' }} /> 
                    In-Process ({pending.length})
                  </span>
                } 
                key="pending"
              >
                <div style={contentContainerStyle}>
                  {pending.length === 0 ? (
                    <div style={emptyStateStyle}>
                      <Empty description="No pending tasks" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  ) : (
                    pending.map((translation, index) => (
                      <Card.Grid key={index} style={{ width: '100%', padding: '12px', boxShadow: 'none', borderBottom: '1px solid #f0f0f0' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                          <Tooltip title={`From ${translation.sourceLang} to ${formatTargetLang(translation.type, translation.targetLang)}`}>
                            <span style={{ fontWeight: 500 }}>{translation.chapterName}</span>
                          </Tooltip>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Badge status="processing" text="In Progress" />                          
                          </div>
                        </div>
                        <div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>
							{getTypeTag(translation.type)}<small>{formatTargetLang(translation.type, translation.targetLang)}</small>
                            </span>
                          </div>
                          <div style={{ marginTop: 8, display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ fontSize: '12px', color: '#666' }}>
                              <ClockCircleOutlined /> Started {getTimeElapsed(translation.timestamp)}
                            </div>
                            <div style={{ fontSize: '12px', color: '#1890ff' }}>
                              <Tooltip title="Estimated completion time">
                                Est. {estimatedCompletion(translation)}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </Card.Grid>
                    ))
                  )}
                </div>
              </TabPane>
              
              <TabPane 
                tab={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <CheckCircleOutlined style={{ marginRight: '8px' }} /> 
                    Completed ({completed.length})
                  </span>
                } 
                key="completed"
              >
                <div style={contentContainerStyle}>
                  {completed.length === 0 ? (
                    <div style={emptyStateStyle}>
                      <Empty description="No completed tasks" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  ) : (
                    completed.map((translation, index) => (
                      <Card.Grid key={index} style={{ width: '100%', padding: '12px', boxShadow: 'none', borderBottom: '1px solid #f0f0f0' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                          <Tooltip title={`From ${translation.sourceLang} to ${translation.targetLang}`}>
                            <span style={{ fontWeight: 500 }}>{translation.chapterName}</span>
                          </Tooltip>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Badge status="success" text="Completed" />
                          </div>
                        </div>
                        <div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>
                              {getTypeTag(translation.type)}<small>{formatTargetLang(translation.type, translation.targetLang)}</small>
                            </span>
                            <Popconfirm
                              title="Remove this notification?"
                              description="This only removes the entry from your status list. Your processed content is already saved and will not be affected."
                              onConfirm={() => clearTranslation(translation.lockGuid)}
                              okText="Remove"
                              cancelText="Cancel"
                            >
                              <Button 
                                type="text" 
                                icon={<DeleteOutlined />} 
                                size="small"
                                loading={clearingItem === translation.lockGuid}
                              />
                            </Popconfirm>
                          </div>
                          <div style={{ marginTop: 8, display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ fontSize: '12px', color: '#52c41a' }}>
                              Completed {getTimeElapsed(translation.completionTime || translation.timestamp)}
                            </div>
{translation.completionTime && (
  <div style={{ fontSize: '12px', color: '#666' }}>
    Took {(() => {
      const startTime = parseUTCTimestamp(translation.timestamp).getTime();
      const endTime = parseUTCTimestamp(translation.completionTime).getTime();
      const durationMinutes = Math.round((endTime - startTime) / (1000 * 60));
      return durationMinutes <= 0 ? '<1' : durationMinutes;
    })()} {(() => {
      const startTime = parseUTCTimestamp(translation.timestamp).getTime();
      const endTime = parseUTCTimestamp(translation.completionTime).getTime();
      const durationMinutes = Math.round((endTime - startTime) / (1000 * 60));
      return durationMinutes === 1 ? 'minute' : 'minutes';
    })()}
  </div>
)}
                          </div>
                        </div>
                      </Card.Grid>
                    ))
                  )}
                </div>
              </TabPane>
              
              <TabPane 
                tab={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <CloseCircleOutlined style={{ marginRight: '8px' }} /> 
                   Failed ({failed.length})
                  </span>
                } 
                key="failed"
              >
                <div style={contentContainerStyle}>
                  {failed.length === 0 ? (
                    <div style={emptyStateStyle}>
                      <Empty description="No failed tasks" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  ) : (
                    failed.map((translation, index) => (
                      <Card.Grid key={index} style={{ width: '100%', padding: '12px', boxShadow: 'none', borderBottom: '1px solid #f0f0f0' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                          <Tooltip title={`From ${translation.sourceLang} to ${translation.targetLang}`}>
                            <span style={{ fontWeight: 500 }}>{translation.chapterName}</span>
                          </Tooltip>
                          <Badge status="error" text="Failed" />
                        </div>
                        <div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>
                              {getTypeTag(translation.type)}<small>{formatTargetLang(translation.type, translation.targetLang)}</small>
                            </span>
                            <Popconfirm
                              title="Remove this notification?"
                              description="This only removes the entry from your status list. No content will be deleted."
                              onConfirm={() => clearTranslation(translation.lockGuid)}
                              okText="Remove"
                              cancelText="Cancel"
                            >
                              <Button 
                                type="text" 
                                icon={<DeleteOutlined />} 
                                size="small"
                                loading={clearingItem === translation.lockGuid}
                              />
                            </Popconfirm>
                          </div>
                          <div style={{ marginTop: 8, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontSize: '12px', color: '#f5222d' }}>
                              Failed {getTimeElapsed(translation.timestamp)}
                            </div>
                            <Button
                              type="link"
                              size="small"
                              onClick={() => showErrorDetails(translation.error)}
                              icon={<InfoCircleOutlined />}
                              style={{ fontSize: '12px', padding: '0', height: 'auto', marginLeft: '8px' }}
                            >
                              View Error
                            </Button>
                          </div>
                        </div>
                      </Card.Grid>
                    ))
                  )}
                </div>
              </TabPane>
            </Tabs>
            
            
<div style={{ marginTop: 10, textAlign: 'center', minHeight: '28px' }}> {/* Added minHeight for consistency */}
  {activeTab === 'pending' && (
    <Button 
      size="small" 
      type="link" 
      onClick={handleRefresh}  
      icon={<SyncOutlined spin={isRefreshing} />}  
      disabled={isRefreshing}  
    >
      {isRefreshing ? 'Refreshing...' : 'Refresh'}  
    </Button>
  )}              
  {activeTab === 'completed' && completed.length > 0 && (
    <Button 
      size="small" 
      type="link" 
      onClick={() => setClearAllModalVisible('completed')}
      icon={<ClearOutlined />}
    >
      Remove all notifications
    </Button>
  )}
  {activeTab === 'failed' && failed.length > 0 && (
    <Button 
      size="small" 
      type="link" 
      onClick={() => setClearAllModalVisible('failed')}
      icon={<ClearOutlined />}
    >
      Clear all
    </Button>
  )}
</div>
          </div>
        </Card>
      )}

      {!isVisible && (
  <Button
    type="primary"
    style={{ 
      position: 'fixed', 
      bottom: 20, 
      right: 20, 
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      padding: '0 12px'
    }}
    onClick={() => {
      setIsVisible(true);
      onRefresh();
    }}
  >
    {pending.length > 0 ? (
      <>
        <SyncOutlined spin /> 
        {pendingTranslations > 0 && `${pendingTranslations} Translations`}
        {pendingTranslations > 0 && (pendingProofreading > 0 || pendingEditingNotes > 0) && `, `}
        {pendingProofreading > 0 && `${pendingProofreading} Proofreads`}
        {pendingProofreading > 0 && pendingEditingNotes > 0 && `, `}
        {pendingEditingNotes > 0 && `${pendingEditingNotes} Editing Notes`}
        {` in progress`}
      </>
    ) : totalCount > 0 ? (
      <>
        <MinusCircleOutlined /> Processing Status ({totalCount})
      </>
    ) : (
      <>
        <MinusCircleOutlined /> Processing Status
      </>
    )}
  </Button>
)}

      {/* Error Modal */}
      <Modal
        title="Error Details"
        open={errorModalVisible}
        onCancel={() => setErrorModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setErrorModalVisible(false)}>
            Close
          </Button>
        ]}
      >
        <div style={{ maxHeight: '300px', overflow: 'auto' }}>
          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {currentError}
          </pre>
        </div>
      </Modal>

      {/* Clear all modal for completed translations */}
      <Modal
        title="Clear All Completed Notifications"
        open={clearAllModalVisible === 'completed'}
        onOk={() => clearAllTranslations('completed')}
        onCancel={() => setClearAllModalVisible(null)}
        okText="Clear All"
        cancelText="Cancel"
      >
        <p>This will only remove these notifications from your history list.</p>
        <p>Your processed content is already saved in your book and will remain intact.</p>
        <p>This merely cleans up your notification panel.</p>
      </Modal>

      {/* Clear all modal for failed translations */}
      <Modal
        title="Clear All Failed Notifications"
        open={clearAllModalVisible === 'failed'}
        onOk={() => clearAllTranslations('failed')}
        onCancel={() => setClearAllModalVisible(null)}
        okText="Clear All"
        cancelText="Cancel"
      >
        <p>This will only remove these notifications from your history list.</p>
        <p>No actual content will be deleted. This merely cleans up your notification panel.</p>
      </Modal>
    </>
  );
};

export default TranslationStatusPanel;