// BasicPlotTab.tsx
import React, { useEffect, ChangeEvent, useState } from 'react';
import { Input, Select, Tooltip, Button, Space, Modal, Spin, Checkbox, Tag } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons'
import axios, { AxiosError, AxiosResponse } from 'axios';
import { User } from "firebase/auth";
import { getFirestore, doc, updateDoc, Timestamp, getDoc  } from "firebase/firestore"; 
import './GeneratingGifStyle.css';
import { SharedState, UsersSharedState, TabProps, CustomAxiosError } from './types';


const { TextArea } = Input;
const { Option } = Select;



const BasicPlotTab: React.FC<TabProps> = ({ sharedState, setSharedState, isAuth, user, usersSharedState, setUsersSharedState }) => {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [infoTitle, setInfoTitle] = useState("");
  const [infoContent, setInfoContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isConfirmBasicPlotModalVisible, setIsConfirmBasicPlotModalVisible] = useState(false);
  const [shouldSkipPlotConfirm, setShouldSkipPlotConfirm] = useState(false);
  const [basicPlot, setBasicPlot] = useState('');
  const [apiModel, setApiModel] = useState("Claude")
  const [isSuggestionModalVisible, setIsSuggestionModalVisible] = useState(false);
  const [userSuggestion, setUserSuggestion] = useState('');


  const [costState, setCostState] = useState<Record<string, any>>({}); 

  const db = getFirestore();

  const handlePlotChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
	setBasicPlot(value);
  };    

  ///Load Book data
  useEffect(() => {
    const fetchData = async () => {
      if (!sharedState.bookguid) return;  // Exit if no bookguid is available

      setIsLoading(true);
      try {
        const response = await axios.get(`http://192.168.1.187:8000/api/books/highlevel/${sharedState.bookguid}`);
        const data = response.data;
        
          setApiModel(data.model);
		  setBasicPlot(data.basicPlot);	  
      } catch (error: any) {
        console.error("Failed to fetch book details: ", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [sharedState.bookguid]); 
  
  useEffect(() => {
    const fetchGenerationCosts = async () => {
      setIsLoading(true);
      try {
        const generationCostsResponse = await axios.get('http://192.168.1.187:8000/get_generation_cost/');
        const costsData: Record<string, any> = generationCostsResponse.data.creditCosts;
        const generationTypes = [
          'BasicPlot', 'Tropes', 'WritingStyle', 'MainCharacters', 
          'SupportingCharacters', 'Locations', 'Outline', 
          'Title', 'Blurb', 'Novel', 'ChapterSummary','CharacterName','CharacterDetails',
          'LocationName','LocationDetails'
        ];

        let newState: Record<string, any> = {}; 
        for (let genType of generationTypes) {
          const key = `${genType}_${apiModel}`;
          newState[`gen${genType}Cost`] = costsData[key];
        }

        // Updating the new costState with the fetched costs
        setCostState(newState);
        
		console.log("Cost State genBasicPlotCost: "+costState.genBasicPlotCost);
		
      } catch (error) {
        console.error("Failed to fetch generation costs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (apiModel) {  // Ensuring model is available before fetching
      fetchGenerationCosts();
    }
  }, [sharedState.bookguid, apiModel]);

const savePlot = async () => {
  if (sharedState.bookguid && isAuth) {
    try {
		console.log("Saving Plot");
      const bookRef = doc(db, 'books', sharedState.bookguid);
      const plotData = {
        basicPlot: basicPlot,
        lastModifiedDate: Timestamp.now()
      };

      // Update the Firestore document with separate fields for each act
      await updateDoc(bookRef, plotData);

    } catch (error) {
      console.error("Error updating document:", error);
    }
  }
};
  


// Confirmation handlers
const handleConfirmBasicPlotModal = async (confirm: boolean) => {
  setIsConfirmBasicPlotModalVisible(false);
  if (confirm) {
    handleGenerateBasicPlot();
  }
};

const handleGenerateBasicPlotClick = async () => {
  if (basicPlot && basicPlot.trim() !== '' && !shouldSkipPlotConfirm) {
    setIsConfirmBasicPlotModalVisible(true);
  } else {
    handleSuggestionModal(true);
  }
};


  const handleInfoModal = (visible: boolean, source: string) => {
    if (source === 'BasicPlot')
	{
	setInfoTitle("Basic Plot Information");
    setInfoContent(`ScribeShadow can generate a basic plot based on the Genre and Tropes in the High Level tab. You can also write your own plot that you want ScribeShadow to use.
	
	Character names or specific locations (if any) included in the Basic Plot will be used when ScribeShadow generates characters or locations, so make sure you are happy with them before continuing.
	
	ScribeShadow will use the Basic Plot when generating Characters, Locations, the Expanded Plot, and your Outline.`);
    }	
    setIsInfoModalVisible(visible);
  };

  let infoModalContent = (
    <p>
      {infoContent.split('\n').map((line, i) => (
        <span key={i}>
          {line}
          <br />
        </span>
      ))}
    </p>
  );

    
  const handleErrorModal = (visible: boolean) => {
    setErrorModalVisible(visible);
  };
  
const handleSuggestionModal = (visible: boolean) => {
  setIsSuggestionModalVisible(visible);
  if (!visible) {
    setUserSuggestion('');
  }
};  
  
const handleGenerateBasicPlot = async () => {
  setIsLoading(true);

  try {
    const response = await axios.post('http://192.168.1.187:8000/generate_basicplot/', { 
      apimodel: apiModel, 
	  bookGuid: sharedState.bookguid,
	  userID: usersSharedState.userID,
	  userSuggestion: userSuggestion
    });
    
	setBasicPlot(response.data.basicPlot);	

  } catch (err) {
    const axiosErr = err as AxiosError;
    if (axiosErr.response && axiosErr.response.data && typeof (axiosErr.response.data as any).detail === 'string') {
      setErrorMessage((axiosErr.response.data as any).detail);
    } else {
      setErrorMessage('An error occurred while generating the plot.');
    }
    handleErrorModal(true);
  } finally {
    setIsLoading(false);
  }
};

  return (
    <div>
        {!sharedState.bookguid && isAuth && <Tooltip title="Please start or open a book in the Book Management tab."><p><b>Please start or open a book in the Book Management tab.<br/>Fields will become available once a book is in progress.</b></p></Tooltip>}
		{!isAuth && <Tooltip title="Please click Get Started."><p><b>Click Get Started to sign up and start generating books!</b></p></Tooltip>}	
	 <div style={{ marginBottom: '20px' }}>
        <Space>
          <p style={{ fontWeight: 'bold' }}>Basic Plot:</p>
          <Tooltip title="Click for more information">
            <InfoCircleOutlined
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => handleInfoModal(true, 'BasicPlot')}
            />
          </Tooltip>
        </Space>

      <div style={{ marginBottom: '20px' }}>
        <TextArea rows={20} value={basicPlot} onChange={e => handlePlotChange(e)} onBlur={() => savePlot()} />
      </div>

	  

<div style={{ marginTop: '10px' }}>
  <div style={{ display: 'inline-block' }}>
    {isAuth ? (
      <Button onClick={handleGenerateBasicPlotClick} disabled={!isAuth || !sharedState.bookguid}>Generate Basic Plot</Button>
    ) : (
      <Tooltip title="Please click 'Get Started' to use this feature.">
        <Button disabled>Generate Basic Plot</Button>
      </Tooltip>
    )}
    {costState.genBasicPlotCost !== null && (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
        <Tag color="blue" style={{ fontWeight: 'bold' }}>
          {costState.genBasicPlotCost} {costState.genBasicPlotCost > 1 ? 'Credits' : 'Credit'}
        </Tag>
      </div>
    )}
  </div>
</div>

      </div>
      
      <Modal 
        title={infoTitle}
        visible={isInfoModalVisible}
        onOk={() => handleInfoModal(false, "")}
        onCancel={() => handleInfoModal(false, "")}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button key="submit" type="primary" onClick={() => handleInfoModal(false, "")}>
              OK
            </Button>
          </div>
        }
      >
        {infoModalContent}
      </Modal>
	  
	  <Modal 
	  title="Error"
	  visible={isErrorModalVisible}
	  onOk={() => setErrorModalVisible(false)}
	  onCancel={() => setErrorModalVisible(false)}
	  footer={
	 	 <div style={{ textAlign: 'center' }}>
	 	 <Button key="submit" type="primary" onClick={() => setErrorModalVisible(false)}>
	 		 OK
	 	 </Button>
	 	 </div>
	  }
	  >
	  <p>{errorMessage}</p>
	  </Modal>
	  
	  <Modal
	  title={null}
	  visible={isLoading}
	  footer={null}
	  centered
	  closable={false}
	  maskClosable={false}
	  style={{ 
	 	 textAlign: 'center', 
	 	 background: 'transparent', 
	 	 boxShadow: 'none',
	 	 width: 'auto'
	  }}
	  >
	  <img src="generating.gif" alt="Generating..." className="responsive-gif" />
	  </Modal>	  

<Modal 
  title="Are you sure you want to regenerate your Basic Plot?"
  visible={isConfirmBasicPlotModalVisible}
  onOk={() => {
    handleConfirmBasicPlotModal(false);
    handleSuggestionModal(true);
  }}
  onCancel={() => handleConfirmBasicPlotModal(false)}
>
	  <p>If you proceed, the existing Basic Plot text will be deleted and replaced. <br/>
	    
	  Regenerating Basic Plot will NOT change any other text fields already generated.<br/><br/>
	  
	  Do you want to continue?</p>
	  <Checkbox onChange={(e) => setShouldSkipPlotConfirm(e.target.checked)}>Don't show this again for this session</Checkbox>
	  </Modal>

<Modal
  title="Suggestions for AI (Optional)"
  visible={isSuggestionModalVisible}
  onOk={() => {
    handleSuggestionModal(false);
    handleGenerateBasicPlot();
  }}
  onCancel={() => handleSuggestionModal(false)}
>
  <p>Enter any additional instructions or suggestions for the AI (optional):</p>
  <TextArea
    rows={4}
    value={userSuggestion}
    onChange={(e) => setUserSuggestion(e.target.value)}
  />
</Modal>
	  	  
    </div>
  );
};

export default BasicPlotTab;
