// NeverTranslateModal.tsx
import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Tag, Tooltip, message, Spin } from 'antd';
import { EditOutlined, DeleteOutlined, SaveOutlined, QuestionCircleOutlined, PlusOutlined } from '@ant-design/icons';

interface NeverTranslateModalProps {
  isVisible: boolean;
  onClose: () => void;
  currentLanguagePair: string;
  neverTranslateWords: { [key: string]: string[] };
  setNeverTranslateWords: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>;
  bookGuid: string;
}

const NeverTranslateModal: React.FC<NeverTranslateModalProps> = ({
  isVisible,
  onClose,
  currentLanguagePair,
  neverTranslateWords,
  setNeverTranslateWords,
  bookGuid,
}) => {
  const [newWord, setNewWord] = useState('');
  const [editingWord, setEditingWord] = useState<string | null>(null);
  const [editedWord, setEditedWord] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Reset state when modal is opened or closed
  useEffect(() => {
    if (isVisible) {
      setNewWord('');
      setEditingWord(null);
      setEditedWord('');
    }
  }, [isVisible]);

  const updateAPI = async (words: string[]): Promise<boolean> => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://askpoeai-nswdzfky7q-uc.a.run.app/update-never-translate/${bookGuid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          language_pair: currentLanguagePair,
          words: words,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to update API');
      }
      return true;
    } catch (error) {
      console.error('Error updating API:', error);
      message.error('Failed to update. Please try again.');
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const isValidInput = (input: string): boolean => {
    // Allow Unicode letters, numbers, spaces, hyphens, and apostrophes
    return /^[\p{L}\p{N}\s\-']+$/u.test(input);
  };

  const handleAddWord = async () => {
    if (newWord.trim() && isValidInput(newWord.trim())) {
      const updatedWords = [...(neverTranslateWords[currentLanguagePair] || []), newWord.trim()];
      const success = await updateAPI(updatedWords);
      if (success) {
        setNeverTranslateWords(prev => ({
          ...prev,
          [currentLanguagePair]: updatedWords
        }));
        setNewWord('');
      }
    } else {
      message.error('Please enter a valid word or phrase (only letters, numbers, spaces, hyphens, and apostrophes are allowed).');
    }
  };

  const handleSaveEdit = async () => {
    if (editingWord && editedWord.trim() && isValidInput(editedWord.trim())) {
      const updatedWords = neverTranslateWords[currentLanguagePair].map(w => 
        w === editingWord ? editedWord.trim() : w
      );
      const success = await updateAPI(updatedWords);
      if (success) {
        setNeverTranslateWords(prev => ({
          ...prev,
          [currentLanguagePair]: updatedWords
        }));
        setEditingWord(null);
      }
    } else {
      message.error('Please enter a valid word or phrase (only letters, numbers, spaces, and hyphens are allowed).');
    }
  };

  const handleRemoveWord = async (word: string) => {
    const updatedWords = neverTranslateWords[currentLanguagePair].filter(w => w !== word);
    const success = await updateAPI(updatedWords);
    if (success) {
      setNeverTranslateWords(prev => ({
        ...prev,
        [currentLanguagePair]: updatedWords
      }));
    }
  };

  const handleEditWord = (oldWord: string) => {
    setEditingWord(oldWord);
    setEditedWord(oldWord);
  };

  const handleClose = () => {
    setNewWord('');
    setEditingWord(null);
    setEditedWord('');
    onClose();
  };

  return (
    <Modal
      title={
        <span>
          Text to Never Translate for {currentLanguagePair}
          <Tooltip title="The AI will be instructed to never translate the text entered here">
            <QuestionCircleOutlined style={{ marginLeft: '8px', cursor: 'pointer', color: '#1677ff' }} />
          </Tooltip>
        </span>
      }
      visible={isVisible}
      onCancel={handleClose}
      footer={[
        <Button key="close" onClick={handleClose}>
          Close
        </Button>
      ]}
      width={700}
    >
      <Spin spinning={isLoading}>
        <div style={{ 
          background: '#f7f7f7', 
          padding: '15px', 
          borderRadius: '6px', 
          marginBottom: '15px' 
        }}>
          <div style={{ marginBottom: '15px' }}>
            <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
              <strong>Word or Phrase to Never Translate:</strong>
            </label>
            <Input
              value={newWord}
              onChange={(e) => setNewWord(e.target.value)}
              placeholder="Enter word or phrase to never translate"
              style={{ width: '100%' }}
            />
          </div>
          
          <Button 
            type="primary" 
            onClick={handleAddWord} 
            icon={<PlusOutlined />}
          >
            Add Word/Phrase
          </Button>
        </div>
        
        <div style={{ 
          background: '#f0f7ff', 
          padding: '15px', 
          borderRadius: '6px'
        }}>
          <div style={{ marginBottom: '12px' }}>
            <strong>Current Text to Never Translate:</strong>
            <Tooltip title="The AI will never translate these words or phrases">
              <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#1677ff' }} />
            </Tooltip>
          </div>
          
          <div style={{ maxHeight: '300px', overflowY: 'auto', padding: '5px 0' }}>
            {currentLanguagePair && (neverTranslateWords[currentLanguagePair] ?? []).length > 0 ? (
              <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -5px' }}>
                {(neverTranslateWords[currentLanguagePair] ?? []).map((word, index) => (
                  <div key={index} style={{ 
                    width: editingWord === word ? '100%' : '50%', 
                    padding: '0 5px', 
                    boxSizing: 'border-box', 
                    marginBottom: '10px' 
                  }}>
                    <Tag 
                      style={{ 
                        padding: '6px 8px', 
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%'
                      }}
                    >
                      {editingWord === word ? (
                        <>
                          <Input
                            value={editedWord}
                            onChange={(e) => setEditedWord(e.target.value)}
                            style={{ width: '150px', marginRight: '8px' }}
                          />
                          <Tooltip title="Save">
                            <SaveOutlined
                              onClick={handleSaveEdit}
                              style={{ marginLeft: '5px', cursor: 'pointer', color: '#1677ff' }}
                            />
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{word}</span>
                          <div style={{ marginLeft: 'auto', display: 'flex', gap: '8px', flexShrink: 0 }}>
                            <Tooltip title="Edit">
                              <EditOutlined
                                onClick={() => handleEditWord(word)}
                                style={{ cursor: 'pointer', color: '#1677ff' }}
                              />
                            </Tooltip>
                            <Tooltip title="Delete">
                              <DeleteOutlined
                                onClick={() => handleRemoveWord(word)}
                                style={{ cursor: 'pointer', color: '#ff4d4f' }}
                              />
                            </Tooltip>
                          </div>
                        </>
                      )}
                    </Tag>
                  </div>
                ))}
              </div>
            ) : (
              <div style={{ color: '#999', padding: '10px 0' }}>
                No words or phrases added yet. Add your first word or phrase above.
              </div>
            )}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default NeverTranslateModal;