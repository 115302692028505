//Account.tsx
import React, { useState, useEffect } from 'react';
import { 
  Card, Switch, Typography, Button, Modal, Divider, Row, Col, 
  Descriptions, Space, Grid, Tooltip, Select, message, Collapse, 
  Alert, Spin, Input, Statistic, Badge, Avatar, Radio, Tag
} from 'antd';
import { 
  CheckCircleOutlined, QuestionCircleOutlined, CopyOutlined, 
  CreditCardOutlined, SettingOutlined, GiftOutlined,
  TeamOutlined, DollarOutlined, BookOutlined, RocketOutlined, CrownOutlined
} from '@ant-design/icons';
import { CheckoutForm } from './CheckoutForm'; 
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { UsersSharedState } from './Create/types';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

const { Title, Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;
const { Option } = Select;
const { Panel } = Collapse;

type PlanType = {
  name: string;
  monthlyPrice: string;
  yearlyPrice: string;
  credits: string;
  description: string;
  isPopular?: boolean;
};

interface AccountProps {
  isAuth: boolean;
  usersSharedState: any;
  setUsersSharedState: any;
  showLoginModal: () => void;
}

interface PlanDetails {
  planName?: string;
  monthlyCredits?: number;
  tier?: number;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const Account: React.FC<AccountProps> = ({ isAuth, usersSharedState, setUsersSharedState, showLoginModal }) => {
  
  const [isYearly, setIsYearly] = useState(false);
  const [planDetails, setPlanDetails] = useState<PlanDetails>({});
  const [isCheckoutModalVisible, setisCheckoutModalVisible] = useState(false);
  const [isLoadingPlanDetails, setIsLoadingPlanDetails] = useState(false);
  const [affiliateCode, setAffiliateCode] = useState('');
  const [foreverCredits, setForeverCredits] = useState(0); 
  const pageDisabled = false;
  const disabledMessage = "After a lot of feedback, we're making improvements. Check back soon! -Team ScribeShadow";
  const screens = useBreakpoint();
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const [isUpdatingLanguage, setIsUpdatingLanguage] = useState(false);
  const baseLanguages = ["Dutch", "English", "British English", "French", "German", "Italian", "European Portuguese", "Brazilian Portuguese", "Spanish"];
  const [isRenewModalVisible, setIsRenewModalVisible] = useState(false);
  const [isRenewing, setIsRenewing] = useState(false);  
  const [renewalAmount, setRenewalAmount] = useState<number | null>(null);
  const [renewalPlanName, setRenewalPlanName] = useState<string>('');
  const [isLoadingRenewalDetails, setIsLoadingRenewalDetails] = useState<boolean>(false);
  const [renewalErrorMessage, setRenewalErrorMessage] = useState<string | null>(null);
  const [isReferralModalVisible, setIsReferralModalVisible] = useState(false);
  const [customReferralCode, setCustomReferralCode] = useState('');
  const [isGeneratingReferralCode, setIsGeneratingReferralCode] = useState(false);
  const [referralCodeOption, setReferralCodeOption] = useState('generate');

  useEffect(() => {
    const fetchDetails = async () => {
      if (isAuth && usersSharedState.userID) {
        setIsLoadingPlanDetails(true);
        try {
          const creditResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/check_credit_balance/', {
            params: {
              userID: usersSharedState.userID,
            },
          });

          const planID = creditResponse.data.planID;
          const planResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/get_plan_details/${planID}`);
          const subscriptionResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/subscription-details/${usersSharedState.userID}`);
          
          setUsersSharedState({
            ...usersSharedState,
            credits: creditResponse.data.credit_balance,
            maxCharacters: subscriptionResponse.data.maxCharacters ?? 1,
            maxLocations: subscriptionResponse.data.maxLocations ?? 1,
            maxChapters: subscriptionResponse.data.maxChapters ?? 1,
            gpt4Allowed: subscriptionResponse.data.gpt4Allowed ?? false
          });
      
          setAffiliateCode(subscriptionResponse.data.affiliateCode ?? '')
          console.log("subscriptionResponse.data.affiliateCode: "+subscriptionResponse.data.affiliateCode);

          setForeverCredits(creditResponse.data.forever_credits);
          setPlanDetails({
            planName: planResponse.data.planName,
            monthlyCredits: planResponse.data.monthlyCredits,
            tier: planResponse.data.tier,
          });

          // Fetch the current default language
          const languageResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/get-default-language', {
            params: { userID: usersSharedState.userID }
          });
          setDefaultLanguage(languageResponse.data.defaultLanguage);

        } catch (error) {
          console.error('Failed to fetch details:', error);
        } finally {
          setIsLoadingPlanDetails(false);
        }
      }
    };
    fetchDetails();
  }, [isAuth, usersSharedState.userID, planDetails.tier]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    // This function will run when the component unmounts.
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  
  const handleBilling = async () => {
    try {
      const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/create-customer-portal-session', {
        userID: usersSharedState.userID,
      });
      window.open(response.data.url, '_blank');
    } catch (error) {
      console.error('Failed to open customer portal:', error);
    }
  };  
  
  const plans: PlanType[] = [
    {
      name: 'Inkling',
      monthlyPrice: '4.99',
      yearlyPrice: '49.99',
      credits: '10,000',
      description: 'Ideal for new writers, this plan offers enough credits for short stories or sampling of AI translation.',
      isPopular: false,
    },
    {
      name: 'Storyteller',
      monthlyPrice: '24.99',
      yearlyPrice: '249.99',
      credits: '55,000',
      description: 'The Storyteller plan provides ample credits to bring your novellas to life in different languages, enhancing your storytelling reach.',
      isPopular: false,
    },
    {
      name: 'Wordsmith',
      monthlyPrice: '49.99',
      yearlyPrice: '499.99',
      credits: '120,000',
      description: 'The Wordsmith plan is suited for authors translating full-length novels monthly, perfect for authors aiming to expand their readership internationally.',
      isPopular: true,
    },
    {
      name: 'Novelist',
      monthlyPrice: '99.99',
      yearlyPrice: '999.99',
      credits: '250,000',
      description: 'For established authors, the Novelist plan offers ample credits for translating novels into multiple languages.',
      isPopular: false,
    },
    {
      name: 'Bestseller',
      monthlyPrice: '199.99',
      yearlyPrice: '1999.99',
      credits: '600,000',
      description: 'The Bestseller plan enables the translation of an extensive catalogue of works. It\'s an ideal choice for prolific authors who have a stream of content requiring translation.',
      isPopular: false,
    },
    {
      name: 'Publisher',
      monthlyPrice: '399.99',
      yearlyPrice: '0',
      credits: '1,300,000',
      description: 'The Publisher plan is perfect for publishing houses and professional writers who manage a large volume of content. This plan provides ample credits to ensure your extensive library is translated quickly.',
      isPopular: false,
    },
  ];

  const handleDefaultLanguageChange = async (value: string) => {
    setIsUpdatingLanguage(true);
    try {
      await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/update-default-language', {
        userID: usersSharedState.userID,
        language: value
      });
      setDefaultLanguage(value);
      message.success('Default language updated successfully');
    } catch (error) {
      console.error('Failed to update default language:', error);
      message.error('Failed to update default language');
    } finally {
      setIsUpdatingLanguage(false);
    }
  };

const PricingCard: React.FC<{ plan: PlanType }> = ({ plan }) => {
  const [isHovered, setIsHovered] = useState(false);
  
  const cardStyle: CSSProperties = {
    width: screens.md ? '350px' : '100%',
    maxWidth: '350px',
    margin: '10px auto',
    padding: plan.isPopular ? '0 0 20px 0' : '0 0 20px 0',
    borderRadius: '12px',
    boxShadow: isHovered 
      ? '0 12px 36px rgba(0, 0, 0, 0.15)'
      : (plan.isPopular ? '0 8px 24px rgba(0, 0, 0, 0.12)' : '0 4px 12px rgba(0, 0, 0, 0.08)'),
    border: plan.isPopular ? '1px solid #1890ff' : '1px solid #eaeaea',
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    transform: isHovered ? 'translateY(-5px)' : 'none'
  };

    const headerStyle: CSSProperties = {
      backgroundColor: plan.isPopular ? '#e6f7ff' : '#fff',
      padding: '16px',
      borderBottom: '1px solid #eaeaea',
      position: 'relative'
    };

    const bodyStyle: CSSProperties = {
      padding: '16px',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    };

    const featureListStyle: CSSProperties = {
      padding: '0 16px',
      margin: '16px 0'
    };

    const priceStyle: CSSProperties = {
      fontSize: '28px',
      fontWeight: 'bold',
      color: '#1890ff',
      margin: '16px 0 8px'
    };

  return (
    <div 
      style={cardStyle} 
      onMouseEnter={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)}
    >
        {plan.isPopular && (
          <div style={{
            backgroundColor: '#1890ff',
            color: 'white',
            padding: '6px 0',
            textAlign: 'center',
            fontWeight: 'bold',
            width: '100%',
          }}>
            MOST POPULAR
          </div>
        )}
        <div style={headerStyle}>
          <Title level={3} style={{ marginBottom: 0, textAlign: 'center' }}>{plan.name}</Title>
        </div>
        <div style={bodyStyle}>
          <Paragraph style={{ minHeight: '80px', textAlign: 'center' }}>{plan.description}</Paragraph>
          
          <div style={{ textAlign: 'center' }}>
            <div style={priceStyle}>${plan.monthlyPrice}<span style={{ fontSize: '16px', fontWeight: 'normal' }}>/month</span></div>
            {plan.yearlyPrice !== '0' && (
              <Text type="secondary">
                ${plan.yearlyPrice} when billed yearly
              </Text>
            )}
          </div>
          
          <div style={featureListStyle}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
                <Text>{plan.credits} monthly translation credits</Text>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
                <Text>Access to all translation features</Text>
              </div>
            </Space>
          </div>
          
          <div style={{ textAlign: 'center', padding: '0 16px' }}>
            <Button 
              type="primary" 
              size="large" 
              block 
              onClick={showLoginModal}
              style={{ 
                height: '48px', 
                borderRadius: '8px',
                fontWeight: 'bold',
                backgroundColor: plan.isPopular ? '#1890ff' : undefined
              }}
            >
              Log In to Purchase
            </Button>
            <Text type="secondary" style={{ display: 'block', marginTop: '8px', fontSize: '12px' }}>
              Each translated word costs approximately one credit
            </Text>
          </div>
        </div>
      </div>
    );
  };

  const handleRenewNowClick = async () => {
    setIsRenewModalVisible(true);
    setIsLoadingRenewalDetails(true);
    setRenewalErrorMessage(null); // Reset the error message
    try {
      const response = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/get-subscription-details', {
        params: { userID: usersSharedState.userID },
      });

      const { amount_due, plan_name, status, message } = response.data;
      
      if (status === 'cancelled') {
        setRenewalErrorMessage(message || 'Your subscription has been cancelled.');
      } else {
        setRenewalAmount(amount_due);
        setRenewalPlanName(plan_name);
      }
    } catch (error) {
      console.error('Failed to fetch subscription details:', error);
      let message = 'Failed to fetch subscription details. Please try again later.';
      if (axios.isAxiosError(error) && error.response) {
        const errorData = error.response.data;
        if (errorData.detail) {
          message = errorData.detail;
        }
      }
      setRenewalErrorMessage(message);
    } finally {
      setIsLoadingRenewalDetails(false);
    }
  };

  const handleRenewNowConfirm = async () => {
    setIsRenewing(true);
    try {
      await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/renew-subscription', {
        userID: usersSharedState.userID,
      });
      message.success('Your subscription has been renewed successfully.');
      await fetchUpdatedPlanDetails(); // Fetch updated plan details
    } catch (error) {
      console.error('Failed to renew subscription:', error);
      message.error('Failed to renew subscription. Please try again later.');
    } finally {
      setIsRenewing(false);
      setIsRenewModalVisible(false);
    }
  };

  const handleRenewNowCancel = () => {
    setIsRenewModalVisible(false);
    setRenewalErrorMessage(null); // Reset error message when modal is closed
  };

  const fetchUpdatedPlanDetails = async () => {
    setIsLoadingPlanDetails(true);
    try {
      const creditResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/check_credit_balance/', {
        params: {
          userID: usersSharedState.userID,
        },
      });

      const planID = creditResponse.data.planID;
      const planResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/get_plan_details/${planID}`);
      const subscriptionResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/subscription-details/${usersSharedState.userID}`);
      
      setUsersSharedState({
        ...usersSharedState,
        credits: creditResponse.data.credit_balance,
        maxCharacters: subscriptionResponse.data.maxCharacters ?? 1,
        maxLocations: subscriptionResponse.data.maxLocations ?? 1,
        maxChapters: subscriptionResponse.data.maxChapters ?? 1,
        gpt4Allowed: subscriptionResponse.data.gpt4Allowed ?? false
      });

      setAffiliateCode(subscriptionResponse.data.affiliateCode ?? '');
      setForeverCredits(creditResponse.data.forever_credits);
      setPlanDetails({
        planName: planResponse.data.planName,
        monthlyCredits: planResponse.data.monthlyCredits,
        tier: planResponse.data.tier,
      });

    } catch (error) {
      console.error('Failed to fetch updated details:', error);
    } finally {
      setIsLoadingPlanDetails(false);
    }
  };

  const handleGenerateReferralCode = async (customCode: string | null = null) => {
    setIsGeneratingReferralCode(true);
    try {
      const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/generate-referral-code', {
        userID: usersSharedState.userID,
        customCode: customCode
      });
      setAffiliateCode(response.data.referralCode);
      setIsReferralModalVisible(false);
      message.success('Referral code generated successfully!');
    } catch (error) {
      console.error('Failed to generate referral code:', error);
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 400 && 
            error.response.data.detail && 
            error.response.data.detail.toLowerCase().includes('already exists')) {
          message.error('This custom code is already in use. Please choose a different one.');
        } else {
          message.error('Failed to generate referral code. Please try again.');
        }
      } else {
        message.error('Failed to generate referral code. Please try again.');
      }
    } finally {
      setIsGeneratingReferralCode(false);
    }
  };

  return (
    <div style={{ 
      backgroundColor: '#f9fafc', 
      padding: '32px 16px', 
      minHeight: '100vh'
    }}>
      <div style={{ maxWidth: '1500px', margin: '0 auto' }}>
        {pageDisabled ? (
          <Alert 
            message={disabledMessage} 
            type="warning" 
            showIcon 
            style={{ marginBottom: '24px', borderRadius: '8px' }}
          />
        ) : (
          <>
            <Title 
              level={2} 
              style={{ 
                textAlign: 'center', 
                marginBottom: '32px',
                color: '#1a3353' 
              }}
            >
              {isAuth ? 'My Account' : 'Pricing Plans'}
            </Title>

            {isAuth ? (
              <Row gutter={[24, 24]}>
                {/* Current Plan Card */}
                <Col xs={24} lg={8} order={screens.lg ? 2 : 1}>
                  <Card
                    title={
                      <div style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        fontSize: '18px',
                        fontWeight: 500,
                        color: '#1a3353'
                      }}>
                        <CreditCardOutlined style={{ marginRight: '8px' }} />
                        Current Plan
                      </div>
                    }
                    style={{ 
                      height: '100%', 
                      borderRadius: '12px',
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                      overflow: 'hidden',
                      border: '1px solid #e9edf3'
                    }}
                    headStyle={{ 
                      backgroundColor: '#f0f5ff',
                      borderBottom: '1px solid #e9edf3',
                      padding: '16px'
                    }}
                    bodyStyle={{ padding: '24px' }}
                  >
				  
      {isLoadingPlanDetails ? (
        <div style={{ 
          textAlign: 'center', 
          padding: '40px 0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '16px'
        }}>
          <Spin size="large" />
          <Text>Loading your plan details...</Text>
        </div>
      ) : (
        <>
<div style={{ 
  marginBottom: '24px',
  background: '#f9fafc',
  borderRadius: '8px',
  padding: '16px',
  border: '1px solid #e9edf3'
}}>
  <Row align="middle" justify="center">
    <Col>
      <Title level={3} style={{ margin: '0', color: '#1890ff' }}>
        {planDetails.planName}
      </Title>
    </Col>
  </Row>
</div>
					<center>
                        <Row gutter={[16, 24]}>
                          <Col span={12}>
                            <Statistic 
                              title="Total Credit Balance" 
                              value={usersSharedState.credits} 
                              valueStyle={{ color: '#1890ff', fontSize: '24px' }}
                            />
                          </Col>
                          <Col span={12}>
                            <Statistic 
                              title="Monthly Credits" 
                              value={planDetails.monthlyCredits} 
                              valueStyle={{ fontSize: '24px' }}
                            />
                          </Col>
                          <Col span={12}>
                            <Statistic 
                              title="Monthly Remaining" 
                              value={usersSharedState.credits - foreverCredits}
                              valueStyle={{ fontSize: '24px' }}
                            />
                          </Col>
                          <Col span={12}>
                            <Statistic 
                              title="Forever Credits" 
                              value={foreverCredits}
                              valueStyle={{ fontSize: '24px' }}
                            />
                          </Col>
                        </Row>
						</center>

                        {planDetails.tier !== 0 && (
                          <div style={{ 
                            marginTop: '24px', 
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '12px'
                          }}>
                            <Button 
                              type="primary" 
                              icon={<CreditCardOutlined />}
                              onClick={handleBilling}
                              size="large"
                              style={{ 
                                borderRadius: '8px',
                                height: '40px'
                              }}
                            >
                              Manage Subscription
                            </Button>
                            
                            <Tooltip title={planDetails.tier === 0 
                              ? 'You do not have an active subscription to renew.' 
                              : 'Immediately renew your subscription. This will charge you immediately and move your billing date to today.'
                            }>
                              <Button
                                type="default"
                                icon={<RocketOutlined />}
                                onClick={handleRenewNowClick}
                                disabled={planDetails.tier === 0}
                                size="large"
                                style={{ 
                                  borderRadius: '8px',
                                  height: '40px'
                                }}
                              >
                                Renew Now
                              </Button>
                            </Tooltip>
                            
                            <Button 
                              type="link" 
                              href="https://app.scribeshadow.com/pricing" 
                              target="_blank"
                            >
                              View Subscription Plans
                            </Button>
                          </div>
                        )}
                        
                        <Divider style={{ margin: '24px 0 16px' }} />
                        
                        <div style={{ textAlign: 'center' }}>
                          <Link 
                            to="/credit-history"
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              color: '#1890ff'
                            }}
                          >
                            <DollarOutlined style={{ marginRight: '4px' }} />
                            View Credit Transaction History
                          </Link>
                        </div>
                      </>
                    )}
                  </Card>
                </Col>

                {/* FAQ Card */}
                <Col xs={24} lg={8} order={screens.lg ? 1 : 2}>
                  <Card
                    title={
                      <div style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        fontSize: '18px',
                        fontWeight: 500,
                        color: '#1a3353'
                      }}>
                        <QuestionCircleOutlined style={{ marginRight: '8px' }} />
                        FAQ
                      </div>
                    }
                    style={{ 
                      height: '100%', 
                      borderRadius: '12px',
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                      overflow: 'hidden',
                      border: '1px solid #e9edf3'
                    }}
                    headStyle={{ 
                      backgroundColor: '#fafcff',
                      borderBottom: '1px solid #e9edf3',
                      padding: '16px'
                    }}
                    bodyStyle={{ padding: '16px' }}
                  >
                    <Collapse 
                      bordered={false}
                      expandIconPosition="end"
                      style={{ background: 'transparent' }}
                    >
                      <Panel 
                        header="Can I upgrade/downgrade/cancel my Subscription?" 
                        key="1" 
                        style={{ 
                          marginBottom: '12px', 
                          borderRadius: '8px',
                          border: '1px solid #e9edf3',
                          background: '#fff' 
                        }}
                      >
                        <p>Yes, you can upgrade, downgrade, or cancel your subscription at any time. To manage your subscription click the "Manage Subscription" button in the <Link to="./account">account page</Link>.</p>
                      </Panel>
                      
                      <Panel 
                        header="Do my subscription credits expire?" 
                        key="2" 
                        style={{ 
                          marginBottom: '12px', 
                          borderRadius: '8px',
                          border: '1px solid #e9edf3',
                          background: '#fff' 
                        }}
                      >
                        <p>Yes, credits granted with your monthly subscription expire at the end of your subscription period. Forever credits, which do not expire, can also be purchased a la carte.</p>
                      </Panel>
                      
                      <Panel 
                        header="When does my subscription renew?" 
                        key="3" 
                        style={{ 
                          marginBottom: '12px', 
                          borderRadius: '8px',
                          border: '1px solid #e9edf3',
                          background: '#fff' 
                        }}
                      >
                        <p>Subscriptions renew monthly and the date and TIME you signed up. So if you signed up on the 17th at 2:45pm, your account will renew each month on the 17th at 2:45pm.</p>
                      </Panel>
                      
                      <Panel 
                        header="How does billing work when I upgrade or downgrade my plan?" 
                        key="6" 
                        style={{ 
                          marginBottom: '12px', 
                          borderRadius: '8px',
                          border: '1px solid #e9edf3',
                          background: '#fff' 
                        }}
                      >
                        <p>When you upgrade or downgrade your plan, changes don't take effect until your billing cycle renews. If you want to upgrade immediately, you can upgrade your plan using the "Manage Subscription" button and then use the "Renew Now" button to renew your subscription under your new plan right away.</p>
                      </Panel>
                      
                      <Panel 
                        header="What does the 'Renew Now' button do?" 
                        key="7" 
                        style={{ 
                          marginBottom: '12px', 
                          borderRadius: '8px',
                          border: '1px solid #e9edf3',
                          background: '#fff' 
                        }}
                      >
                        <p>The 'Renew Now' button allows you to immediately renew your subscription. This will charge you right away and move your billing date to today. It's useful if you want to align your billing cycle with a specific date or if you need immediate access to new credits.</p>
                      </Panel>
                      
                      <Panel 
                        header="How can I immediately upgrade my plan?" 
                        key="8" 
                        style={{ 
                          marginBottom: '12px', 
                          borderRadius: '8px',
                          border: '1px solid #e9edf3',
                          background: '#fff' 
                        }}
                      >
                        <p>To upgrade your plan immediately:
                          <ol>
                            <li>Click the "Manage Subscription" button in your account page.</li>
                            <li>Choose your new plan and complete the upgrade process.</li>
                            <li>After upgrading, return to your account page and click the "Renew Now" button.</li>
                            <li>Confirm the renewal to activate your new plan immediately.</li>
                          </ol>
                          This process will charge you for the new plan right away and adjust your billing cycle to start from today.
                        </p>
                      </Panel>
                      
                      <Panel 
                        header="Where can I find more information about pricing?" 
                        key="5" 
                        style={{ 
                          marginBottom: '12px', 
                          borderRadius: '8px',
                          border: '1px solid #e9edf3',
                          background: '#fff' 
                        }}
                      >
                        <p>You can find detailed information about our pricing plans on our <a href="https://app.scribeshadow.com/pricing" target="_blank" rel="noopener noreferrer">pricing page</a>.</p>
                      </Panel>
                      
                      <Panel 
                        header="Can my Referral Code be used on any plan?" 
                        key="4" 
                        style={{ 
                          borderRadius: '8px',
                          border: '1px solid #e9edf3',
                          background: '#fff' 
                        }}
                      >
                        <p>No. Referral codes cannot be redeemed when signing up for the Publisher or Polyglot plans.</p>
                      </Panel>
                    </Collapse>
                  </Card>
                </Col>

                {/* Settings & Referral Column */}
                <Col xs={24} lg={8} order={screens.lg ? 3 : 3}>
                  {/* Settings Card */}
                  <Card
                    title={
                      <div style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        fontSize: '18px',
                        fontWeight: 500,
                        color: '#1a3353'
                      }}>
                        <SettingOutlined style={{ marginRight: '8px' }} />
                        Settings
                      </div>
                    }
                    style={{ 
                      marginBottom: '24px', 
                      borderRadius: '12px',
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                      overflow: 'hidden',
                      border: '1px solid #e9edf3'
                    }}
                    headStyle={{ 
                      backgroundColor: '#f5f7fa',
                      borderBottom: '1px solid #e9edf3',
                      padding: '16px'
                    }}
                    bodyStyle={{ padding: '24px' }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <Title level={4} style={{ marginBottom: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Default Translation Language
                        <Tooltip title="This is the default language you want to translate into. The language chosen here will be automatically selected when you start a new translation. You can always change the selection before running a translation.">
                          <QuestionCircleOutlined style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '16px' }} />
                        </Tooltip>
                      </Title>
                      <Spin spinning={isUpdatingLanguage}>
                        <Select
                          style={{ width: '100%' }}
                          placeholder="Select default language"
                          onChange={handleDefaultLanguageChange}
                          value={defaultLanguage}
                          disabled={isUpdatingLanguage}
                          size="large"
                          optionFilterProp="children"
                          showSearch
                          filterOption={(input, option) =>
                            (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                          }
                        >
                          <Option value="">None</Option>
                          {baseLanguages.map((language) => (
                            <Option key={language} value={language}>
                              {language}
                            </Option>
                          ))}
                        </Select>
                      </Spin>
                    </div>
                  </Card>

                  {/* Referral Card */}
                  <Card
                    title={
                      <div style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        fontSize: '18px',
                        fontWeight: 500,
                        color: '#1a3353'
                      }}>
                        <GiftOutlined style={{ marginRight: '8px' }} />
                        Referral Program
                      </div>
                    }
                    style={{ 
                      borderRadius: '12px',
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                      overflow: 'hidden',
                      border: '1px solid #e9edf3'
                    }}
                    headStyle={{ 
                      backgroundColor: '#f6fff6',
                      borderBottom: '1px solid #e9edf3',
                      padding: '16px'
                    }}
                    bodyStyle={{ padding: '24px' }}
                  >
                    {affiliateCode ? (
                      <div style={{ textAlign: 'center' }}>
                        <div style={{ 
                          marginBottom: '16px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}>
                          <Title level={5} style={{ margin: '0 0 8px 0' }}>Your Referral Code:</Title>
                          <div style={{
                            padding: '12px 16px',
                            border: '1px dashed #52c41a',
                            borderRadius: '8px',
                            background: '#f6ffed',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px',
                            width: 'fit-content'
                          }}>
                            <Title level={3} style={{ margin: 0, color: '#52c41a' }}>{affiliateCode}</Title>
                            <Tooltip title="Copy to clipboard">
                              <Button 
                                type="text" 
                                icon={<CopyOutlined />} 
                                onClick={() => {
                                  navigator.clipboard.writeText(affiliateCode);
                                  message.success('Referral code copied to clipboard!');
                                }}
                              />
                            </Tooltip>
                          </div>
                        </div>

                        <Divider>How It Works</Divider>

                        <Space direction="vertical" style={{ width: '100%' }}>
                          <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                            <Avatar 
                              style={{ backgroundColor: '#f6ffed', color: '#52c41a', flexShrink: 0 }}
                              icon={<TeamOutlined />}
                            />
                            <Text>New subscribers get 50% off their first month when they use your code.</Text>
                          </div>
                          
                          <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                            <Avatar 
                              style={{ backgroundColor: '#f6ffed', color: '#52c41a', flexShrink: 0 }}
                              icon={<DollarOutlined />}
                            />
                            <Text>You earn 25% of the credits from their plan purchase as forever credits.</Text>
                          </div>
                          
                          <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                            <Avatar 
                              style={{ backgroundColor: '#f6ffed', color: '#52c41a', flexShrink: 0 }}
                              icon={<CheckCircleOutlined />}
                            />
                            <Text>Referrals are valid for new subscribers only and cannot be used with Publisher or higher subscription tiers.</Text>
                          </div>
                        </Space>
                      </div>
                    ) : (
                      <div style={{ textAlign: 'center' }}>
                        <Title level={4} style={{ marginBottom: '16px' }}>Join our referral program!</Title>
                        
                        <div style={{ 
                          padding: '16px', 
                          background: '#f6ffed', 
                          borderRadius: '8px', 
                          marginBottom: '16px',
                          border: '1px solid #b7eb8f'
                        }}>
                          <Space direction="vertical" style={{ width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                              <CheckCircleOutlined style={{ color: '#52c41a' }} />
                              <Text>Refer friends and earn forever credits</Text>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                              <CheckCircleOutlined style={{ color: '#52c41a' }} />
                              <Text>They get 50% off their first month</Text>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                              <CheckCircleOutlined style={{ color: '#52c41a' }} />
                              <Text>You get 25% of their credits as forever credits</Text>
                            </div>
                          </Space>
                        </div>
                        
                        <Button 
                          type="primary" 
                          icon={<GiftOutlined />} 
                          onClick={() => setIsReferralModalVisible(true)}
                          size="large"
                          style={{ width: '100%', borderRadius: '8px', height: '40px' }}
                        >
                          Generate My Referral Code
                        </Button>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
            ) : (
              // Non-authenticated pricing section
              <Row gutter={[24, 24]}>
                {plans.map(plan => (
                  <Col key={plan.name} xs={24} sm={12} lg={8}>
                    <PricingCard plan={plan} />
                  </Col>
                ))}
              </Row>
            )}

            {/* Upgrade Plan / Forever Credits Card */}
            {isAuth && (
              <Card 
                title={ 
                  <div style={{ 
                    textAlign: 'center', 
                    fontSize: '20px', 
                    fontWeight: 500,
                    color: '#1a3353'
                  }}>
                    {planDetails.tier === 0 ? 'Upgrade Your Plan' : 'Purchase Forever Credits A la Carte'}
                  </div>
                } 
                style={{ 
                  marginTop: '32px',
                  borderRadius: '12px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                  overflow: 'hidden',
                  border: '1px solid #e9edf3'
                }}
                headStyle={{ 
                  backgroundColor: '#f0f5ff',
                  borderBottom: '1px solid #e9edf3',
                  padding: '16px'
                }}
                bodyStyle={{ padding: '24px' }}
              >
                {planDetails.tier === 0 ? (
                  <>
                    <stripe-pricing-table 
                      pricing-table-id="prctbl_1Q2DRQEj6HHcTsCPhfYLEndc"
                      publishable-key="pk_live_51NRFOxEj6HHcTsCPvhzzF60Cq1CF8cLb4H5u325kVom6Mo3s5vYG773YjxvDDAm5LQvwYDZHm3y8QWtVAMHCYJJS00nnNHDf1L"
                      client-reference-id={usersSharedState.userID}
                    />
                    <Divider style={{ margin: '32px 0' }}>
                      <Title level={4} style={{ margin: 0 }}>Annual Plans</Title>
                    </Divider>
                    <stripe-pricing-table 
                      pricing-table-id="prctbl_1Q2DTvEj6HHcTsCPpI5jNzxX"
                      publishable-key="pk_live_51NRFOxEj6HHcTsCPvhzzF60Cq1CF8cLb4H5u325kVom6Mo3s5vYG773YjxvDDAm5LQvwYDZHm3y8QWtVAMHCYJJS00nnNHDf1L"
                      client-reference-id={usersSharedState.userID}
                    />
                    <Divider style={{ margin: '32px 0' }}>
                      <Title level={4} style={{ margin: 0 }}>Forever Credits</Title>
                    </Divider>
                  </>
                ) : null}
                <stripe-pricing-table 
                  pricing-table-id="prctbl_1OsHRkEj6HHcTsCPwALbwTSF"
                  publishable-key="pk_live_51NRFOxEj6HHcTsCPvhzzF60Cq1CF8cLb4H5u325kVom6Mo3s5vYG773YjxvDDAm5LQvwYDZHm3y8QWtVAMHCYJJS00nnNHDf1L"
                  client-reference-id={usersSharedState.userID}
                />
              </Card>
            )}
          </>
        )}
      </div>

      {/* Modals */}
      <Modal
        title={
          <div style={{ textAlign: 'center', fontWeight: 600, fontSize: '18px' }}>
            Renew Subscription Now
          </div>
        }
        visible={isRenewModalVisible}
        onOk={handleRenewNowConfirm}
        onCancel={handleRenewNowCancel}
        confirmLoading={isRenewing}
        okText="Confirm"
        cancelText="Cancel"
        okButtonProps={{ 
          disabled: !!renewalErrorMessage,
          style: { borderRadius: '6px' }
        }}
        cancelButtonProps={{ style: { borderRadius: '6px' } }}
        centered
        bodyStyle={{ padding: '24px' }}
      >
        {isLoadingRenewalDetails ? (
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            padding: '32px 0',
            gap: '16px'
          }}>
            <Spin size="large" />
            <Text>Loading subscription details...</Text>
          </div>
        ) : renewalErrorMessage ? (
          <Alert 
            message={renewalErrorMessage} 
            type="error" 
            showIcon 
            style={{ borderRadius: '8px' }}
          />
        ) : (
          <div style={{ padding: '8px 0' }}>
            <div style={{
              background: '#f6f8fa',
              padding: '16px',
              borderRadius: '8px',
              marginBottom: '16px',
              border: '1px solid #e9edf3'
            }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Text strong>Plan:</Text>
                  <Text>{renewalPlanName}</Text>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Text strong>Amount:</Text>
                  <Text>${(renewalAmount! / 100).toFixed(2)}</Text>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Text strong>Billing Date:</Text>
                  <Text>Today</Text>
                </div>
              </Space>
            </div>
            
            <Text>
              This will immediately renew your subscription at your current subscription level, charge you
              now, and move your billing date to today.
            </Text>
          </div>
        )}
      </Modal>

      <Modal
        title={
          <div style={{ textAlign: 'center', fontWeight: 600, fontSize: '18px' }}>
            <GiftOutlined style={{ marginRight: '8px', color: '#52c41a' }} />
            Generate Referral Code
          </div>
        }
        visible={isReferralModalVisible}
        onCancel={() => setIsReferralModalVisible(false)}
        footer={null}
        centered
        bodyStyle={{ padding: '24px' }}
      >
        <div style={{ 
          background: '#f6ffed', 
          border: '1px solid #b7eb8f', 
          borderRadius: '8px', 
          padding: '16px', 
          marginBottom: '24px' 
        }}>
          <Text>
            Create your personalized referral code to share with friends. They'll get 50% off their first month, 
            and you'll earn 25% of their plan's credits as forever credits!
          </Text>
        </div>
        
        <Space direction="vertical" style={{ width: '100%' }}>
          <Radio.Group 
            onChange={(e) => setReferralCodeOption(e.target.value)} 
            value={referralCodeOption}
            style={{ width: '100%', marginBottom: '16px' }}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <Radio value="generate">Generate random code</Radio>
              <Radio value="custom">Choose my own code</Radio>
            </Space>
          </Radio.Group>

          {referralCodeOption === 'generate' ? (
            <Button 
              type="primary"
              onClick={() => handleGenerateReferralCode()} 
              loading={isGeneratingReferralCode}
              style={{ 
                width: '100%', 
                height: '40px', 
                borderRadius: '8px',
                marginTop: '8px'
              }}
            >
              Generate Random Code
            </Button>
          ) : (
            <>
              <Input 
                placeholder="Enter custom code (letters and numbers only)"
                value={customReferralCode}
                onChange={(e) => {
                  const sanitizedValue = e.target.value
                    .toUpperCase()
                    .replace(/[^A-Z0-9]/g, '')
                    .slice(0, 15);
                  setCustomReferralCode(sanitizedValue);
                }}
                style={{ 
                  width: '100%', 
                  height: '40px', 
                  borderRadius: '8px' 
                }}
              />
              <Text type="secondary" style={{ display: 'block', margin: '8px 0 16px' }}>
                Choose something memorable such as your name or pen name (max 15 characters).
              </Text>
              <Button 
                type="primary"
                onClick={() => handleGenerateReferralCode(customReferralCode)} 
                loading={isGeneratingReferralCode}
                disabled={!customReferralCode}
                style={{ 
                  width: '100%', 
                  height: '40px', 
                  borderRadius: '8px' 
                }}
              >
                Create Custom Code
              </Button>
            </>
          )}
        </Space>
      </Modal>
    </div>
  );
};

export default Account;