// BookManagementTab.tsx
import React, { useEffect, ChangeEvent, useState,useCallback  } from 'react';
import { Table, Input, Select, Tooltip, Button, Modal, Image, Spin } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons'
import axios, { AxiosError, AxiosResponse } from 'axios';
import { User } from "firebase/auth";
import { doc, getDoc, setDoc, getDocs, query, where, collection, Timestamp, deleteDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebaseConfig";  // Import your Firestore instance
import { useNavigate } from "react-router-dom";


type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';


const { TextArea } = Input;
const { Option } = Select;

interface SharedState {
  bookguid: string;
  model: string;
}

interface BookManagementTabProps {
  sharedState: SharedState;
  setSharedState: (newState: SharedState) => void;
  isAuth: boolean;
  user: User | null;
  setActiveTabKey: (key: string) => void;
  usersSharedState: any; 
  setUsersSharedState: any;
}

interface CustomAxiosError extends AxiosError {
  response: AxiosResponse<{ detail: string }>;
}

interface IBook {
  bookguid: string;
  title: string;
  seriesName: string;
  seriesNumber?: number;
  genre: string;
  lastModifiedDate: any; 
}

function generateGUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const BookManagementTab: React.FC<BookManagementTabProps> = ({ sharedState, setSharedState, isAuth, user, setActiveTabKey, usersSharedState, setUsersSharedState  }) => {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [infoTitle, setInfoTitle] = useState("");
  const [infoContent, setInfoContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  
  const [bookDetails, setBookDetails] = useState<Array<{bookguid: string, title: string, seriesName: string, genre: string, lastModifiedDate: Date}>>([]);

  const [isSequelModalVisible, setSequelModalVisible] = useState(false);
  const [sequelTitle, setSequelTitle] = useState('');
  const [sequelGenre, setSequelGenre] = useState('');
  const [newMainCharactersSelected, setNewMainCharactersSelected] = useState(false);
  const [currentBookTitle, setCurrentBookTitle] = useState<string>("");
  const [currentSeriesNumber, setCurrentSeriesNumber] = useState<number | null>(null);
  const [sequelSeriesNumber, setSequelSeriesNumber] = useState<number | null>(null);
  const [sequelBookType, setSequelBookType] = useState<"sequel" | "prequel" | "standalone">("sequel");
  const [selectedBookGuid, setSelectedBookGuid] = useState<string | null>(null);
  const [useSameTropes, setUseSameTropes] = useState(true);
  const [useSameStyle, setUseSameStyle] = useState(true);
  const [useSameTense, setUseSameTense] = useState(true);
  const [useSamePOV, setUseSamePOV] = useState(true);
  const [useCurrentAsSupporting, setUseCurrentAsSupporting] = useState(false);
  const [selectedSeriesName, setSelectedSeriesName] = useState<string | null>(null);
  const [keepSupportingCharactersFlag, setKeepSupportingCharactersFlag] = useState(true);
  const [keepLocationsFlag, setKeepLocationsFlag] = useState(true);
  

function openSequelModal(title: string, seriesName: string, bookguid: string) {
  setCurrentBookTitle(title);
  setSelectedBookGuid(bookguid);
  setSelectedSeriesName(seriesName);
  setSequelModalVisible(true);
}

const closeCreateSequelModal = () => {
  setSequelModalVisible(false);
};


const initialSharedState = {
	bookguid: "", 
	model: "gpt-4"
	  }	


const fetchBookGuids = useCallback(async () => {
  setIsLoading(true);  // set isLoading to true before the API call
  if (user) {
    try {
      const response = await axios.get(`http://192.168.1.187:8000/api/books?userId=${user.uid}`);
      const books: IBook[] = response.data.map((book: IBook) => ({
        ...book,
        lastModifiedDate: book.lastModifiedDate ? new Date(book.lastModifiedDate) : new Date() // default to current date if not provided
      }));
      setBookDetails(books);
      setIsLoading(false);  // set isLoading to false after the API call
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(user.uid);
        setErrorMessage(`Error fetching book GUIDs: ${error.response?.data.detail || error.message}`);
        setErrorModalVisible(true);
        setIsLoading(false);  // set isLoading to false if an error occurred
      }
    }
  } else {
    console.error('User not authenticated'); // log if user is not authenticated
    setIsLoading(false);  // set isLoading to false if an error occurred
  }
},[user]);

  
  useEffect(() => {
    fetchBookGuids();
  }, [fetchBookGuids]);


const loadBook = async (bookGuid: string) => {
  setIsLoading(true); 
  console.log('Loading Book');
  try {

  const newState: SharedState = {
    ...sharedState, 
    bookguid: bookGuid
  };

  setSharedState(newState);

    setIsLoading(false);
    setActiveTabKey("2");
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorMessage(`Error loading book: ${error.response?.data.detail}`);
      setErrorModalVisible(true);
    }
    setIsLoading(false);
  }
};

	
const deleteBook = async (bookGuid: string) => {
	try {
		await axios.delete(`http://192.168.1.187:8000/api/books/${bookGuid}`);
		setBookDetails(prevDetails => prevDetails.filter(book => book.bookguid !== bookGuid));
    setSharedState(initialSharedState);
	} catch (error) {
		if (axios.isAxiosError(error)) {
		  setErrorMessage(`Error deleting book: ${error.response?.data.detail}`);
		  setErrorModalVisible(true);
		}
	}
};
	
const generateNewBook = async () => {
    if (user) {
        try {
            const currentDate = new Date();
            const book = {
			userId: user.uid, 
			bookguid: generateGUID(), 
			tropes: "",
			genre: "",
			model: "Claude",
			tense: "",
			pov: "",
			numChapters: 6,
			writingStyle: "",
			basicPlot: "",
			createDate: currentDate,
			lastModifiedDate: currentDate,
			title: "My New Book",
			blurb: "",
			author: "",
			seriesName: "",
			seriesNumber: 0,
};
			
            const response = await axios.post(`http://192.168.1.187:8000/api/books`, book);
            
			const newState: SharedState = {
				...sharedState,
				bookguid: book.bookguid
			};
			
			setSharedState(newState);

            setActiveTabKey("2");
        } catch (error) {
            if (axios.isAxiosError(error)) {
                setErrorMessage(`Error creating new book: ${error.response?.data.detail}`);
                setErrorModalVisible(true);
            }
        }
    }
};


  const handleErrorModal = (visible: boolean) => {
    setErrorModalVisible(visible);
  };
  

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: (a: { bookguid: string, title: string, genre: string, lastModifiedDate: Date }, 
               b: { bookguid: string, title: string, genre: string, lastModifiedDate: Date }) => a.title.localeCompare(b.title),
    },
	{
		title: 'Series',
		dataIndex: 'seriesName',
		render: (text: string, record: IBook) => {
			let seriesText = "";
			if(record.seriesName && record.seriesNumber) {
				seriesText = `${record.seriesName} ${record.seriesNumber}`;
			} else if(record.seriesName) {
				seriesText = record.seriesName;
			} // If neither seriesName nor seriesNumber is provided, seriesText remains an empty string.
			return seriesText;
		},
		sorter: (a: IBook, b: IBook) => 
			(`${a.seriesName || ""} ${a.seriesNumber || ""}`).localeCompare(`${b.seriesName || ""} ${b.seriesNumber || ""}`),
	},
    {
      title: 'Genre',
      dataIndex: 'genre',
      sorter: (a: { bookguid: string, title: string, genre: string, lastModifiedDate: Date }, 
               b: { bookguid: string, title: string, genre: string, lastModifiedDate: Date }) => a.genre.localeCompare(b.genre),
    },
    {
      title: 'Last Modified',
      dataIndex: 'lastModifiedDate',
      render: (date: Date) => date.toLocaleString(),  // Converts date to string
	  defaultSortOrder: 'descend' as const,
      sorter: (a: { bookguid: string, title: string, genre: string, lastModifiedDate: Date }, 
               b: { bookguid: string, title: string, genre: string, lastModifiedDate: Date }) => a.lastModifiedDate.getTime() - b.lastModifiedDate.getTime(),
	  responsive: ['md'] as Breakpoint[],
    },
	{
		title: 'Action',
		key: 'action',
		render: (text: any, record: { bookguid: string, title: string, seriesName:string, genre: string, lastModifiedDate: Date }) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Tooltip title="Open Book">
            <Button style={{ width: 35, height: 35 }} onClick={() => loadBook(record.bookguid)}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <img src="/OpenIcon.png" alt="Open" style={{ width: 35, height: 35 }}/>
              </div>
            </Button>
          </Tooltip>
          <Tooltip title="Delete Book">
            <Button style={{ width: 35, height: 35 }} onClick={() => {
              Modal.confirm({
                title: 'Are you sure you want to delete this book?',
                content: 'This action cannot be undone, and you will not be refunded for any credits spent on this book.',
                onOk: () => deleteBook(record.bookguid),
              });
            }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <img src="/trash.png" alt="Delete" style={{ width: 35, height: 35 }} />
              </div>
            </Button>
          </Tooltip>
          <Tooltip title="Create Sequel">
            <Button style={{ width: 35, height: 35 }} onClick={() => openSequelModal(record.title, record.seriesName, record.bookguid)}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <img src="/sequel.png" alt="Create Sequel" style={{ width: 35, height: 35 }} />
              </div>
            </Button>
          </Tooltip>
        </div>
		),
	},
  ];


  const onRow = (record: { bookguid: string, title: string, genre: string, lastModifiedDate: Date }, rowIndex?: number) => {
    return {
      style: { cursor: 'pointer' },
      onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => loadBook(record.bookguid),  // Click row to load book
    };
  };
  
const handleCreateSequel = async (e: React.FormEvent) => {
  e.preventDefault();
  setSequelModalVisible(false);
  setIsLoading(true);
  if (!selectedBookGuid) return;  // make sure bookguid is available

  if (user) {
    try {
      const response = await axios.post('http://192.168.1.187:8000/api/createSequel', {
        originBookguid: selectedBookGuid,
        sequelTitle: sequelTitle,
        sequelBookType: sequelBookType,
        newMainCharactersFlag: newMainCharactersSelected,
        useCurrentAsSupportingFlag: useCurrentAsSupporting,
		keepSupportingCharactersFlag: keepSupportingCharactersFlag,
		keepLocationsFlag: keepLocationsFlag,
        preferences: {
          tropes: useSameTropes,
          style: useSameStyle,
          tense: useSameTense,
          pov: useSamePOV
        },
        sequelSeriesNumber: sequelSeriesNumber,
        seriesName: selectedSeriesName,
        userID: user.uid
      });

      // Handle the response as needed
      if (response.data.detail === "Sequel created successfully") {
        fetchBookGuids();
		setIsLoading(false);
      } else {
        // Handle any error messages
        console.error(response.data.message);
		setIsLoading(false);
      }
    } catch (error) {
      console.error("Error creating sequel:", error);
	  setIsLoading(false);
    }
  }
};

const handleCreateSeries = () => {
    navigate("/create-series");
}
  
  return (
  <Spin spinning={isLoading}>
      <div>
        {isAuth ? (
          <>
            <Button style={{ marginTop: '10px' }} onClick={generateNewBook}>Start New Book</Button>
			{/*<Button style={{ marginTop: '10px', marginLeft: '10px' }} onClick={handleCreateSeries}>Create New Series</Button>*/}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2>Your Books:</h2>
              <Button style={{ width: 35, height: 35 }} onClick={fetchBookGuids}>
  				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
  					<img src="/refresh.png" alt="Open" style={{ width: 35, height: 35 }}/>
  				</div>
  			</Button> 
            </div>
            <Table columns={columns} dataSource={bookDetails}  rowClassName="cursor-pointer" rowKey="guid" />
          </>
        ) : (
          <Tooltip title="Please click 'Get Started' to use this feature.">
            <Button style={{ marginTop: '10px' }} disabled>
              Start New Book
            </Button>
          </Tooltip>
        )}

	  
	  <Modal 
	  title="Error"
	  visible={isErrorModalVisible}
	  onOk={() => setErrorModalVisible(false)}
	  onCancel={() => setErrorModalVisible(false)}
	  footer={
	 	 <div style={{ textAlign: 'center' }}>
	 	 <Button key="submit" type="primary" onClick={() => setErrorModalVisible(false)}>
	 		 OK
	 	 </Button>
	 	 </div>
	  }
	  >
	  <p>{errorMessage}</p>
	  </Modal>

<Modal
  visible={isSequelModalVisible}
  onCancel={() => setSequelModalVisible(false)}
   title={`Create Sequel for ${currentBookTitle}`}
  footer={null} // This removes the default footer so we can customize it
>
  <form onSubmit={handleCreateSequel}>
    <div style={{ marginBottom: '15px' }}>
      <label>
        <strong>Title:</strong>
        <input 
          value={sequelTitle}
          onChange={(e) => setSequelTitle(e.target.value)}
          style={{ width: '90%', padding: '10px', marginTop: '5px' }}
        />
      </label>
    </div>

    <div style={{ marginBottom: '15px' }}>
      <p><strong>Type:</strong></p>
<label>
  <input type="radio" name="type" value="sequel" defaultChecked onChange={() => setSequelBookType('sequel')} /> Sequel
</label>
<label>
  <input type="radio" name="type" value="prequel" onChange={() => setSequelBookType('prequel')} /> Prequel
</label>
<label>
  <input type="radio" name="type" value="standalone" onChange={() => setSequelBookType('standalone')} /> Standalone
</label>

    </div>
{sequelBookType === "sequel" || sequelBookType === "prequel" ? (
  <div style={{ marginBottom: '15px' }}>
    <p><strong>Number in Series:</strong></p>
    <input
      type="number"
      onChange={(e) => setSequelSeriesNumber(Number(e.target.value))}
    />
  </div>
) : null}

    <div style={{ marginBottom: '15px' }}>
      <p><strong>Main Characters:</strong></p>
      <label>
        <input 
          type="radio" 
          name="characters" 
          value="same" 
          defaultChecked 
          onChange={() => setNewMainCharactersSelected(false)}
        /> 
        Same Main Characters
      </label>
      <label>
        <input 
          type="radio" 
          name="characters" 
          value="new" 
          onChange={() => setNewMainCharactersSelected(true)}
        /> 
        New Main Characters
      </label>
      {newMainCharactersSelected && (
        <div style={{ marginTop: '10px' }}>
<label>
    <input 
        type="checkbox" 
        checked={useCurrentAsSupporting}
        onChange={() => setUseCurrentAsSupporting(!useCurrentAsSupporting)} 
    /> 
    Use current main characters as supporting characters
</label>

        </div>
      )}
    </div>

<div style={{ marginBottom: '15px' }}>
  <p><strong>Preferences:</strong></p>
        <div>
        <label>
          <input 
            type="checkbox" 
            checked={keepSupportingCharactersFlag}
            onChange={() => setKeepSupportingCharactersFlag(!keepSupportingCharactersFlag)} 
          /> 
          Keep Existing Supporting Characters
        </label>
      </div>
      <div>
        <label>
          <input 
            type="checkbox" 
            checked={keepLocationsFlag}
            onChange={() => setKeepLocationsFlag(!keepLocationsFlag)} 
          /> 
          Keep Existing Locations
        </label>
      </div>
  <div>
    <label>
      <input 
        type="checkbox" 
        checked={useSameTropes}
        onChange={() => setUseSameTropes(!useSameTropes)} 
      /> 
      Keep same tropes
    </label>
  </div>
  <div>
    <label>
      <input 
        type="checkbox" 
        checked={useSameStyle}
        onChange={() => setUseSameStyle(!useSameStyle)} 
      /> 
      Keep same writing style
    </label>
  </div>
  <div>
    <label>
      <input 
        type="checkbox" 
        checked={useSameTense}
        onChange={() => setUseSameTense(!useSameTense)} 
      /> 
      Keep same tense
    </label>
  </div>
  <div>
    <label>
      <input 
        type="checkbox" 
        checked={useSamePOV}
        onChange={() => setUseSamePOV(!useSamePOV)} 
      /> 
      Keep same point of view
    </label>
  </div>
</div>


    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <button type="submit" style={{ padding: '10px 20px' }}>Create Sequel</button>
    </div>
  </form>
</Modal>

	  	  
    </div>
	</Spin>
  );
};

export default BookManagementTab;
