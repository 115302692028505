import React, { useState, useEffect } from 'react';
import { CheckCircleOutlined, DownOutlined, WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { message, Modal, Button, Select, Tooltip, Input, Tag, Alert } from 'antd';
import axios from 'axios';
import { Book, Chapter } from './types';

interface GenerateBlurbModalProps {
  isVisible: boolean;
  onClose: () => void;
  bookId: string;
  auth: any;
  genres: string[];
  selectedAIModel: string;
  setSelectedAIModel: React.Dispatch<React.SetStateAction<string>>;
  fetchCreditBalance: (userId: string) => void;
  creditBalance: { credit_balance: number } | null;
  setBlurb: (blurb: string) => void;
  isLoading: boolean; 
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;  
  book: Book;
}

const GenerateBlurbModal: React.FC<GenerateBlurbModalProps> = ({
  isVisible,
  onClose,
  bookId,
  auth,
  genres,
  selectedAIModel,
  setSelectedAIModel,
  fetchCreditBalance,
  creditBalance,
  setBlurb,
  isLoading,
  setIsLoading,  
  book,
}) => {
  const [blurbTense, setBlurbTense] = useState('Present');
  const [blurbPoV, setBlurbPoV] = useState('3rd Person');
  const [blurbCharacterNames, setBlurbCharacterNames] = useState('');
  const [generatedBlurbs, setGeneratedBlurbs] = useState<string[]>([]);
  const [selectedBlurb, setSelectedBlurb] = useState<string | null>(null);
  const [isSelectingBlurb, setIsSelectingBlurb] = useState(false);
  const [modalVisible, setModalVisible] = useState(isVisible);
  
  const calculateBlurbCost = (): number => {
    const totalWordCount = book.chapters.reduce((sum, chapter) => sum + chapter.word_count, 0);
    let cost = Math.ceil(totalWordCount * 0.1); // 10% of the total word count, rounded up
    cost = Math.min(Math.max(cost, 500), 5000);
    
    // Apply 50% discount for DeepSeek model, rounded up to the nearest integer
    if (selectedAIModel === 'DeepSeek') {
      cost = Math.ceil(cost * 0.5);
    }
    
    return cost;
  };

  const [blurbCost, setBlurbCost] = useState(calculateBlurbCost());

  useEffect(() => {
    if (auth.currentUser && isVisible) {
      fetchCreditBalance(auth.currentUser.uid);
    }
  }, [isVisible, auth.currentUser]);

  useEffect(() => {
    setBlurbCost(calculateBlurbCost());
  }, [selectedAIModel, book.chapters]);

  const userHasEnoughCredits = (creditBalance?.credit_balance ?? 0) >= blurbCost;

  const handleGenerateBlurbSubmit = async () => {
    if (!userHasEnoughCredits) {
      message.error("Insufficient credits");
      return;
    }

    setIsLoading(true);
    setModalVisible(false);

    try {
      const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/generate-blurb/', {
        userId: auth.currentUser!.uid,
        bookId: bookId,
        genre: genres.join(', '),
        tense: blurbTense,
        pov: blurbPoV,
        characterNames: blurbCharacterNames,
        model: selectedAIModel
      });

      if (response.data.status === "success") {
        setGeneratedBlurbs([response.data.blurb1, response.data.blurb2, response.data.blurb3]);
        setIsSelectingBlurb(true);
        setModalVisible(true);
        message.success('Blurbs generated successfully');
        fetchCreditBalance(auth.currentUser!.uid);
      } else {
        message.error('Blurb generation failed: ' + response.data.message, 10);
      }
    } catch (error) {
      console.error("Error generating blurb:", error);
      message.error("Error generating blurb", 10);
    } finally {
      setIsLoading(false);  
    }
  };

  const handleSaveSelectedBlurb = async () => {
    if (selectedBlurb) {
      setBlurb(selectedBlurb);

      if (!auth.currentUser) {
        console.error("No user logged in");
        return;
      }

      setIsLoading(true);

      try {
        await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/update-book-blurb/', { 
          bookID: bookId,
          userID: auth.currentUser.uid,
          blurb: selectedBlurb
        });
      } catch (error) {
        console.error("Error saving book details:", error);
      }
      setIsLoading(false);

      message.success('Blurb saved successfully');
      onClose();
    } else {
      message.error('Please select a blurb before saving');
    }
  };

  const handleBlurbClick = (blurb: string) => {
    setSelectedBlurb(blurb);
  };

  // Get status messages to display in alerts
  const getStatusMessages = () => {
    const messages = [];
    
    if (!userHasEnoughCredits) {
      messages.push({
        type: 'error',
        message: 'You do not have enough credits for this operation.',
        icon: <ExclamationCircleOutlined />
      });
    }

    return messages;
  };
  
  return (
    <Modal
      title={isSelectingBlurb ? "Select a Blurb" : "Generate Blurb"}
      visible={modalVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>Cancel</Button>,
        !isSelectingBlurb && (
          <Button key="generate" type="primary" onClick={handleGenerateBlurbSubmit} disabled={!userHasEnoughCredits}>
            Generate Blurbs
          </Button>
        ),
        isSelectingBlurb && (
          <Button key="save" type="primary" onClick={handleSaveSelectedBlurb} disabled={!selectedBlurb}>
            Save Selected Blurb
          </Button>
        ),
      ]}
      width={'90%'}
    >
      {!isSelectingBlurb && (
        <>
          <div style={{ marginBottom: '15px' }}>
            <p>This feature works best if the Primary Genre and Tropes have been entered. The AI will use this information along with a portion of your manuscript to create 3 compelling blurbs for your book which you can choose from.</p>
          </div>
          
          <div style={{ marginBottom: '15px' }}>
            <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
              <strong>AI Model:</strong>
            </label>
            <Select
              value={selectedAIModel}
              onChange={(value) => setSelectedAIModel(value)}
              style={{ width: '100%' }}
              suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
            >
              <Select.Option value="Claude3.7">
                Claude 3.7 <Tag color="geekblue" style={{ marginLeft: '8px', position: 'relative', top: '-0.5px' }}>Recommended </Tag>
              </Select.Option>
              <Select.Option value="Claude">Claude 3.5</Select.Option>
              <Select.Option value="Claude3">Claude 3</Select.Option>
              <Select.Option value="Chat-GPT">Chat-GPT</Select.Option>             
              <Select.Option value="DeepSeek">
                DeepSeek <Tag color="green" style={{ marginLeft: '8px', position: 'relative', top: '-0.5px' }}>50% Off</Tag>
                <Tag color="red" style={{ marginLeft: '8px', position: 'relative', top: '-0.5px' }}>Allows NSFW</Tag>
              </Select.Option>
            </Select>
          </div>

          {/* Tense and POV in a row */}
          <div style={{ 
            display: 'flex', 
            gap: '15px', 
            marginBottom: '15px' 
          }}>
            {/* Left column - Tense */}
            <div style={{ flex: 1 }}>
              <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
                <strong>Tense:</strong>
                <Tooltip title="The tense you want the blurb written in. It should generally match the tense of the story.">
                  <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#1677ff' }} />
                </Tooltip>
              </label>
              <Select
                value={blurbTense}
                onChange={(value) => setBlurbTense(value)}
                style={{ width: '100%' }}
                suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
              >
                <Select.Option value="Present">Present</Select.Option>
                <Select.Option value="Past">Past</Select.Option>
                <Select.Option value="Future">Future</Select.Option>
              </Select>
            </div>
            
            {/* Right column - Point of View */}
            <div style={{ flex: 1 }}>
              <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
                <strong>Point of View:</strong>
                <Tooltip title="The Point of View (PoV) you want the blurb written in. It should generally match the POV of the story.">
                  <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#1677ff' }} />
                </Tooltip>
              </label>
              <Select
                value={blurbPoV}
                onChange={(value) => setBlurbPoV(value)}
                style={{ width: '100%' }}
                suffixIcon={<DownOutlined style={{ color: '#1677ff' }}/>}
              >
                <Select.Option value="1st Person">1st Person</Select.Option>
                <Select.Option value="2nd Person">2nd Person</Select.Option>
                <Select.Option value="3rd Person">3rd Person</Select.Option>
                <Select.Option value="Omniscient">Omniscient</Select.Option>
              </Select>
            </div>
          </div>

          {blurbPoV === '1st Person' && (
            <div style={{ marginBottom: '15px' }}>
              <label style={{ marginRight: '10px', display: 'block', marginBottom: '5px' }}>
                <strong>Character Name:</strong>
                <Tooltip title="Optional. Enter the name of the main character whose perspective you want the blurb written in. If no name is given, the AI will attempt to write the blurb from the perspective of the primary protagonist.">
                  <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#1677ff' }} />
                </Tooltip>
              </label>
              <Input
                value={blurbCharacterNames}
                onChange={(e) => setBlurbCharacterNames(e.target.value)}
                placeholder="Enter character name(s) for 1st person POV"
              />
            </div>
          )}

          {/* Credit information */}
          <div style={{ 
            background: '#f0f7ff', 
            padding: '15px', 
            borderRadius: '6px', 
            marginBottom: '15px' 
          }}>
            <div style={{ marginBottom: '8px' }}>
              <strong>Credit Balance: </strong>
              <span>{creditBalance ? creditBalance.credit_balance : 'Loading...'}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <strong>Credit Cost: </strong>
              <span style={{ marginLeft: '5px', marginRight: '5px' }}>{blurbCost}</span>
              <Tooltip title="We use a portion of the book's text to generate the blurb. The cost is approximately 10% of the book's word count with a minimum cost of 500 credits and a maximum cost of 5000 credits.">
                <QuestionCircleOutlined style={{ color: '#1677ff' }} />
              </Tooltip>
            </div>
          </div>
          
          {/* Status Messages */}
          <div style={{ marginTop: '12px' }}>
            {getStatusMessages().map((msg, index) => (
              <Alert
                key={index}
                message={msg.message}
                type={msg.type as any}
                icon={msg.icon}
                style={{ marginBottom: '8px' }}
                showIcon
                closable={false}
                banner={false}
              />
            ))}
          </div>
        </>
      )}

      {isSelectingBlurb && (
        <div style={{ marginTop: '5px' }}>
          <p style={{ fontSize: '13px', margin: '0 0 8px 0' }}>Select one of the generated blurbs below by clicking on it:</p>
          <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
            {generatedBlurbs.map((blurb, index) => (
              <div
                key={index}
                onClick={() => handleBlurbClick(blurb)}
                style={{
                  border: selectedBlurb === blurb ? '2px solid #1890ff' : '1px solid #d9d9d9',
                  padding: '10px',
                  borderRadius: '6px',
                  marginBottom: '10px',
                  cursor: 'pointer',
                  whiteSpace: 'pre-wrap',
                  background: selectedBlurb === blurb ? '#f0f7ff' : 'white',
                  position: 'relative'
                }}
              >
                {blurb.split('\n').map((line, i) => (
                  <React.Fragment key={i}>
                    {line}
                    {i < blurb.split('\n').length - 1 && <br />}
                  </React.Fragment>
                ))}
                {selectedBlurb === blurb && (
                  <div style={{ 
                    position: 'absolute', 
                    top: '5px', 
                    right: '5px', 
                    color: '#1890ff',
                    background: 'rgba(255,255,255,0.8)',
                    padding: '2px 8px',
                    borderRadius: '12px'
                  }}>
                    <CheckCircleOutlined /> Selected
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default GenerateBlurbModal;