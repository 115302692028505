import React, { useState, useEffect } from 'react';
import { Modal, Button, Tabs, Card, Tooltip, Alert } from 'antd';
import { QuestionCircleOutlined, CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getAuth } from "firebase/auth";

const { TabPane } = Tabs;

interface TranslationOption {
  translatedText: string;
  sourceTrans: string;
  desc: string;
}

interface TranslationOptions {
  titleOptions?: TranslationOption[];
  seriesOptions?: TranslationOption[];
}

interface TranslationOptionsModalProps {
  isVisible: boolean;
  onCancel: () => void;
  translationOptions: TranslationOptions;
  translateMetadata: {
    title: boolean;
    series: boolean;
    blurb: boolean;
  };
  bookId: string;
  bookLanguage: string;
  targetLanguage: string;
  fetchBookDetails: () => void;
}

const auth = getAuth();

const TranslationOptionsModal: React.FC<TranslationOptionsModalProps> = ({
  isVisible,
  onCancel,
  translationOptions,
  translateMetadata,
  bookId,
  bookLanguage,
  targetLanguage,
  fetchBookDetails,
}) => {
  const [activeTabKeyTOM, setActiveTabKeyTOM] = useState<string>('1');
  const [selectedTitleOption, setSelectedTitleOption] = useState<TranslationOption | null>(null);
  const [selectedSeriesOption, setSelectedSeriesOption] = useState<TranslationOption | null>(null);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  useEffect(() => {
    if (translateMetadata.title && !translateMetadata.series) {
      setActiveTabKeyTOM('1');
    } else if (!translateMetadata.title && translateMetadata.series) {
      setActiveTabKeyTOM('2');
    }
  }, [translateMetadata.title, translateMetadata.series]);

  useEffect(() => {
    setIsSaveEnabled(
      (translateMetadata.title ? selectedTitleOption !== null : true) &&
      (translateMetadata.series ? selectedSeriesOption !== null : true)
    );
  }, [selectedTitleOption, selectedSeriesOption, translateMetadata]);

  const handleCancel = async () => {
    onCancel();
    fetchBookDetails();
  };

  const handleOptionSelect = (option: TranslationOption, type: 'title' | 'series') => {
    if (type === 'title') {
      setSelectedTitleOption(option);
    } else {
      setSelectedSeriesOption(option);
    }
  };

  const saveSelectedOptions = async () => {
    try {
      if (selectedTitleOption) {
        await axios.post(`https://askpoeai-nswdzfky7q-uc.a.run.app/save-title-translation/`, {
          userId: auth.currentUser?.uid,
          bookId: bookId,
          title: selectedTitleOption.translatedText,
          sourceLang: bookLanguage,
          targetLang: targetLanguage
        });
      }

      if (selectedSeriesOption) {
        await axios.post(`https://askpoeai-nswdzfky7q-uc.a.run.app/save-series-translation/`, {
          userId: auth.currentUser?.uid,
          bookId: bookId,
          series: selectedSeriesOption.translatedText,
          sourceLang: bookLanguage,
          targetLang: targetLanguage
        });
      }

      onCancel();
      fetchBookDetails();
    } catch (error) {
      console.error("Error saving Title or Series Translation:", error);
    }
  };

  return (
    <Modal
      title="Select Translation Options"
      visible={isVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>Cancel</Button>,
        <Button 
          key="save" 
          type="primary" 
          onClick={saveSelectedOptions} 
          disabled={!isSaveEnabled}
        >
          Save
        </Button>
      ]}
      width={'80%'}
      bodyStyle={{ padding: '12px 24px' }}
    >
      <Tabs 
        defaultActiveKey="1" 
        activeKey={activeTabKeyTOM} 
        onChange={setActiveTabKeyTOM}
        style={{ marginBottom: '8px' }}
		type="card"
      >
        {translateMetadata.title && (
          <TabPane tab="Title" key="1">
            <div style={{ 
              background: '#f7f7f7', 
              padding: '8px 12px', 
              borderRadius: '6px', 
              marginBottom: '8px' 
            }}>
              <div style={{ marginBottom: '0' }}>
                <strong>Select the best translation for your book title:</strong>
                <Tooltip title="Choose the translation option that best captures the meaning and style of your original title.">
                  <QuestionCircleOutlined style={{ color: '#1677ff', marginLeft: 8 }} />
                </Tooltip>
              </div>
            </div>
            
            {translationOptions.titleOptions?.map((option, index) => (
              <Card
                key={index}
                style={{ 
                  marginBottom: 10, 
                  border: selectedTitleOption === option ? '2px solid #1677ff' : '1px solid #e8e8e8',
                  borderRadius: '6px',
                  boxShadow: selectedTitleOption === option ? '0 0 5px rgba(22, 119, 255, 0.3)' : 'none'
                }}
                onClick={() => handleOptionSelect(option, 'title')}
                hoverable
                bodyStyle={{ padding: '12px' }}
              >
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  {selectedTitleOption === option && (
                    <CheckCircleOutlined style={{ color: '#1677ff', marginRight: '8px', marginTop: '2px' }} />
                  )}
                  <div>
                    <p style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '4px' }}>
                      {option.translatedText}
                    </p>
                    <p style={{ marginBottom: '4px' }}>
                      <strong>Literal Meaning:</strong> {option.sourceTrans}
                    </p>
                    <p style={{ color: '#666', marginBottom: '0' }}>
                      <strong>Description:</strong> {option.desc}
                    </p>
                  </div>
                </div>
              </Card>
            ))}
          </TabPane>
        )}
        
        {translateMetadata.series && (
          <TabPane tab="Series Name" key="2">
            <div style={{ 
              background: '#f7f7f7', 
              padding: '8px 12px', 
              borderRadius: '6px', 
              marginBottom: '8px' 
            }}>
              <div style={{ marginBottom: '0' }}>
                <strong>Select the best translation for your series name:</strong>
                <Tooltip title="Choose the translation option that best captures the meaning and style of your original series name.">
                  <QuestionCircleOutlined style={{ color: '#1677ff', marginLeft: 8 }} />
                </Tooltip>
              </div>
            </div>
            
            {translationOptions.seriesOptions?.map((option, index) => (
              <Card
                key={index}
                style={{ 
                  marginBottom: 10, 
                  border: selectedSeriesOption === option ? '2px solid #1677ff' : '1px solid #e8e8e8',
                  borderRadius: '6px',
                  boxShadow: selectedSeriesOption === option ? '0 0 5px rgba(22, 119, 255, 0.3)' : 'none'
                }}
                onClick={() => handleOptionSelect(option, 'series')}
                hoverable
                bodyStyle={{ padding: '12px' }}
              >
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  {selectedSeriesOption === option && (
                    <CheckCircleOutlined style={{ color: '#1677ff', marginRight: '8px', marginTop: '2px' }} />
                  )}
                  <div>
                    <p style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '4px' }}>
                      {option.translatedText}
                    </p>
                    <p style={{ marginBottom: '4px' }}>
                      <strong>Literal Meaning:</strong> {option.sourceTrans}
                    </p>
                    <p style={{ color: '#666', marginBottom: '0' }}>
                      <strong>Description:</strong> {option.desc}
                    </p>
                  </div>
                </div>
              </Card>
            ))}
          </TabPane>
        )}
      </Tabs>
    </Modal>
  );
};

export default TranslationOptionsModal;